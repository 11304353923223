import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import * as Sentry from '@sentry/react'

import { IUser } from 'interfaces'
import { COUNTS_TIME_TO_UPDATE } from 'config/app'
import Routes from 'routes'
import {
    Loader,
    AlertDialog,
    AlertNotify,
    ModalContent,
} from 'components'
import { useAppSettings } from 'containers/App/hooks'
import { fetchUser, fetchCounts } from 'containers/User/user-actions'
import * as userSelectors from 'containers/User/user-selectors'
import { showAlertNotify, getRequestError } from 'utils/helpers'
import { AuthService, TranslationService } from 'services'
import { useMetrics, useThunkDispatch } from 'hooks'
import {
    setDarkDoor,
    getCurrentPosition,
    fetchLanguages,
    fetchCountries,
    fetchCountryIdentify,
    fetchResources,
    fetchStickers,
} from './app-actions'
import style from './App.module.css'

type AppPropType = {}

/**
 * Application main container
 *
 * @name App
 * @memberOf module:Modules
 */
const App: React.FC<AppPropType> = () => {
    const { search } = useLocation()
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const thunkDispatch = useThunkDispatch()
    const history = useHistory()
    const location = useLocation()
    const { init: initAppSettings } = useAppSettings()
    const metrics = useMetrics()

    const user = useSelector(userSelectors.user)
    const isAuthorized = useSelector(userSelectors.authorize)

    const [isLoading, setIsLoading] = useState(true)

    function authorize(userData: IUser) {
        authorizeAction(userData)
            .then((route) => {
                if (route) {
                    history.push(route)
                }
            })
            .catch((err) => {
                const errorText = getRequestError(err)
                showAlertNotify({ type: 'error', message: errorText || t('update_error') })
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    function fetchUserAction() {
        thunkDispatch(fetchUser())
            .then((data: IUser) => {
                // change app lang by user country lang
                TranslationService.changeLanguage(data.language.lang_short)
                authorize(data)
            })
            .catch((err) => {
                const { response, code } = err || {}
                const { error, error_description: errorDesc } = response?.data || {}
                let errorText = t('update_error')

                setIsLoading(false)

                if (error === 'invalid_grant') {
                    errorText = errorDesc
                } else if (code === 'ERR_NETWORK') {
                    errorText = t('no_internet')
                }

                showAlertNotify({ type: 'error', message: errorText })
            })
    }

    function authorizeAction(userData: IUser) {
        return AuthService.authorize(userData, location)
    }

    useEffect(() => {
        const darkDoorUrlParams = AuthService.getDarkDoorUrlParams(search)

        setIsLoading(true)
        initAppSettings()

        dispatch(getCurrentPosition())
        dispatch(fetchLanguages())
        dispatch(fetchCountries())
        dispatch(fetchResources())

        if (AuthService.isDarkDoorAuth(darkDoorUrlParams)) {
            dispatch(setDarkDoor(darkDoorUrlParams))
            fetchUserAction()
        } else if (AuthService.isAuthenticated()) {
            fetchUserAction()
        } else {
            dispatch(fetchCountryIdentify())
            setIsLoading(false)
        }
    }, [])

    useEffect(() => {
        if (user) {
            Sentry.setUser({ id: String(user.id) })
            metrics.userParams({ userId: user.id, companyId: user.company_id })
            dispatch(fetchStickers())
        }

        return () => {
            Sentry.setUser(null)
        }
    }, [user])

    useEffect(() => {
        metrics.hit(location.pathname)
    }, [location])

    useEffect(() => {
        let countsTimerId = 0

        if (isAuthorized) {
            dispatch(fetchCounts())

            countsTimerId = window.setInterval(() => {
                dispatch(fetchCounts())
            }, COUNTS_TIME_TO_UPDATE)
        }

        return () => {
            if (countsTimerId) {
                clearInterval(countsTimerId)
            }
        }
    }, [isAuthorized])

    return (
        <>
            {isLoading ? (
                <Loader classes={style.loader} size="large" />
            ) : (
                <div className={style.app}>
                    <Routes isAuthorized={isAuthorized} />

                    <AlertDialog />

                    <ModalContent />
                </div>
            )}

            <AlertNotify />
        </>
    )
}

export default App
