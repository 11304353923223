import { IRootState } from 'interfaces'

export const authorize = (state: IRootState) => state.authorize

export const mlm = (state: IRootState) => state.mlm

export const user = (state: IRootState) => state.user

export const profile = (state: IRootState) => state.profile

export const geolocation = (state: IRootState) => state.geolocation

export const tags = (state: IRootState) => state.tags

export const counts = (state: IRootState) => state.counts
