import React, { useMemo } from 'react'
import { Field, useFormState } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import cn from 'classnames'

import { Button, Textarea } from 'components'
import { FieldSelect } from 'forms/fields'
import styleForm from 'styles/modules/form.module.css'
import style from './ReportForm.module.css'

export enum FieldName {
    category = 'category',
    subject = 'subject',
    member_id = 'member_id',
    member_name = 'member_name',
    member_email = 'member_email',
    member_phone = 'member_phone',
    message_text = 'message_text',
}

export type FormDataType = {
    [FieldName.category]: number
    [FieldName.subject]: string
    [FieldName.member_id]: string
    [FieldName.member_name]: string
    [FieldName.member_email]: string
    [FieldName.member_phone]: string
    [FieldName.message_text]: string
}

export const formDataInitial: FormDataType = {
    category: 0,
    subject: '',
    member_id: '',
    member_name: '',
    member_email: '',
    member_phone: '',
    message_text: '',
}

export type ReportFormPropType = {
    isSubmitting: boolean
    textInfo?: string
    onSubmit: (e: React.FormEvent<HTMLFormElement>) => void
}

const SUBJECTS = [
    { id: 1, translateKey: 'complain_about_photo' },
    { id: 2, translateKey: 'complain_about_spam' },
    { id: 3, translateKey: 'complain_about_fake' },
    { id: 4, translateKey: 'complain_about_offensive_behavior' },
    { id: 5, translateKey: 'complain_about_something_else' },
]

const ReportForm: React.FC<ReportFormPropType> = ({
    isSubmitting,
    textInfo,
    onSubmit,
}) => {
    const { t } = useTranslation()

    const { hasValidationErrors } = useFormState<FormDataType>()

    const subjects = useMemo(() => {
        return SUBJECTS.map((item) => ({ id: item.id, value: t(item.translateKey) }))
    }, [SUBJECTS])

    return (
        <form className={cn({ [styleForm.submitting]: isSubmitting })} onSubmit={onSubmit}>
            {textInfo && (
                <div className={cn(styleForm.row, styleForm.row_20)}>
                    {textInfo}
                </div>
            )}
            <div className={cn(styleForm.row, styleForm.row_20)}>
                <FieldSelect
                    classes={style.select}
                    name={FieldName.subject}
                    items={subjects}
                    defaultItem={subjects[0]}
                />
            </div>
            <div className={cn(styleForm.row, styleForm.row_20)}>
                <Field
                    name={FieldName.message_text}
                    render={({ input, meta }) => (
                        <>
                            <Textarea
                                classes={cn({ [styleForm.invalid]: meta.dirty && meta.error })}
                                styleType="dynamic"
                                dynamicPlaceholder={t('message')}
                                {...input}
                            />
                            <div className={styleForm.error}>
                                {meta.dirty && meta.error}
                            </div>
                        </>
                    )}
                />
            </div>
            <div className={styleForm.controls_20}>
                <Button
                    fullWidth
                    classes={style.modalControl}
                    type="submit"
                    textUpper
                    size="size40"
                    text={t('send')}
                    disabled={hasValidationErrors}
                />
            </div>
        </form>
    )
}

export default ReportForm
