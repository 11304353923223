import { AnyAction, combineReducers } from 'redux'

import { IRootState } from 'interfaces'
import { appReducer, geolocationReducer } from 'containers/App/app-reducer'
import {
    authorizeReducer,
    mlmReducer,
    userReducer,
    profileReducer,
    tagReducer,
    countReducer,
} from 'containers/User/user-reducer'
import { networkReducer } from 'containers/Network/network-reducer'
import { ordersReducer } from 'containers/Market/market-reducer'
import { ticketReducer } from 'containers/Support/support-reducer'
import { transactionReducer } from 'containers/Wallet/wallet-reducer'
import { CLEAR_STATE } from './types'

const reducers = combineReducers({
    app: appReducer,
    authorize: authorizeReducer,
    mlm: mlmReducer,
    user: userReducer,
    profile: profileReducer,
    geolocation: geolocationReducer,
    tags: tagReducer,
    network: networkReducer,
    orders: ordersReducer,
    counts: countReducer,
    tickets: ticketReducer,
    transactions: transactionReducer,
})

const rootReducer = (state: IRootState, action: AnyAction) => {
    switch (action.type) {
        case CLEAR_STATE:
            return reducers(action.payload, action)

        default:
            return reducers(state, action)
    }
}

export default rootReducer
