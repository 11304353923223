import React from 'react'
import cn from 'classnames'

import { Button } from 'components'
import style from './Tag.module.css'

type TagPropType = {
    classes?: string
    onDelete?: () => void
}

const Tag: React.FC<TagPropType> = ({
    children,
    classes,
    onDelete,
}) => {
    return (
        <div className={cn(style.tag, classes)}>
            <div className={style.content}>
                {children}
            </div>
            {onDelete && (
                <Button
                    classes={style.action}
                    styleType="transparent"
                    onClick={onDelete}
                >
                    <span className={style.cross} />
                </Button>
            )}
        </div>
    )
}

export default Tag
