import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { IBasket } from 'interfaces'
import { Block } from 'layout'
import { SvgResource } from 'components'
import { numberFormat } from 'utils/helpers'
import { MarketService } from 'services'
import style from './StoreBannerGift.module.css'

type StoreBannerGiftPropType = {
    basket?: IBasket
}

const StoreBannerGift: React.FC<StoreBannerGiftPropType> = ({ basket = {} }) => {
    const { t } = useTranslation()

    const [isShowBanner, setIsShowBanner] = useState(false)
    const [text, setText] = useState('')

    function getText(sum: number): string {
        const pattern = t('market_wallet_title')
        return pattern
            .replace('%@', numberFormat(sum))
            .replace('%@', t('market_cv'))
    }

    useEffect(() => {
        if (basket?.market_wallet_list?.length) {
            const available = MarketService.getPayoutAvailableSum(basket.market_wallet_list)

            if (available > 0) {
                setText(getText(available))
                setIsShowBanner(true)
            }
        }
    }, [basket])

    return (
        <>
            {isShowBanner && (
                <Block classes={style.block}>
                    <SvgResource
                        isImgTag={false}
                        classes={style.icon}
                        resourceKey="ic_shop_present_svg"
                        width={30}
                        height={31}
                    />
                    <span className={style.text}>
                        {text}
                    </span>
                </Block>
            )}
        </>
    )
}

export default StoreBannerGift
