import React, { Suspense, lazy, useEffect } from 'react'
import {
    Switch,
    Route,
    Redirect,
} from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { APP_URL, BREAKPOINTS } from 'config/app'
import {
    Container,
    PageContainer,
    SideBarContainer,
    Block,
} from 'layout'
import {
    NavBar,
    NavMenu,
    MenuNav,
    UserInfo,
    FooterLK,
    Loader,
} from 'components'
import {
    Feed,
    ReportsV1,
    ReportsPrivilegeMembers,
    ReportsFreedom,
    Report,
    Support,
    Ticket,
} from 'containers'
import { useApp } from 'containers/App/hooks'
import { fetchProfile } from 'containers/User/user-actions'
import * as userSelectors from 'containers/User/user-selectors'
import { useWindowResize } from 'hooks'
import style from './LKPage.module.css'

const Privilege = lazy(() => import('containers/Privilege/Privilege'))
const Publication = lazy(() => import('containers/Publication/Publication'))
const Market = lazy(() => import('containers/Market/Market'))
const Shops = lazy(() => import('containers/Shops/Shops'))
const Store = lazy(() => import('containers/Store/Store'))
const Goods = lazy(() => import('containers/Goods/Goods'))
const Reports = lazy(() => import('containers/Reports/Reports'))
const Friends = lazy(() => import('containers/Friends/Friends'))
const FriendsRequest = lazy(() => import('containers/FriendsRequest/FriendsRequest'))
const FriendsMutual = lazy(() => import('containers/FriendsMutual/FriendsMutual'))
const Messenger = lazy(() => import('containers/Messenger/Messenger'))
const Calendar = lazy(() => import('containers/Calendar/Calendar'))
const Settings = lazy(() => import('containers/Settings/Settings'))
const SettingsLab = lazy(() => import('containers/SettingsLab/SettingsLab'))
const UserProfile = lazy(() => import('containers/UserProfile/UserProfile'))
const UserSettings = lazy(() => import('containers/UserSettings/UserSettings'))
const Wallet = lazy(() => import('containers/Wallet/Wallet'))

const DevModule = lazy(() => import('containers/DevModule/DevModule'))

/**
 * Страница личный кабинет авторизованного пользователя
 */
const LKPage: React.FC = () => {
    const dispatch = useDispatch()
    const [windowWidth] = useWindowResize()
    const { mainPrivateRoute } = useApp()

    const user = useSelector(userSelectors.user)

    const routes = (
        <Suspense fallback={<Loader />}>
            <Switch>
                <Route exact path={APP_URL.privilege} component={Privilege} />

                <Route path={APP_URL.lenta} component={Feed} />
                <Route path={APP_URL.lentaUser} component={Feed} />
                <Route path={APP_URL.post} component={Publication} />

                <Route exact path={APP_URL.favoriteShops} component={Shops} />
                <Route exact path={APP_URL.market} component={Market} />
                <Route path={APP_URL.category} component={Shops} />

                <Route exact path={APP_URL.store} component={Store} />
                <Route path={APP_URL.storeProfile} component={Store} />
                <Route path={APP_URL.catalog} component={Store} />
                <Route path={APP_URL.basketFromOrder} component={Store} />
                <Route path={APP_URL.basket} component={Store} />
                <Route path={APP_URL.orders} component={Store} />
                <Route path={APP_URL.orderMake} component={Store} />
                <Route path={APP_URL.orderPayment} component={Store} />

                <Route path={APP_URL.goods} component={Goods} />

                <Route exact path={APP_URL.reports} component={Reports} />
                <Route path={APP_URL.reportsAccounts} component={ReportsV1} />
                <Route path={APP_URL.reportsPrivilegeMembers} component={ReportsPrivilegeMembers} />
                <Route path={APP_URL.reportsFreedom} component={ReportsFreedom} />
                <Route path={APP_URL.report} component={Report} />

                <Route exact path={APP_URL.support} component={Support} />
                <Route path={APP_URL.ticket} component={Ticket} />

                {/* profilePosts profileFriends */}
                <Route path={APP_URL.profile} component={UserProfile} />
                <Route path={APP_URL.userSettings} component={UserSettings} />

                <Route exact path={APP_URL.settings} component={Settings} />
                <Route path={APP_URL.settingsLab} component={SettingsLab} />

                <Route path={APP_URL.wallet} component={Wallet} />

                <Route exact path={APP_URL.friends} component={Friends} />
                <Route exact path={APP_URL.friendsRequest} component={FriendsRequest} />
                <Route path={APP_URL.friendsMutual} component={FriendsMutual} />

                <Route path={APP_URL.messenger} component={Messenger} />

                <Route exact path={APP_URL.calendar} component={Calendar} />
                <Route path={APP_URL.calendarDate} component={Calendar} />

                {/* dev */}
                <Route path={APP_URL.account} component={DevModule} />
                <Route path={APP_URL.kicks} component={DevModule} />
                <Route path={APP_URL.popular} component={DevModule} />
                <Route path={APP_URL.invite} component={DevModule} />
                <Route path={APP_URL.receipt} component={DevModule} />
                <Route path={APP_URL.lessons} component={DevModule} />

                <Redirect from="*" to={mainPrivateRoute} />
            </Switch>
        </Suspense>
    )

    useEffect(() => {
        dispatch(fetchProfile({ userId: user.id }))
    }, [])

    return (
        <>
            <header>
                <NavBar>
                    <NavMenu />
                </NavBar>
            </header>

            <Container>
                <PageContainer classes={style.pageContainer}>
                    {windowWidth >= BREAKPOINTS.tabletLandscape && (
                        <SideBarContainer position="left">
                            <Block classes={style.userInfo}>
                                <UserInfo />
                            </Block>

                            <div className={style.menuNav}>
                                <MenuNav />
                            </div>

                            <FooterLK />
                        </SideBarContainer>
                    )}

                    {routes}
                </PageContainer>
            </Container>

            {windowWidth < BREAKPOINTS.tabletLandscape && (
                <MenuNav isMobile />
            )}
        </>
    )
}

export default LKPage
