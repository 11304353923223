import React, { ButtonHTMLAttributes, Children } from 'react'
import cn from 'classnames'

import style from './Button.module.css'

type ButtonPropType = {
    classes?: string
    styleType?: 'primary' | 'default' | 'default_bordered' | 'bordered' | 'bordered2' | 'transparent' | 'text' | 'custom'
    size?: 'size32' | 'size36' | 'size40' | 'size44' | 'size46' | 'size48'
    text?: string
    textUpper?: boolean
    fullWidth?: boolean
    onClick?: (e: React.SyntheticEvent<HTMLButtonElement>) => void
} & Omit<ButtonHTMLAttributes<HTMLButtonElement>, 'className'>

const Button: React.FC<ButtonPropType> = ({
    children,
    classes,
    styleType = 'primary',
    type = 'button',
    size,
    text,
    textUpper,
    fullWidth,
    onClick,
    ...params
}) => {
    return (
        <button
            className={cn(
                style[styleType],
                size ? style[size] : undefined,
                {
                    [style.textUpper]: textUpper,
                    [style.fullWidth]: fullWidth,
                },
                classes,
            )}
            type={type}
            onClick={onClick}
            {...params}
        >
            {text && (
                <span className={style.content}>
                    {text}
                </span>
            )}

            {Children.count(children) > 0 && (
                children
            )}
        </button>
    )
}

export default Button
