import {
    IUser,
    IUserTag,
    IProfile,
    IGeolocation,
    ICounts,
} from 'interfaces'
import {
    AUTHORIZE_USER,
    MLM_USER,
    FETCH_USER,
    FETCH_USER_TAGS,
    FETCH_USER_PROFILE,
    UPDATE_USER,
    UPDATE_USER_PROFILE,
    LOGOUT_USER,
    SET_GEOLOCATION,
    FETCH_COUNTS,
    FETCH_COUNT,
    RESET_COUNT,
} from 'reducers/types'
import {
    authorizeUserActionType,
    setMlmActionType,
    fetchUserActionType,
    updateUserActionType,
    fetchProfileActionType,
    setGeolocationActionType,
    fetchTagsActionType,
    fetchCountsActionType,
    fetchCountActionType,
    deleteCountActionType,
} from './user-actions-type'

export type userActionsTypes = fetchUserActionType | updateUserActionType

export type profileActionType = fetchProfileActionType

export type tagActionType = fetchTagsActionType

export type countActionType = fetchCountsActionType | fetchCountActionType | deleteCountActionType

export const authorizeReducer = (state: boolean = false, action: authorizeUserActionType): boolean => {
    switch (action.type) {
        case AUTHORIZE_USER:
            return action.payload

        default:
            return state
    }
}

export const mlmReducer = (state: boolean = false, action: setMlmActionType): boolean => {
    switch (action.type) {
        case MLM_USER:
            return action.payload

        default:
            return state
    }
}

export const userReducer = (state: null | IUser = null, action: userActionsTypes): null | IUser => {
    switch (action.type) {
        case FETCH_USER:
            return action.payload

        case UPDATE_USER:
            return { ...state, ...action.payload }

        case LOGOUT_USER:
            return null

        default:
            return state
    }
}

export const profileReducer = (state: null | IProfile = null, action: profileActionType): null | IProfile => {
    switch (action.type) {
        case FETCH_USER_PROFILE:
            return action.payload

        case UPDATE_USER_PROFILE:
            return state ? { ...state, ...action.payload } : action.payload

        default:
            return state
    }
}

export const geolocationReducer = (state: null | IGeolocation = null, action: setGeolocationActionType)
    : null | IGeolocation => {
    switch (action.type) {
        case SET_GEOLOCATION:
            return action.payload

        default:
            return state
    }
}

export const tagReducer = (state: IUserTag[] = [], action: tagActionType): IUserTag[] => {
    switch (action.type) {
        case FETCH_USER_TAGS:
            return action.payload

        default:
            return state
    }
}

export const countReducer = (state: object | ICounts = {}, action: countActionType): object | ICounts => {
    switch (action.type) {
        case FETCH_COUNTS:
            return (action.payload as ICounts)

        case FETCH_COUNT: {
            const { counter, count } = (action.payload as fetchCountActionType['payload'])
            return { ...state, [counter]: count }
        }

        case RESET_COUNT: {
            const { counter } = (action.payload as deleteCountActionType['payload'])
            return counter in state ? { ...state, [counter]: 0 } : state
        }

        default:
            return state
    }
}
