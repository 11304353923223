import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import cn from 'classnames'

import { IReportMlmRank, IReportMlmProgress } from 'interfaces'
import { ChartConfiguration } from 'components/Chart/Chart'
import { API_HOST } from 'config/api'
import { Image, Chart, SvgResource } from 'components'
// eslint-disable-next-line
import doughnutTextCenter, { DoughnutTextCenterOptions } from 'components/Chart/plugins/doughnutTextCenter'
// eslint-disable-next-line
import doughnutBackground, { DoughnutBackgroundOptions } from 'components/Chart/plugins/doughnutBackground'
import { numberFormat } from 'utils/helpers'
import { vBlue9 } from 'styles/modules/variables.module.css'
import style from './ReportsMlmRank.module.css'

type ReportsMlmRankPropType = {
    current: IReportMlmRank
    next: IReportMlmRank
    progress: IReportMlmProgress
}

// type ChartConfigExtended = ChartConfiguration<'doughnut'> & {
//     options: ChartOptions<'doughnut'> & {
//         plugins: PluginOptionsByType<'doughnut'> & {
//             doughnutTextCenter: DoughnutTextCenterOptions
//             doughnutBackground: DoughnutBackgroundOptions
//         }
//     }
// }

// type ChartConfigExtended = ChartConfiguration<'doughnut'> & {
//     options: ChartOptions<'doughnut'> & PluginChartOptions<'doughnut'> & {
//         doughnutTextCenter: DoughnutTextCenterOptions
//         doughnutBackground: DoughnutBackgroundOptions
//     }
// }

const MAX_PERCENTAGES = 100

const defaults = {
    color: vBlue9,
}

const ReportsMlmRank: React.FC<ReportsMlmRankPropType> = ({
    current,
    next,
    progress,
}) => {
    const { t } = useTranslation()

    const [isShowChart, setIsShowChart] = useState(false)

    const values = useMemo(() => {
        const { progress: progressValue } = progress
        return progressValue !== undefined ? [progressValue, MAX_PERCENTAGES - progressValue] : []
    }, [progress])

    const progressCurrentValue = useMemo(() => {
        const { value } = progress
        return value !== undefined ? numberFormat(value) : undefined
    }, [progress])

    const progressNextValue = useMemo(() => {
        const { rest } = progress
        return rest !== undefined ? numberFormat(rest) : undefined
    }, [progress])

    const chartConfig: ChartConfiguration = useMemo(() => {
        return {
            type: 'doughnut',
            data: {
                datasets: [{
                    backgroundColor: [defaults.color, 'transparent'],
                    hoverBackgroundColor: [defaults.color, 'transparent'],
                    borderRadius: [values[0] !== MAX_PERCENTAGES ? { innerEnd: 20, outerEnd: 20 } : 0, 0],
                    data: values,
                }],
            },
            options: {
                responsive: false,
                cutout: '70%',
                borderWidth: 0,
                borderJoinStyle: 'round',
                plugins: {
                    legend: {
                        display: false,
                    },
                    tooltip: {
                        enabled: false,
                    },
                    doughnutTextCenter: {
                        enabled: true,
                        text: `${progress.progress ?? 0}%`,
                        font: 'bolder 26px sans-serif',
                        fontColor: defaults.color,
                    },
                    doughnutBackground: {
                        enabled: true,
                    },
                },
            },
            plugins: [doughnutTextCenter, doughnutBackground],
        }
    }, [values, progress])

    useEffect(() => {
        setIsShowChart(true)
    }, [])

    return (
        <>
            <div className={style.title}>
                {current.name}
                <SvgResource
                    classes={style.iconArrow}
                    isImgTag={false}
                    resourceKey="dropdpwn_daw_svg"
                    width={20}
                    height={12}
                />
            </div>
            {progress.name && (
                <div className={style.subtitle}>
                    {progress.name}
                </div>
            )}
            {progress.message && (
                <div className={style.message}>
                    {progress.message}
                </div>
            )}
            <div className={style.columns}>
                <div className={cn(style.column, style.rank)}>
                    <Image
                        classesWrap={style.rankImageWrap}
                        src={`${API_HOST}${current.icon}`}
                        width={100}
                        height={61}
                        resize={{ width: 200, height: 122, quality: 100 }}
                        isPlaceholder={false}
                    />
                    <div className={style.rankValue}>
                        {progressCurrentValue}
                        {' '}
                        {progress.unit}
                    </div>
                    <div className={style.rankText}>
                        {t('report_freedom_current_rank')}
                    </div>
                    <div className={style.rankName}>
                        {current.name}
                    </div>
                </div>
                <div className={style.column}>
                    <Chart
                        config={isShowChart ? chartConfig : undefined}
                        options={{ className: style.rankChart, width: 140, height: 140 }}
                    />
                </div>
                <div className={cn(style.column, style.rank)}>
                    <Image
                        classesWrap={style.rankImageWrap}
                        src={`${API_HOST}${next.icon}`}
                        width={100}
                        height={61}
                        resize={{ width: 200, height: 122, quality: 100 }}
                        isPlaceholder={false}
                    />
                    <div className={style.rankValue}>
                        {progressNextValue}
                        {' '}
                        {progress.unit}
                    </div>
                    <div className={style.rankText}>
                        {t('report_freedom_next_rank')}
                    </div>
                    <div className={style.rankName}>
                        {next.name}
                    </div>
                </div>
            </div>
        </>
    )
}

export default ReportsMlmRank
