import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import cn from 'classnames'

import {
    IBreadcrumb,
    ICurrency,
    IMlmIncome,
    IMlmIncomeType,
} from 'interfaces'
import { DatePairType } from 'components/DateRangeFilter/DateRangeFilter'
import { TabsItemType } from 'components/Tabs/TabsItem/TabsItem'
import { DatePeriod } from 'enums'
import { APP_URL } from 'config/app'
import { CardContainer, Block } from 'layout'
import { ReportsFreedomChart } from 'containers/ReportsFreedom/components'
import { useFetchMlmReportsIncomeList, useFetchMlmReportsIncomeGraph } from 'containers/ReportsFreedom/hooks'
import { useFetchCurrencies } from 'containers/App/hooks'
import {
    PageTitle,
    Breadcrumbs,
    DateRangeFilter,
    Loader,
    InfiniteScroll,
    Tabs,
    NoDataInfo,
} from 'components'
import {
    defaultDateFormat,
    numberFormat,
    parseTpl,
    groupBy,
    arrayToObjectByKey,
} from 'utils/helpers'
import {
    ReportsFreedomIncomeListGroup,
    ReportsFreedomIncomeListItem,
    ReportsFreedomIncomeListItemContent,
} from './components'
import styleReports from '../ReportsFreedom/ReportsFreedom.module.css'
import style from './ReportsFreedomIncome.module.css'

const MIN_DATE_PV = '2017-03-01'

const getIncomeListGroups = (list: IMlmIncome[]) => {
    return groupBy(list, 'date', (value) => defaultDateFormat(value))
}

const ReportsFreedomIncome: React.FC = () => {
    const { t } = useTranslation()

    const [incomeList, setIncomeList] = useState<IMlmIncome[]>([])
    const [incomeGroups, setIncomeGroups] = useState<Record<string, IMlmIncome[]>>({})
    const [incomeTypes, setIncomeTypes] = useState<Record<number, IMlmIncomeType>>({})
    const [incomeTabs, setIncomeTabs] = useState<TabsItemType[]>([])
    const [activeTabId, setActiveTabId] = useState<number>(0)
    const [currencies, setCurrencies] = useState<Record<number, ICurrency>>({})
    const [limit] = useState(15)
    const [offset, setOffset] = useState(0)

    const [datePeriodIncome, setDatePeriodIncome] = useState<DatePeriod>()
    const [dateFilterIncome, setDateFilterIncome] = useState<DatePairType>()
    const [dateFilterIncomeList, setDateFilterIncomeList] = useState<DatePairType>()

    const [isResetUserListPrevData, setIsResetUserListPrevData] = useState(false)
    const [isHideInfiniteLoader, setIsHideInfiniteLoader] = useState(false)

    const {
        isInitialLoading: isLoadingIncome,
        isFetching: isFetchingIncome,
        data: dataReportsFreedomIncomeGraph,
    } = useFetchMlmReportsIncomeGraph({
        dateFrom: dateFilterIncome?.dateFrom,
        dateTo: dateFilterIncome?.dateTo,
    }, {
        keepPreviousData: true,
        enabled: !!dateFilterIncome,
    })

    const {
        isInitialLoading: isLoadingIncomeList,
        data: dataMlmIncomeList,
    } = useFetchMlmReportsIncomeList({
        dateFrom: dateFilterIncomeList?.dateFrom,
        dateTo: dateFilterIncomeList?.dateTo,
        typeId: activeTabId || undefined,
        limit,
        offset,
    }, {
        enabled: !!dateFilterIncomeList,
    })

    const { data: dataCurrencies } = useFetchCurrencies()

    const breadcrumbs = useMemo<IBreadcrumb[]>(() => {
        const parent = { id: 1, name: t('reports'), url: APP_URL.reports }

        if (dataReportsFreedomIncomeGraph) {
            return [
                parent,
                { id: 2, name: dataReportsFreedomIncomeGraph.title },
            ]
        }
        return [
            { id: 1, name: t('reports'), url: APP_URL.reports },
        ]
    }, [dataReportsFreedomIncomeGraph])

    const chartTotalActivePartners = useMemo(() => {
        const { total } = dataReportsFreedomIncomeGraph || {}
        return total !== undefined ? numberFormat(total) : undefined
    }, [dataReportsFreedomIncomeGraph])

    const orderText = useMemo(() => {
        return parseTpl(t('business_kicks_history_order'), { '%s': '' }, { prefix: '', suffix: '' })
    }, [])

    const defaultDateMonthFilterRange = useMemo(() => {
        const dateCurrent = new Date()
        return new Date(dateCurrent.getFullYear(), dateCurrent.getMonth(), 0) // prev month
    }, [])

    const incomeListTitle = useMemo(() => {
        if (incomeTabs) {
            const incomeType = incomeTabs.find((item) => item.id === activeTabId)
            return incomeType ? incomeType.name : undefined
        }

        return t('reports_freedom_total_income')
    }, [incomeTabs, activeTabId])

    const handlerChangeDateFilterIncome = (dates: DatePairType, period: DatePeriod) => {
        setDateFilterIncome(dates)
        setDatePeriodIncome(period)
    }

    const handlerChangeDateFilterIncomeList = (dates: DatePairType, period: DatePeriod) => {
        setDateFilterIncomeList(dates)
        resetUserList()
    }

    const handlerChangeTypeIncomeList = (id: number) => {
        setActiveTabId(id)
        resetUserList()
    }

    const handlerOnLoad = () => {
        setOffset((prevSate) => prevSate + limit)
    }

    function getIncomeListTabs(types: IMlmIncomeType[]) {
        const items = types.filter((item) => !!item.count)

        if (items.length) {
            const tabs = items.map(({ id, name }) => ({ id, name }))
            return [{ id: 0, name: t('reports_freedom_total_income') }, ...tabs]
        }

        return []
    }

    function resetUserList() {
        setIsResetUserListPrevData(true)
        setOffset(0)
        setIsHideInfiniteLoader(false)
    }

    useEffect(() => {
        if (dataMlmIncomeList) {
            const { data, types } = dataMlmIncomeList
            const list = isResetUserListPrevData ? data : [...incomeList, ...data]

            setIncomeList(list)
            setIncomeGroups(getIncomeListGroups(list))
            setIsResetUserListPrevData(false)

            if (!Object.keys(incomeTypes).length) {
                setIncomeTypes(arrayToObjectByKey(types, 'id'))
                setIncomeTabs(getIncomeListTabs(types))
            }
            if (data.length < limit) {
                setIsHideInfiniteLoader(true)
            }
        }
    }, [dataMlmIncomeList])

    useEffect(() => {
        if (dataCurrencies) {
            setCurrencies(arrayToObjectByKey(dataCurrencies, 'iso'))
        }
    }, [dataCurrencies])

    return (
        <>
            <PageTitle>
                <Breadcrumbs items={breadcrumbs} />
            </PageTitle>

            <Block classes={styleReports.block}>
                <Block.Header title={dataReportsFreedomIncomeGraph?.title ?? ''} />
                <DateRangeFilter
                    activeColor={dataReportsFreedomIncomeGraph?.color}
                    defaultDateMonth={defaultDateMonthFilterRange}
                    onChange={handlerChangeDateFilterIncome}
                />
                <div
                    className={cn(
                        styleReports.chartTotal,
                        { [styleReports.chartTotal_updating]: isFetchingIncome },
                    )}
                    style={{ color: dataReportsFreedomIncomeGraph?.color }}
                >
                    {chartTotalActivePartners}
                </div>
                {isLoadingIncome && (
                    <Loader />
                )}
                {dataReportsFreedomIncomeGraph && (
                    <ReportsFreedomChart
                        data={dataReportsFreedomIncomeGraph}
                        period={datePeriodIncome}
                        isLoading={isFetchingIncome}
                    />
                )}
            </Block>
            <Block classes={styleReports.block}>
                <Block.Header title={incomeListTitle} />
                <Tabs
                    classes={style.tabs}
                    classesItem={style.tabsItem}
                    classesActive={style.tabsItemActive}
                    classesText={style.tabsItemText}
                    items={incomeTabs}
                    active={activeTabId}
                    onChange={handlerChangeTypeIncomeList}
                />
                <DateRangeFilter
                    activeColor={dataReportsFreedomIncomeGraph?.color}
                    defaultDateMonth={defaultDateMonthFilterRange}
                    minDate={MIN_DATE_PV}
                    onChange={handlerChangeDateFilterIncomeList}
                />
                <CardContainer classes={style.list}>
                    <InfiniteScroll
                        isActive={!isLoadingIncomeList}
                        options={{ rootMargin: '0px 0px 50% 0px' }}
                        isHideLoader={isHideInfiniteLoader}
                        onLoad={handlerOnLoad}
                    >
                        {Object.entries(incomeGroups)
                            .map(([key, items]) => (
                                <ReportsFreedomIncomeListGroup date={key} key={key}>
                                    {items.map((item) => (
                                        <ReportsFreedomIncomeListItem data={item} key={item.id}>
                                            <ReportsFreedomIncomeListItemContent
                                                data={item}
                                                orderTitle={orderText}
                                                isLink={!!item.post_id || !!item.store_id}
                                                incomeType={incomeTypes[item.type]}
                                                currencyData={currencies[item.currency]}
                                            />
                                        </ReportsFreedomIncomeListItem>
                                    ))}
                                </ReportsFreedomIncomeListGroup>
                            ))}

                        {!isLoadingIncomeList && !Object.keys(incomeGroups).length && (
                            <NoDataInfo
                                classes={styleReports.listNoData}
                                text={t('no_rewards_in_period')}
                            />
                        )}
                    </InfiniteScroll>
                </CardContainer>
            </Block>
        </>
    )
}

export default ReportsFreedomIncome
