import React, { useMemo, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { TStoreSettingsUpdateProps } from 'services/StoreService'
import { CardSize } from 'enums'
import { APP_URL, BREAKPOINTS } from 'config/app'
import { CardContainer, ContentContainer } from 'layout'
import {
    PageHeader,
    Loader,
    InfiniteScroll,
    NoDataInfo,
    ErrorMsg,
} from 'components'
import {
    useFetchInfiniteStoreSettings,
    useMutationStoreSettings,
    useSetInfiniteQueryDataStoreSettings,
    useRemoveQueriesDataStoreProfile,
} from 'containers/Store/hooks'
import { useWindowResize } from 'hooks'
import { scrollTop } from 'utils/helpers'
import { MarketStoreItem } from './components'
import { useRemoveQueriesDataShopsStatic } from './hooks'
import style from './Market.module.css'

const LIMIT = 12
const OFFSET = 0

/**
 * KW-122 Страница настройки магазинов - добавление в избранное
 */
const Market: React.FC = () => {
    const { t } = useTranslation()
    const history = useHistory()
    const [windowWidth] = useWindowResize()

    const [isHideLoader, setIsHideLoader] = useState(false)

    const {
        isInitialLoading: isLoadingStoreSettings,
        data: dataStoreSettings,
        error: errorStoreSettings,
        fetchNextPage: fetchNextPageStoreSettings,
    } = useFetchInfiniteStoreSettings({
        limit: LIMIT,
        offset: OFFSET,
    })

    const { update: updateStoreSettings } = useMutationStoreSettings()

    const { remove: removeQueriesDataShopsStatic } = useRemoveQueriesDataShopsStatic()

    const { remove: removeQueriesDataStoreProfile } = useRemoveQueriesDataStoreProfile()

    const { setQueryData: setInfiniteQueryDataStoreSettings } = useSetInfiniteQueryDataStoreSettings({
        limit: LIMIT,
        offset: OFFSET,
    })

    const itemSize = useMemo(() => {
        if (windowWidth > BREAKPOINTS.tabletLandscape) {
            return CardSize.thirdWidth
        }
        return CardSize.halfWidth
    }, [windowWidth])

    const handlerLoad = () => {
        if (dataStoreSettings) {
            fetchNextPageStoreSettings()
        }
    }

    const handlerClickBack = () => {
        history.push(APP_URL.favoriteShops)
    }

    const handlerStoreSubscribe = (id: number, isSubscribe: boolean) => {
        updateStoreSettingsAction({
            limit: 0,
            subscribe_list: [{ id, is_subscribe: isSubscribe }],
        })
    }

    function updateStoreSettingsAction({ subscribe_list: subscribeList, ...params }: TStoreSettingsUpdateProps) {
        const [listItem] = subscribeList || []
        const { id } = listItem || {}

        updateStoreSettings.mutate({ subscribe_list: subscribeList, ...params }, {
            onSuccess: (res) => {
                if (res && id) {
                    const currentStoreSettings = res.find((item) => item.id === id)

                    if (currentStoreSettings) {
                        setInfiniteQueryDataStoreSettings(currentStoreSettings)
                        removeQueriesDataShopsStatic({}, { exact: false })
                        removeQueriesDataStoreProfile({ id })
                    }
                }
            },
        })
    }

    useEffect(() => {
        const scrollTimerId = setTimeout(scrollTop)

        return () => {
            clearTimeout(scrollTimerId)
        }
    }, [])

    useEffect(() => {
        if (dataStoreSettings) {
            const { pages } = dataStoreSettings
            const pagesLen = pages.length

            if (pagesLen && pages[pagesLen - 1].length < LIMIT) {
                setIsHideLoader(true)
            }
        }
    }, [dataStoreSettings])

    return (
        <ContentContainer classes={style.content} size="three-quarters">
            <PageHeader
                title={t('stores')}
                onClickBack={handlerClickBack}
            />

            {isLoadingStoreSettings && (
                <Loader />
            )}

            {!isLoadingStoreSettings && dataStoreSettings && !dataStoreSettings.pages.length && (
                <NoDataInfo text={t('nothing_found')} />
            )}

            <InfiniteScroll
                options={{ rootMargin: '0px 0px 25% 0px' }}
                isActive={!!dataStoreSettings}
                isHideLoader={isHideLoader}
                onLoad={handlerLoad}
            >
                <CardContainer>
                    {dataStoreSettings?.pages.map((page, i) => (
                        /* eslint-disable-next-line */
                        <React.Fragment key={i}>
                            {page.map((item) => (
                                <MarketStoreItem
                                    data={item}
                                    size={itemSize}
                                    key={item.id}
                                    onSubscribe={handlerStoreSubscribe}
                                />
                            ))}
                        </React.Fragment>
                    ))}
                </CardContainer>
            </InfiniteScroll>

            {!isLoadingStoreSettings && !!errorStoreSettings?.length && (
                <ErrorMsg error={errorStoreSettings[0]} />
            )}
        </ContentContainer>
    )
}

export default Market
