import React, { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import cn from 'classnames'

import { IMlmPrivilegeSum } from 'interfaces'
import { Button } from 'components'
import { numberFormat, splitStr, plural } from 'utils/helpers'
import { PrivilegeScoreContentLog, PrivilegeSlideDown } from 'containers/Privilege/components'
import style from './PrivilegeScoreContent.module.css'

type PrivilegeScoresPropType = {
    data: IMlmPrivilegeSum
    onClickPrivilegeHistory: (id: number) => void
}

const NUMBER_FORMAT_OPTIONS = { minimumFractionDigits: 2, maximumFractionDigits: 2 }

const PrivilegeScoreContent: React.FC<PrivilegeScoresPropType> = ({ data, onClickPrivilegeHistory }) => {
    const { t } = useTranslation()

    const [childActive, setChildActive] = useState(data.children?.length ? data.children[0] : undefined)

    const tPoints = useMemo(() => {
        return [t('points_count_1x'), t('points_count_2x'), t('points_count_5x')]
    }, [])

    const total = useMemo(() => numberFormat(data.total, NUMBER_FORMAT_OPTIONS), [data])

    const desc = useMemo(() => (data.description ? splitStr(data.description) : []), [data])

    const handlerClickChildAction = ({ currentTarget }: React.SyntheticEvent<HTMLButtonElement>) => {
        const child = data.children?.find((item) => String(item.id) === currentTarget.name)

        if (child) {
            setChildActive(child)
        }
    }

    return (
        <PrivilegeSlideDown title={data.name}>
            <div className={style.title}>
                <span className={style.titleValue}>
                    {total}
                </span>
                <span className={style.titleUnit}>
                    {plural(data.total, tPoints)}
                </span>
            </div>

            {data.children && (
                <>
                    {data.children && (
                        <div className={style.actions}>
                            {data.children.map((childItem) => (
                                <Button
                                    classes={cn(style.action, {
                                        [style.action_active]: childItem.id === childActive?.id,
                                    })}
                                    styleType="bordered2"
                                    size="size36"
                                    name={String(childItem.id)}
                                    key={childItem.id}
                                    onClick={handlerClickChildAction}
                                >
                                    {childItem.name}
                                </Button>
                            ))}
                        </div>
                    )}

                    {childActive?.log && (
                        <div className={style.log}>
                            <PrivilegeScoreContentLog
                                typeId={childActive.id}
                                items={childActive.log}
                                onClickHistory={onClickPrivilegeHistory}
                            />
                        </div>
                    )}
                </>
            )}

            {data.log && (
                <div className={style.log}>
                    <PrivilegeScoreContentLog
                        typeId={data.id}
                        items={data.log}
                        onClickHistory={onClickPrivilegeHistory}
                    />
                </div>
            )}

            {!!desc?.length && (
                <div className={style.desc}>
                    {desc.map((descItem) => (
                        <div key={descItem.id}>
                            {descItem.text}
                        </div>
                    ))}
                </div>
            )}
        </PrivilegeSlideDown>
    )
}

export default PrivilegeScoreContent
