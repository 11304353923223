import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { IMlmPrivilegeHistory } from 'interfaces'
import { LoadDataType } from 'components/InfiniteScroll/InfiniteScroll'
import { CardContainer } from 'layout'
import {
    Card,
    TimeAgo,
    NoDataInfo,
    ErrorMsg,
    InfiniteScroll,
} from 'components'
import { useFetchPrivilegeHistory } from 'containers/User/hooks'
import { numberFormat } from 'utils/helpers'
import style from './PrivilegeHistory.module.css'

type PrivilegeHistoryPropType = {
    id?: number
}

const DATE_FORMAT_OPTIONS: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'short', day: 'numeric' }
const AMOUNT_FORMAT_OPTIONS = { minimumFractionDigits: 2, maximumFractionDigits: 2 }

const PrivilegeHistory: React.FC<PrivilegeHistoryPropType> = ({ id }) => {
    const { t } = useTranslation()

    const [items, setItems] = useState<IMlmPrivilegeHistory[]>([])
    const [limit] = useState(15)
    const [offset, setOffset] = useState<number>()

    const [isHideLoader, setIsHideLoader] = useState(false)

    const { isLoading, data, error } = useFetchPrivilegeHistory({
        type: id ?? 0,
        limit,
        offset,
    }, {
        enabled: !!id && offset !== undefined,
    })

    const handlerOnLoad = ({ page }: LoadDataType) => {
        if (!isLoading) {
            setOffset(page * limit)
        }
    }

    useEffect(() => {
        if (data) {
            setItems((prevState) => [...prevState, ...data])

            if (data.length < limit) {
                setIsHideLoader(true)
            }
        }
    }, [data])

    return (
        <>
            {!isLoading && !items.length && (
                <NoDataInfo text={t('nothing_found')} />
            )}

            {!isLoading && error && (
                <ErrorMsg error={error} />
            )}

            <CardContainer>
                <InfiniteScroll
                    options={{ rootMargin: '0px' }}
                    isHideLoader={isHideLoader}
                    onLoad={handlerOnLoad}
                >
                    {items.map((item) => (
                        <Card.Item classes={style.item} url={item.link} key={item.id}>
                            <Card.Body classes={style.body}>
                                <div>
                                    <div className={style.name}>
                                        {item.type.name}
                                    </div>
                                    <div className={style.date}>
                                        <TimeAgo time={item.date} options={DATE_FORMAT_OPTIONS} />
                                    </div>
                                </div>
                                <div className={style.value}>
                                    {numberFormat(item.amount, AMOUNT_FORMAT_OPTIONS)}
                                </div>
                            </Card.Body>
                        </Card.Item>
                    ))}
                </InfiniteScroll>
            </CardContainer>
        </>
    )
}

export default PrivilegeHistory
