import {
    UseQueryOptions,
    // UseInfiniteQueryOptions,
    // InvalidateQueryFilters,
    // InvalidateOptions,
    // SetDataOptions,
    // InfiniteData,
    // useQueryClient,
    // useInfiniteQuery,
    useQuery,
    // useMutation,
} from '@tanstack/react-query'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { IGoods } from 'interfaces'
import { TStorePopularGoodsProps } from 'services/StoreService'
import { QueryCacheKeys } from 'enums'
import { StoreService } from 'services'
import * as userSelectors from 'containers/User/user-selectors'
import { getRequestError } from 'utils/helpers'

export type TFetchFnParams = TStorePopularGoodsProps
export type TError = string
export type TQueryKey = [string, TFetchFnParams]
export type TQueryFnOpts = UseQueryOptions<IGoods[], TError, IGoods[], TQueryKey>
// export type TInfiniteQueryFnOpts = UseInfiniteQueryOptions<
//     IStoreProfile[],
//     TError,
//     IStoreProfile[],
//     IStoreProfile[],
//     TQueryKey
// >

const key = QueryCacheKeys.storePopularGoods

/**
 * Хук API получить магазины
 */
export default function useFetchStorePopularGoods(params: TFetchFnParams, {
    cacheTime = 600 * 1000, // 10m
    staleTime = 600 * 1000, // 10m
    ...opts
}: TQueryFnOpts = {}) {
    const { t } = useTranslation()
    const user = useSelector(userSelectors.user)

    return useQuery([`${key}-${user.id}`, params], () => {
        return StoreService.fetchStorePopularGoods(params)
            .then(({ data }) => {
                return data
            })
            .catch((err) => {
                return Promise.reject(getRequestError(err) || t('update_error'))
            })
    }, {
        cacheTime,
        staleTime,
        ...opts,
    })
}
