import React from 'react'
import cn from 'classnames'

import { IMlmPrivilegeStatus } from 'interfaces'
import { SvgResource } from 'components'
import { useImgResource } from 'hooks'
import style from './PrivilegeStatusItem.module.css'

type PrivilegeStatusItemPropType = {
    data: IMlmPrivilegeStatus
    card: string
    isLock: boolean
}

const PrivilegeStatusItem: React.FC<PrivilegeStatusItemPropType> = ({ data, card, isLock }) => {
    const { url } = useImgResource(data.bg)

    return (
        <div className={style.item} style={{ backgroundColor: data.lockColor, backgroundImage: `url(${url})` }}>
            <div className={style.name} style={{ color: data.color }}>
                {data.drawTitle && data.name}
            </div>
            <div className={cn(style.card, { [style.card_mt]: !data.drawTitle })} style={{ color: data.color }}>
                {card}
            </div>
            {isLock && (
                <div className={style.lock}>
                    {/* TODO svg color = data.lockColor, svg update from KW-90 */}
                    <SvgResource
                        isImgTag={false}
                        resourceKey="mlm_privilege_status_lock"
                        width={22}
                        height={31}
                    />
                </div>
            )}
        </div>
    )
}

export default PrivilegeStatusItem
