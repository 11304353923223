import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import cn from 'classnames'

import { IBreadcrumb } from 'interfaces'
import { DatePairType } from 'components/DateRangeFilter/DateRangeFilter'
import { DatePeriod } from 'enums'
import { APP_URL } from 'config/app'
import { Block } from 'layout'
import { ReportsFreedomRankCard } from 'containers/ReportsFreedom/components'
import { useFetchMlmReportsRanksMax, useFetchMlmReportsRanksNew } from 'containers/ReportsFreedom/hooks'
import {
    PageTitle,
    Breadcrumbs,
    DateRangeFilter,
    Loader,
    NoDataInfo,
} from 'components'
import { vBlue9 } from 'styles/modules/variables.module.css'
import { ReportsFreedomRankUser } from './components'
import style from './ReportsFreedomRanks.module.css'
import styleReports from '../ReportsFreedom/ReportsFreedom.module.css'

const DATE_RANGE_FILTER_PERIODS = [
    DatePeriod.month,
    DatePeriod.year,
    DatePeriod.all,
    DatePeriod.custom,
]

const ReportsFreedomRanks: React.FC = () => {
    const { t } = useTranslation()

    const [dateFilter, setDateFilter] = useState<DatePairType>()
    const [breadcrumbs, setBreadcrumbs] = useState<IBreadcrumb[]>([
        { id: 1, name: t('reports'), url: APP_URL.reports },
    ])

    const {
        isInitialLoading: isLoadingRankUserMax,
        data: dataRankUserMax,
    } = useFetchMlmReportsRanksMax({ line: 0 })

    const {
        isInitialLoading: isLoadingRankUserListNew,
        data: dataRankUserListNew,
    } = useFetchMlmReportsRanksNew({
        dateFrom: dateFilter?.dateFrom,
        dateTo: dateFilter?.dateTo,
    }, {
        keepPreviousData: true,
        enabled: !!dateFilter,
    })

    const {
        isLoading: isLoadingRankUserListMax,
        data: dataMlmReportsRankUserListMax,
    } = useFetchMlmReportsRanksMax({})

    const handlerChangeDateFilter = (dates: DatePairType) => {
        setDateFilter(dates)
    }

    useEffect(() => {
        if (dataRankUserMax) {
            const [maxRank] = dataRankUserMax

            setBreadcrumbs((prevState) => [
                ...prevState,
                { id: 2, name: maxRank ? maxRank.name : t('reports_freedom_max_rank') },
            ])
        }
    }, [dataRankUserMax])

    return (
        <>
            <PageTitle>
                <Breadcrumbs items={breadcrumbs} />
            </PageTitle>

            {isLoadingRankUserMax && (
                <Loader />
            )}

            {!isLoadingRankUserMax && dataRankUserMax && !dataRankUserMax.length && (
                <NoDataInfo text={t('no_data')} />
            )}

            {!!dataRankUserMax?.length && (
                <>
                    <Block classes={style.block}>
                        <ReportsFreedomRankUser data={dataRankUserMax[0]} />
                    </Block>
                    <Block classes={styleReports.block}>
                        <Block.Header title={t('reports_freedom_new_ranks')} />
                        <div className={styleReports.blockBody}>
                            <DateRangeFilter
                                classes={style.rankFilter}
                                periods={DATE_RANGE_FILTER_PERIODS}
                                activeColor={vBlue9}
                                onChange={handlerChangeDateFilter}
                            />
                            {dataRankUserListNew && (
                                <div className={styleReports.rankList}>
                                    {dataRankUserListNew.map((item) => (
                                        <NavLink
                                            className={cn(
                                                styleReports.rankListItem,
                                                styleReports.rankListLink,
                                            )}
                                            to={{
                                                pathname: APP_URL.reportsFreedomRanksUsers,
                                                state: {
                                                    type: 'new',
                                                    name: t('reports_freedom_new_ranks'),
                                                    rank: item,
                                                    dateFilter,
                                                },
                                            }}
                                            key={item.id}
                                        >
                                            <ReportsFreedomRankCard
                                                data={item}
                                            />
                                        </NavLink>
                                    ))}
                                </div>
                            )}
                            {!isLoadingRankUserListNew && dataRankUserListNew && !dataRankUserListNew.length && (
                                <NoDataInfo text={t('no_data')} />
                            )}
                        </div>
                    </Block>
                    <Block classes={styleReports.block}>
                        <Block.Header title={t('reports_freedom_max_ranks')} />
                        {dataMlmReportsRankUserListMax && (
                            <div className={cn(styleReports.blockBody, styleReports.rankList)}>
                                {dataMlmReportsRankUserListMax.map((item) => (
                                    <NavLink
                                        className={cn(
                                            styleReports.rankListItem,
                                            styleReports.rankListLink,
                                        )}
                                        to={{
                                            pathname: APP_URL.reportsFreedomRanksUsers,
                                            state: {
                                                type: 'max',
                                                name: t('reports_freedom_max_ranks'),
                                                rank: item,
                                                dateFilter,
                                            },
                                        }}
                                        key={item.id}
                                    >
                                        <ReportsFreedomRankCard
                                            data={item}
                                        />
                                    </NavLink>
                                ))}
                            </div>
                        )}
                        {!isLoadingRankUserListMax && !dataMlmReportsRankUserListMax?.length && (
                            <NoDataInfo text={t('no_data')} />
                        )}
                    </Block>
                </>
            )}
        </>
    )
}

export default ReportsFreedomRanks
