import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { IOrder } from 'interfaces'
import { fetchOrdersPropType } from 'services/MarketService'
import * as userSelectors from 'containers/User/user-selectors'
import { MarketService } from 'services'

export type TFetchFnParams = fetchOrdersPropType
export type TError = string
export type TQueryKey = [string, fetchOrdersPropType] | string[]
export type TQueryFnOpts = UseQueryOptions<IOrder[], TError, IOrder[], TQueryKey>

/**
 * Хук API получение списка заказов в магазине
 */
export default function useFetchStoreOrders(params: TFetchFnParams, {
    cacheTime = 30 * 1000, // 30s
    staleTime = 30 * 1000, // 30s
    ...opts
}: TQueryFnOpts = {}) {
    const { t } = useTranslation()
    const user = useSelector(userSelectors.user)

    return useQuery([`storeOrders-${user.id}`, params], () => {
        return MarketService.fetchOrders(params)
            .then(({ data }) => {
                return data
            })
            .catch((err) => {
                const [error] = err?.response?.data || []

                if (error && typeof error === 'string') {
                    return Promise.reject(error)
                }

                return Promise.reject(t('update_error'))
            })
    }, {
        cacheTime,
        staleTime,
        ...opts,
    })
}
