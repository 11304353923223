import React, { useState, useEffect } from 'react'
import cn from 'classnames'

import { Spinner } from 'components'
import { useImgResource, useOnLoadImage } from 'hooks'
import styleCommon from 'styles/modules/common.module.css'
import { getImageResizeSrc } from 'utils/helpers'

type ImgResourcePropType = {
    resourceKey: string
    classes?: string
    isShowLoader?: boolean
    width?: number
    height?: number
    resize?: {
        width?: number
        height?: number
        quality?: number
        format?: 'auto' | 'webp' | 'jpeg'
        fit?: 'scale-down' | 'contain' | 'cover' | 'pad'
    }
    onClick?: (e: React.SyntheticEvent<HTMLImageElement>) => void
}

/**
 * Component get remote image src by resource key
 */
const ImgResource: React.FC<ImgResourcePropType> = ({
    resourceKey,
    classes,
    isShowLoader = true,
    width,
    height,
    resize,
    onClick = () => {},
}) => {
    const [imgSrc, setImgSrc] = useState('')
    const [isLoading, setIsLoading] = useState(true)

    const { url } = useImgResource(resourceKey)
    const [loadedImg, error] = useOnLoadImage(imgSrc)

    useEffect(() => {
        if (url) {
            setImgSrc(resize ? getImageResizeSrc({ src: url, ...resize }) : url)
        }
    }, [url])

    useEffect(() => {
        if (loadedImg || error) {
            setIsLoading(false)
        }
    }, [loadedImg, error])

    return (
        <>
            {isShowLoader && isLoading && <Spinner size="medium" />}
            {/* eslint-disable-next-line */}
            <img
                className={cn(styleCommon.image, { [styleCommon.image_loading]: isLoading }, classes)}
                src={loadedImg ? loadedImg.src : ''}
                width={width}
                height={height}
                alt=""
                onClick={onClick}
            />
        </>
    )
}

export default ImgResource
