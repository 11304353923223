import React from 'react'

import {
    LogoLink,
    MenuNavMain,
} from 'components'
import style from './NavMenu.module.css'

type NavMenuPropType = {}

const NavMenu: React.FC<NavMenuPropType> = () => {
    return (
        <>
            <div className={style.colLeft}>
                <LogoLink path="/" />
            </div>
            <div className={style.colCenter}>
                {/* <div className={style.searchWrap}>
                    <Search
                        disabled
                        classesInput={style.searchInput}
                        styleType="transparent"
                        onSearch={() => {}}
                    />
                    <SvgResource
                        classes={style.iconSearch}
                        resourceKey="ic_search_white_thin_svg"
                        width={30}
                        height={30}
                    />
                </div> */}
            </div>
            <div className={style.colRight}>
                <MenuNavMain />
            </div>
        </>
    )
}

export default NavMenu
