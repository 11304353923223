import React, { useEffect, useMemo, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import cn from 'classnames'

import { IStoreCurrency, IOrder } from 'interfaces'
import {
    APP_URL,
    MARKET_ORDER_STATUS_READY_PAYMENT,
    MARKET_ORDER_STATUS_PAID,
} from 'config/app'
import { Block } from 'layout'
import { useFetchStoreProfile } from 'containers/Store/hooks'
import { InfiniteScroll, ErrorMsg } from 'components'
import {
    showAlertNotify,
    getStoreCurrency,
    getRequestError,
    parseTpl,
    scrollTop,
} from 'utils/helpers'
import { MarketService } from 'services'
import { StoreOrdersItem } from './components'
import { useFetchStoreOrders } from './hooks'
import style from './StoreOrders.module.css'

const ORDERS_LIMIT = 20
const ORDERS_OFFSET = 0

const StoreOrders: React.FC = () => {
    const { id } = useParams<{ id: string }>()
    const { t } = useTranslation()
    const history = useHistory()

    const [storeId] = useState(Number(id))
    const [isSetStoreId] = useState(!Number.isNaN(storeId))
    const [orders, setOrders] = useState<IOrder[]>([])
    const [offset, setOffset] = useState(ORDERS_OFFSET)

    const [isInit, setIsInit] = useState(false)
    const [isLoadingPaymentUrl, setIsLoadingPaymentUrl] = useState(false)
    const [isHideLoader, setIsHideLoader] = useState(false)

    const { data: dataStoreProfile } = useFetchStoreProfile({ id: storeId }, { enabled: isSetStoreId })

    const storeCurrency: IStoreCurrency | undefined = useMemo(() => {
        return dataStoreProfile ? getStoreCurrency(dataStoreProfile) : undefined
    }, [dataStoreProfile])

    const {
        isInitialLoading: isLoadingStoreOrders,
        data: ordersData,
        error: ordersError,
    } = useFetchStoreOrders({
        storeId,
        statuses: `${MARKET_ORDER_STATUS_READY_PAYMENT},${MARKET_ORDER_STATUS_PAID}`,
        limit: ORDERS_LIMIT,
        offset,
    }, {
        enabled: isSetStoreId,
    })

    const handlerClickPayment = (orderId: number) => {
        getOrderPaymentUrlAction(orderId)
    }

    const handlerOnLoad = () => {
        if (isInit) {
            setOffset((prevState) => prevState + ORDERS_LIMIT)
        }
    }

    function getOrderPaymentUrlAction(orderId: number) {
        setIsLoadingPaymentUrl(true)
        MarketService.getOrderPaymentUrl({ orderId })
            .then(({ data }) => {
                if (data) {
                    history.push(parseTpl(APP_URL.orderPayment, {
                        ':id': storeId,
                        ':orderId': orderId,
                    }, {
                        prefix: '',
                        suffix: '',
                    }))
                } else {
                    showAlertNotify({ type: 'error', message: t('update_error') })
                }
            })
            .catch((err) => {
                showAlertNotify({ type: 'error', message: getRequestError(err) || t('update_error') })
            })
            .finally(() => {
                setIsLoadingPaymentUrl(false)
            })
    }

    useEffect(() => {
        const scrollTimerId = setTimeout(scrollTop)

        return () => {
            clearTimeout(scrollTimerId)
        }
    }, [])

    useEffect(() => {
        if (!isSetStoreId) {
            setIsHideLoader(true)
        }
    }, [isSetStoreId])

    useEffect(() => {
        if (ordersData) {
            const ordersLength = ordersData.length

            if (ordersLength && !isInit) {
                setIsInit(true)
            }
            if (ordersLength < ORDERS_LIMIT) {
                setIsHideLoader(true)
            }

            setOrders((prevState) => ([...prevState, ...ordersData]))
        }
    }, [ordersData])

    useEffect(() => {
        if (ordersError) {
            setIsHideLoader(true)
        }
    }, [ordersError])

    return (
        <div className={style.content}>
            {!isLoadingStoreOrders && !orders.length && !ordersError && isSetStoreId && (
                <ErrorMsg error={t('nothing_found')} />
            )}

            {!isLoadingStoreOrders && ordersError && (
                <ErrorMsg error={ordersError} />
            )}

            <InfiniteScroll
                isHideLoader={isHideLoader}
                onLoad={handlerOnLoad}
            >
                {!!orders.length && (
                    <Block classes={cn(style.orders, { [style.updating]: isLoadingPaymentUrl })}>
                        <table className={style.list}>
                            <thead>
                                <tr>
                                    <th className={style.listCol}>
                                        {t('Number')}
                                    </th>
                                    <th className={style.listCol}>
                                        {t('delivery')}
                                    </th>
                                    <th className={style.listCol}>
                                        {t('date')}
                                    </th>
                                    <th className={style.listCol}>
                                        {t('order_sum')}
                                    </th>
                                    <th className={style.listCol}>
                                        {t('actions_title')}
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {orders.map((item) => (
                                    <StoreOrdersItem
                                        data={item}
                                        currency={storeCurrency}
                                        key={item.id}
                                        onClickPayment={handlerClickPayment}
                                    />
                                ))}
                            </tbody>
                        </table>
                    </Block>
                )}
            </InfiniteScroll>
        </div>
    )
}

export default StoreOrders
