import React, {
    useRef,
    useEffect,
    useMemo,
    useState,
} from 'react'
import { generatePath, useHistory, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import cn from 'classnames'

import {
    IHistoryMessage,
    IMessengerUser,
    IMessengerMessage,
    IMessengerWsEventMessageStatus,
    IMessengerWsEventMessageNew,
    IMessengerWsEventMessageError,
    IMessengerWsChatMessageProps,
    IMessengerWsChannelMessageProps,
    IMessengerWsStatusMessageProps,
    IMessengerWsForwardMessageProps,
} from 'interfaces'
import { TAddChannelMemberProps, TConversationListResponse } from 'services/ChatMicroService'
import { MessengerMessageStatus, MessengerMessageType } from 'enums'
import { APP_URL, BREAKPOINTS } from 'config/app'
import { ContentContainer, SideBarContainer, Block } from 'layout'
import {
    useMessenger,
    useMessengerChatUsersData,
    useMutationChannelMembers,
    useSetQueryDataConversationList,
} from 'containers/Messenger/hooks'
import { Button, Modal } from 'components'
import { useWindowResize } from 'hooks'
import { MessageAction } from 'form-actions'
import { showAlertNotify } from 'utils/helpers'
import { useAppSelector } from 'store'
import { useFetchChannel, useFetchChannelMembers, useFetchInfiniteHistoryChannel } from '../hooks'
import {
    MessengerContainer,
    MessengerHeader,
    MessengerHeaderChannel,
    MessengerBody,
    MessengerFooter,
    MessengerRoom,
    MessengerMenu,
    MessengerMessageReply,
    MessengerConversationList,
    MessengerFriends,
    MessengerSearch,
    MessengerSearchList,
    MessengerItemCheckBox,
    MessengerItemChat,
    MessengerItemChannel,
    MessengerItemUser,
} from '../components'
import style from './MessengerChannel.module.css'

type TWsMessage = IMessengerWsEventMessageNew | IMessengerWsEventMessageStatus | IMessengerWsEventMessageError

type TRetryMessageProps = (IMessengerWsChatMessageProps|IMessengerWsChannelMessageProps)[]

const LIMIT_HISTORY = 50
const LIMIT_CONVERSATION_LIST = 10

const MessengerChannel: React.FC = () => {
    const { id: channelId } = useParams<{ id: string }>()
    const { t } = useTranslation()
    const history = useHistory()
    const [windowWidth] = useWindowResize()

    const chatRef = useRef<HTMLDivElement>(null)

    const user = useAppSelector((state) => state.user)

    const [conversationListParams] = useState({ userId: user.id, page: 1, paginatedBy: LIMIT_CONVERSATION_LIST })
    const [websocketMessage, setWebsocketMessage] = useState<TWsMessage>()
    const [historyMessages, setHistoryMessages] = useState<IHistoryMessage[]>()
    const [channelMembers, setChannelMembers] = useState<Record<string, boolean>>({})
    const [chatUserIds, setChatUserIds] = useState<number[]>([])
    const [replyMessage, setReplyMessage] = useState<IHistoryMessage>()
    const [retryMessages, setRetryMessages] = useState<TRetryMessageProps>()
    const [statusMessages, setStatusMessages] = useState<IMessengerWsStatusMessageProps[]>()
    const [forwardMessages, setForwardMessages] = useState<IMessengerWsForwardMessageProps[]>()
    const [forwardMessageId, setForwardMessageId] = useState<string>()
    const [searchText, setSearchText] = useState('')
    const [isModeAddMember, setIsModeAddMember] = useState(false)
    const [isUpdatingMembers, setIsUpdatingMembers] = useState(false)
    const [isHideLoaderHistoryChannel, setIsHideLoaderHistoryChannel] = useState(false)
    const [isSingleForward, setIsSingleForward] = useState(false)
    const [isOpenModalMembers, setIsOpenModalMembers] = useState(false)

    const {
        data: dataChannel,
        error: errorChannel,
        refetch: refetchChannel,
    } = useFetchChannel({
        id: channelId,
    })

    const {
        isInitialLoading: isLoadingHistoryChannel,
        data: dataHistoryChannel,
        fetchNextPage: fetchNextPageHistoryChannel,
    } = useFetchInfiniteHistoryChannel({
        channelId,
        withUsersInfo: true,
        page: 1,
        paginatedBy: LIMIT_HISTORY,
    }, {
        enabled: !!dataChannel,
        staleTime: 0,
        cacheTime: 0,
        refetchOnReconnect: false,
    })

    const {
        data: dataChannelMembers,
        refetch: refetchChannelMembers,
    } = useFetchChannelMembers({
        channelId,
        withUsersInfo: true,
    }, {
        enabled: !!dataChannel,
    })

    const {
        updateLastMessageConversationList,
        removeLastMessageConversationList,
        getUnreadMessageProps,
        getRetryMessageProps,
        getDeleteMessageProps,
        getChatUserId,
    } = useMessenger()

    const { data: dataChatUsersData } = useMessengerChatUsersData(chatUserIds)

    const { add: addChannelMembers, remove: removeChannelMembers } = useMutationChannelMembers()

    const { setQueryData: setQueryDataConversationList } = useSetQueryDataConversationList()

    const users = useMemo(() => {
        if (dataChannelMembers) {
            return dataChannelMembers.reduce<IMessengerUser[]>((acc, item) => {
                return item.user ? [...acc, item.user] : acc
            }, [])
        }

        return []
    }, [dataChannelMembers])

    const handlerLoadHistoryChannel = () => {
        if (!isLoadingHistoryChannel) {
            fetchNextPageHistoryChannel()
        }
    }

    const handlerLoadConversationList = (value: TConversationListResponse) => {
        setChatUserIds(value.data.reduce<number[]>((acc, item) => {
            return 'channelId' in item ? acc : [...acc, getChatUserId(user.id, item)]
        }, []))
    }

    const handlerMessage = (value: TWsMessage) => {
        filterChannelMessage(value)
        filterForwardMessage(value)
    }

    const handlerReadMessages = (value: string[]) => {
        setStatusMessages([getUnreadMessageProps({ messageIds: value })])
    }

    const handlerReplyMessage = (value: IHistoryMessage) => {
        setReplyMessage(value)
    }

    const handlerRetryMessages = (value: IMessengerMessage[]) => {
        setRetryMessages(value
            .filter((item) => !!item.channelId)
            .map(({ randomId, channelId: channelID, text }) => {
                return getRetryMessageProps({ randomId, toChannelId: channelID!, text: text ?? '' })
            }))
    }

    const handlerErrorMessage = () => {
        showAlertNotify({ type: 'error', message: t('update_error') })
    }

    const handlerChangeMember = ({ id }: { id: number }, value: boolean) => {
        setChannelMembers((prevState) => {
            if (!value) {
                const { [id]: memberId, ...state } = prevState
                return state
            }

            return { ...prevState, [id]: true }
        })
    }

    const handlerOpenModalMembers = () => {
        setIsOpenModalMembers((prevState) => !prevState)
    }

    const handlerAddMembers = () => {
        setIsModeAddMember(true)
    }

    const handlerSearchFriend = (value: string) => {
        setSearchText(value)
    }

    const handlerForwardMessage = (value: string, isSingle: boolean) => {
        setForwardMessageId(value)
        setIsSingleForward(isSingle)
    }

    const handlerDeleteMessage = (messageId: string) => {
        setStatusMessages([getDeleteMessageProps({ messageId })])
    }

    const handlerCloseReplyMessage = () => {
        setReplyMessage(undefined)
    }

    const handlerAddMember = () => {
        const members = Object
            .entries(channelMembers)
            .map(([key]) => {
                return { channelId, userId: Number(key), isAdmin: false }
            })

        const request = members.map((item) => addChannelMembersAction(item))

        setIsUpdatingMembers(true)
        Promise.all(request)
            .then(() => {
                setChannelMembers({})
                setIsModeAddMember(false)
                refetchChannel()
                refetchChannelMembers()
            })
            .catch((err) => {
                showAlertNotify({ type: 'error', message: err?.length ? err[0] : t('update_error') })
            })
            .finally(() => {
                setIsUpdatingMembers(false)
            })
    }

    const handlerSendForward = (value: IMessengerWsForwardMessageProps[]) => {
        setForwardMessages(value)
    }

    const handlerLeaveChat = () => {
        removeChannelMembersAction()
    }

    const handlerEventKeyup = ({ key }: KeyboardEvent) => {
        if (key === 'Escape') {
            setIsModeAddMember(false)
        }
    }

    function filterChannelMessage(value: TWsMessage) {
        const { messageType, payload } = value
        let isCurrentChatMessage = false

        switch (messageType) {
            case MessengerMessageType.message: {
                if (payload.channelId === channelId) { // on new message
                    isCurrentChatMessage = true
                }
                break
            }
            case MessengerMessageType.status: {
                const { status } = payload
                const message = 'data' in payload ? payload.data : payload

                /**
                 * Status delivered for channel sender user
                 */
                if (status === MessengerMessageStatus.delivered && message.channelId === channelId) {
                    const { inResponseToMessageId, isDeleted } = message

                    isCurrentChatMessage = true

                    if (inResponseToMessageId) {
                        /** reset reply message state */
                        setReplyMessage((prevState) => {
                            return prevState?.id === inResponseToMessageId ? undefined : prevState
                        })
                    }
                    if (isDeleted) {
                        /** remove chat last message in conversation list */
                        setQueryDataConversationList(conversationListParams, (queryData) => {
                            return queryData && 'pages' in queryData
                                ? {
                                    ...queryData,
                                    pages: queryData.pages.map((page) => ({
                                        ...page,
                                        data: page.data.map((item) => {
                                            return removeLastMessageConversationList(item, message.id)
                                        }),
                                    })),
                                }
                                : queryData
                        })
                    } else {
                        /** update chat last message in conversation list */
                        setQueryDataConversationList(conversationListParams, (queryData) => {
                            return queryData && 'pages' in queryData
                                ? {
                                    ...queryData,
                                    pages: queryData.pages.map((page) => ({
                                        ...page,
                                        data: page.data.map((item) => updateLastMessageConversationList(
                                            item,
                                            channelId, {
                                                ...message,
                                                viewsCount: 0,
                                            },
                                            users.find((channelUser) => channelUser.id === message.senderUserId),
                                        )),
                                    })),
                                }
                                : queryData
                        })
                    }
                }
                /**
                 * Status read for channel sender user
                 */
                if (status === MessengerMessageStatus.read && message.channelId === channelId) {
                    isCurrentChatMessage = true

                    /** update chat read status in conversation list */
                    setQueryDataConversationList(conversationListParams, (queryData) => {
                        return queryData && 'pages' in queryData
                            ? {
                                ...queryData,
                                pages: queryData.pages.map((page) => ({
                                    ...page,
                                    data: page.data.map((item) => updateLastMessageConversationList(
                                        item,
                                        channelId,
                                        message,
                                    )),
                                })),
                            }
                            : queryData
                    })
                }
                /**
                 * Status deleted for channel receiver user
                 */
                if (status === MessengerMessageStatus.deleted && message.channelId === channelId) {
                    isCurrentChatMessage = true

                    /** remove channel last message in conversation list */
                    setQueryDataConversationList(conversationListParams, (queryData) => {
                        return queryData && 'pages' in queryData
                            ? {
                                ...queryData,
                                pages: queryData.pages.map((page) => ({
                                    ...page,
                                    data: page.data.map((item) => removeLastMessageConversationList(item, message.id)),
                                })),
                            }
                            : queryData
                    })
                }
                break
            }
            case MessengerMessageType.error: {
                if ('requestData' in payload && 'toChannelId' in payload.requestData && payload.requestData.toChannelId === channelId) {
                    isCurrentChatMessage = true
                }
                break
            }
            default:
            //
        }

        if (isCurrentChatMessage) {
            setWebsocketMessage(value)
        }
    }

    function filterForwardMessage(value: TWsMessage) {
        const { messageType, payload } = value

        let isForwardMessage = false
        let isRedirect = false
        let urlGoTo = ''

        switch (messageType) {
            case MessengerMessageType.status: {
                const { status } = payload
                const message = 'data' in payload ? payload.data : payload

                /**
                 * Status delivered for channel sender user
                 */
                if (status === MessengerMessageStatus.delivered) {
                    const { senderUserId } = message

                    if (senderUserId === user.id && message.forwardedMessageId) {
                        /** compare sent forward message id */
                        setForwardMessageId((prevState) => {
                            if (prevState === message.forwardedMessageId) {
                                isForwardMessage = true
                            }
                            return prevState
                        })
                        /** check is set single forward message receiver */
                        setIsSingleForward((prevState) => {
                            if (prevState) {
                                isRedirect = true
                            }
                            return prevState
                        })
                    }
                }
                break
            }
            default:
            //
        }

        if ('status' in payload && isForwardMessage && isRedirect) {
            const message = 'data' in payload ? payload.data : payload

            if ('receiverUserId' in message && message.receiverUserId) {
                urlGoTo = generatePath(APP_URL.messengerChat, { id: message.receiverUserId })
            }
            if ('channelId' in message && message.channelId) {
                urlGoTo = generatePath(APP_URL.messengerChannel, { id: message.channelId })
            }
            if (urlGoTo) {
                history.push(urlGoTo)
            }
        }
    }

    function addChannelMembersAction(params: TAddChannelMemberProps) {
        return addChannelMembers.mutateAsync(params)
    }

    function removeChannelMembersAction() {
        removeChannelMembers.mutate({ channelId, userId: user.id }, {
            onSuccess: () => {
                history.push(APP_URL.messenger)
            },
            onError: (err) => {
                showAlertNotify({ type: 'error', message: err?.length ? err[0] : t('update_error') })
            },
        })
    }

    useEffect(() => {
        document.addEventListener('keydown', handlerEventKeyup)

        return () => {
            document.removeEventListener('keydown', handlerEventKeyup)
        }
    }, [])

    useEffect(() => {
        return () => {
            setHistoryMessages(undefined)
            setWebsocketMessage(undefined)
            setReplyMessage(undefined)
            setRetryMessages(undefined)
            setForwardMessages(undefined)
            setForwardMessageId(undefined)
            setIsHideLoaderHistoryChannel(false)
        }
    }, [channelId])

    useEffect(() => {
        if (dataHistoryChannel) {
            const { pages } = dataHistoryChannel
            const pagesLen = pages.length

            setHistoryMessages(pages.reduce<IHistoryMessage[]>((acc, item) => [...acc, ...item.data], []))

            if (pagesLen && pages[pagesLen - 1].currentPage >= pages[pagesLen - 1].totalPages) {
                setIsHideLoaderHistoryChannel(true)
            }
        }
    }, [dataHistoryChannel])

    useEffect(() => {
        if (errorChannel) {
            const [msg, error] = errorChannel
            const { response } = error || {}

            showAlertNotify({ type: 'error', message: response?.data.errorMsg || msg })
        }
    }, [errorChannel])

    return (
        <>
            <ContentContainer size="half">
                <MessengerContainer
                    isSingleForward={isSingleForward}
                    forwardMessageId={forwardMessageId}
                    onSendForward={handlerSendForward}
                >
                    <MessengerHeader>
                        <MessengerHeaderChannel
                            user={user}
                            channel={dataChannel}
                            members={dataChannelMembers}
                            onLeaveChat={handlerLeaveChat}
                            onClickMembers={handlerOpenModalMembers}
                            onAddMembers={handlerAddMembers}
                        />
                    </MessengerHeader>
                    <MessengerBody ref={chatRef}>
                        <MessengerRoom
                            isLoading={isLoadingHistoryChannel}
                            isStopLoad={isHideLoaderHistoryChannel}
                            isAdmin={dataChannel?.userId === user.id}
                            parentRef={chatRef}
                            user={user}
                            users={users}
                            historyMessages={dataHistoryChannel && historyMessages} // fix reset prev historyMessages
                            websocketMessage={dataHistoryChannel && websocketMessage} // fix reset prev historyMessages
                            key={channelId}
                            onLoad={handlerLoadHistoryChannel}
                            onRead={handlerReadMessages}
                            onRetry={handlerRetryMessages}
                            onReply={handlerReplyMessage}
                            onForward={handlerForwardMessage}
                            onDelete={handlerDeleteMessage}
                        />
                    </MessengerBody>
                    <MessengerFooter>
                        <MessageAction
                            isEnabled={!!dataChannel}
                            isFocus={!!replyMessage}
                            toChannelId={channelId}
                            replyMessage={replyMessage}
                            retryMessages={retryMessages}
                            statusMessages={statusMessages}
                            forwardMessages={forwardMessages}
                            key={channelId}
                            onMessage={handlerMessage}
                            onError={handlerErrorMessage}
                        >
                            {replyMessage && (
                                <MessengerMessageReply
                                    data={replyMessage}
                                    onClose={handlerCloseReplyMessage}
                                />
                            )}
                        </MessageAction>
                    </MessengerFooter>
                </MessengerContainer>
            </ContentContainer>

            {windowWidth >= BREAKPOINTS.desktop && (
                <SideBarContainer classes={style.sidebar} position="left">
                    <Block classes={cn(style.blockSideBar, { [style.blockSideBar_updating]: isUpdatingMembers })}>
                        <MessengerSearch
                            isCompact
                            classes={style.searchSideBar}
                            onSearch={handlerSearchFriend}
                        >
                            <MessengerMenu />
                        </MessengerSearch>
                        {!!searchText && (
                            <MessengerSearchList
                                classesTitle={style.searchListTitle}
                                classesItem={style.searchListItem}
                                user={user}
                                searchText={searchText}
                            />
                        )}
                        {isModeAddMember && !searchText && (
                            <>
                                <MessengerFriends
                                    render={(data) => (
                                        <MessengerItemCheckBox
                                            name={String(data.id)}
                                            checked={channelMembers[data.id]}
                                            key={data.id}
                                            onChange={(name, value) => handlerChangeMember(data, value)}
                                        >
                                            <MessengerItemUser
                                                data={data}
                                            />
                                        </MessengerItemCheckBox>
                                    )}
                                />
                                <div className={style.actionsSideBar}>
                                    <Button
                                        fullWidth
                                        classes={style.actionAddMember}
                                        size="size40"
                                        disabled={!Object.keys(channelMembers).length}
                                        onClick={handlerAddMember}
                                    >
                                        {t('add')}
                                    </Button>
                                </div>
                            </>
                        )}
                        {!isModeAddMember && !searchText && (
                            <MessengerConversationList
                                user={user}
                                limit={conversationListParams.paginatedBy}
                                onLoad={handlerLoadConversationList}
                                render={(data) => (
                                    'channelId' in data ? (
                                        <MessengerItemChannel
                                            isActive={data.channelId === channelId}
                                            data={data}
                                            user={user}
                                        />
                                    ) : (
                                        <MessengerItemChat
                                            data={data}
                                            user={user}
                                            chatUserData={dataChatUsersData[data.starterUserId === user.id
                                                ? data.followerUserId
                                                : data.starterUserId]}
                                        />
                                    )
                                )}
                            />
                        )}
                    </Block>
                </SideBarContainer>
            )}

            <Modal
                isOpen={isOpenModalMembers}
                size="small"
                onClose={handlerOpenModalMembers}
            >
                <Modal.Header
                    isCloseButton
                    titlePos="left"
                    title={t('channel_chat_members')}
                />
                <Modal.Body classes={style.modalBody}>
                    {dataChannelMembers ? (
                        <>
                            {dataChannelMembers.map((item) => (
                                item.user && (
                                    <MessengerItemUser
                                        isProfileUrl
                                        isTimeInContent
                                        data={item.user}
                                        key={item.id}
                                    />
                                )
                            ))}
                        </>
                    ) : (
                        <>
                            {t('nothing_found')}
                        </>
                    )}
                </Modal.Body>
            </Modal>
        </>
    )
}

export default MessengerChannel
