import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { IBreadcrumb } from 'interfaces'
import { AppSettings } from 'enums'
import { APP_URL } from 'config/app'
import { ContentContainer, Block } from 'layout'
import * as appSelectors from 'containers/App/app-selectors'
import { useAppSettings } from 'containers/App/hooks'
import * as userSelectors from 'containers/User/user-selectors'
import {
    PageTitle,
    Breadcrumbs,
    Switch,
    NoDataInfo,
} from 'components'
import style from './SettingsLab.module.css'

const SettingsLab: React.FC = () => {
    const { t } = useTranslation()
    const { update: updateAppSettings } = useAppSettings()

    const BREADCRUMBS: IBreadcrumb[] = useMemo(() => [
        { id: 1, name: t('settings_tab'), url: APP_URL.settings },
        { id: 2, name: t('lab_settings_title') },
    ], [])

    const { reportsNew: isShowNewReports } = useSelector(appSelectors.settings)
    const isMlmUser = useSelector(userSelectors.mlm)

    const handlerChangeNewReports = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
        const { checked } = target
        updateAppSettings(AppSettings.reportsNew, checked)
    }

    return (
        <ContentContainer size="half">
            <PageTitle>
                <Breadcrumbs items={BREADCRUMBS} />
            </PageTitle>

            {isMlmUser ? (
                <Block classes={style.block}>
                    <div className={style.item}>
                        <span className={style.text}>
                            {t('lab_use_new_reports')}
                        </span>
                        <Switch
                            name="reports-new"
                            checked={!!isShowNewReports}
                            onChange={handlerChangeNewReports}
                        />
                    </div>
                </Block>
            ) : (
                <NoDataInfo text={t('nothing_found')} />
            )}
        </ContentContainer>
    )
}

export default SettingsLab
