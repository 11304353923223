import React, { useEffect, useMemo, useState } from 'react'
import cn from 'classnames'

import { ICalendarHour, ICalendarPost, IDateValues } from 'interfaces'
import { BREAKPOINTS } from 'config/app'
import { Spinner } from 'components'
import { useWindowResize } from 'hooks'
import { defaultDateFormat } from 'utils/helpers'
import { CalendarDayCell, CalendarDayTimeLine, CalendarDayEvent } from '../index'
import { useCalendar } from '../../hooks'
import style from './CalendarDay.module.css'

type CalendarDayPropType = {
    date: Date
    posts?: ICalendarPost[]
    colors?: string[]
    onClickEvent: (value: Date, events: ICalendarPost[]) => void
    onAddEvent: (value: Date) => void
}

const GRID_ITEM_HEIGHT = 62
const GRID_ITEM_HEIGHT_MOBILE = 52
const GRID_ITEMS_LENGTH = 23
const TIME_START_HOUR = 1

const getHours = ({ year, month, day }: Omit<IDateValues, 'hour' | 'minutes'>): ICalendarHour[] => {
    return Array(GRID_ITEMS_LENGTH)
        .fill('')
        .map((item, index) => {
            const dateDate = new Date(year, month, day, index + TIME_START_HOUR, 0, 0)
            return {
                id: defaultDateFormat(dateDate, true),
                date: dateDate,
            }
        })
}

const getEventPosition = (index: number, itemsCount: number) => {
    const widthOverlap = index + 1 < itemsCount ? 8 : 0
    return {
        left: `${(100 / itemsCount) * index}%`,
        width: `calc(${(100 / itemsCount)}% + ${widthOverlap}px)`,
        zIndex: index + 1,
    }
}

const CalendarDay: React.FC<CalendarDayPropType> = ({
    date = new Date(),
    posts,
    colors = [],
    onClickEvent,
    onAddEvent,
}) => {
    const [windowWidth] = useWindowResize()

    const [hours, setHours] = useState<ICalendarHour[]>([])
    const [hoursPosts, setHoursPosts] = useState<Record<string, ICalendarPost[]>>({})

    const { getHoursPosts } = useCalendar()

    const gridItemHeight = useMemo(() => {
        return windowWidth < BREAKPOINTS.tabletLandscape ? GRID_ITEM_HEIGHT_MOBILE : GRID_ITEM_HEIGHT
    }, [windowWidth])

    useEffect(() => {
        setHours(getHours({ year: date.getFullYear(), month: date.getMonth(), day: date.getDate() }))
        setHoursPosts({})
    }, [date])

    useEffect(() => {
        if (posts) {
            setHoursPosts(getHoursPosts(hours, posts))
        }
    }, [hours, posts])

    const handlerClickEvent = (value: Date, events: ICalendarPost[]) => {
        onClickEvent(value, events)
    }

    return (
        <div className={style.wrap}>
            {!posts && <Spinner position="top" size="medium" />}
            <div className={cn(style.list, { [style.list_loading]: !posts })}>
                {hours.map((item) => (
                    <CalendarDayCell
                        data={item}
                        gridItemHeight={gridItemHeight}
                        key={item.id}
                        onClickAddEvent={onAddEvent}
                    >
                        {hoursPosts[item.id]?.map((post, index) => (
                            <CalendarDayEvent
                                styles={getEventPosition(index, hoursPosts[item.id].length)}
                                date={date}
                                data={post}
                                colors={colors}
                                gridItemHeight={gridItemHeight}
                                key={post.id}
                                onClickEvent={() => handlerClickEvent(item.date, hoursPosts[item.id])}
                            />
                        ))}
                    </CalendarDayCell>
                ))}
            </div>
            <CalendarDayTimeLine
                date={date}
                gridItemHeight={gridItemHeight}
                timeStartHour={TIME_START_HOUR}
            />
        </div>
    )
}

export default CalendarDay
