import React from 'react'
import { Route, Switch } from 'react-router-dom'

import { APP_URL } from 'config/app'
import { Container, PageContainer } from 'layout'
import {
    NavBar,
    Logo,
    MenuNavMainPublic,
    Footer,
} from 'components'
import { Auth, Registration } from 'containers'
import style from './AuthPage.module.css'

/**
 * Страница регистрации/авторизации
 *
 * @name AuthPage
 * @memberOf module:Layouts
 */
const AuthPage: React.FC = () => {
    return (
        <>
            <header>
                <NavBar>
                    <Logo />
                    <MenuNavMainPublic />
                </NavBar>
            </header>

            <Container>
                <PageContainer classes={style.pageContainer}>
                    <Switch>
                        <Route path={APP_URL.auth} component={Auth} />
                        <Route path={APP_URL.registration} component={Registration} />
                    </Switch>
                </PageContainer>
            </Container>

            <Footer />
        </>
    )
}

export default AuthPage
