import React, { useState, useMemo } from 'react'
import { useLocation, matchPath } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import cn from 'classnames'

import { IMenuNavItemData } from 'interfaces'
import { MenuNavChild, AppScheme, FeedTabsId } from 'enums'
import { APP_URL, COUNTERS, REPORT_PV_MATERIALS_ID } from 'config/app'
import * as userSelectors from 'containers/User/user-selectors'
import { useFetchShopsStatic } from 'containers/Market/hooks'
import { useFetchBudget } from 'containers/User/hooks'
import { numberFormat } from 'utils/helpers'
import { MenuNavItem, MenuNavLink, MenuNavDropDown } from './components'
import style from './MenuNav.module.css'

type MenuNavPropType = {
    isMobile?: boolean
}

type TMenuItems = Record<string, IMenuNavItemData>

enum MenuKey {
    feed = 1,
    friends = 2,
    market = 3,
    privilege = 4,
    reports = 5,
    wallet = 6,
    materials = 7,
    calendar = 8,
    rank = 10,
}

enum FeedChildrenKey {
    all = 'all',
    posts = 'posts',
    company = 'company',
    leaders = 'leaders',
    president = 'president'
}

const MENU_ITEMS: TMenuItems = {
    [MenuKey.feed]: {
        id: MenuKey.feed,
        translateKey: 'feed',
        icon: 'ic_tab2_feed_svg',
        iconClassName: style.icon,
        url: APP_URL.lenta,
        countEvent: {
            key: COUNTERS.feedPosts,
            value: 0,
        },
        mobile: true,
    },
    [MenuKey.friends]: {
        id: MenuKey.friends,
        translateKey: 'friends',
        icon: 'ic_tab_friends_svg',
        iconClassName: style.icon,
        url: APP_URL.friends,
        countEvent: {
            key: COUNTERS.friendRequests,
            value: 0,
        },
    },
    [MenuKey.market]: {
        id: MenuKey.market,
        translateKey: 'my_stores',
        icon: 'ic_shop_cart_svg',
        iconClassName: style.icon,
        url: APP_URL.market,
        mobile: true,
    },
    [MenuKey.calendar]: {
        id: MenuKey.calendar,
        translateKey: 'calendar',
        icon: 'ic_kicks_calendar2_svg',
        iconClassName: style.icon,
        url: APP_URL.calendar,
        countValue: {
            key: COUNTERS.calendar,
            value: 0,
        },
    },
}

const MENU_ITEMS_MLM: TMenuItems = {
    [MenuKey.feed]: {
        id: MenuKey.feed,
        translateKey: 'feed',
        icon: 'ic_tab2_feed_svg',
        iconClassName: style.icon,
        url: APP_URL.lenta,
        countEvent: {
            key: COUNTERS.feedPosts,
            value: 0,
        },
        children: {
            [FeedChildrenKey.all]: {
                id: FeedTabsId.allPosts,
                url: APP_URL.lenta,
                translateKey: 'feed_all_articles',
            },
            [FeedChildrenKey.posts]: {
                id: 100,
                url: APP_URL.lentaUser,
                translateKey: 'user_profile_posts_and_checks',
            },
            [FeedChildrenKey.company]: {
                id: FeedTabsId.companyPosts,
                url: `${APP_URL.lenta}?tabId=${FeedTabsId.companyPosts}`,
                translateKey: 'company_post',
            },
            [FeedChildrenKey.leaders]: {
                id: FeedTabsId.leadersPosts,
                url: `/lenta?tabId=${FeedTabsId.leadersPosts}`,
                translateKey: 'leaders_posts',
            },
            [FeedChildrenKey.president]: {
                id: FeedTabsId.presidentPosts,
                url: `${APP_URL.lenta}?tabId=${FeedTabsId.presidentPosts}`,
                translateKey: 'president_posts',
            },
        },
        childType: MenuNavChild.byQueryParams,
        mobile: true,
    },
    [MenuKey.friends]: {
        id: MenuKey.friends,
        translateKey: 'friends',
        icon: 'ic_tab_friends_svg',
        iconClassName: style.icon,
        url: APP_URL.friends,
        countEvent: {
            key: COUNTERS.friendRequests,
            value: 0,
        },
    },
    [MenuKey.market]: {
        id: MenuKey.market,
        translateKey: 'stores',
        icon: 'ic_shop_cart_svg',
        iconClassName: style.icon,
        url: APP_URL.market,
        mobile: true,
    },
    [MenuKey.privilege]: {
        id: MenuKey.privilege,
        translateKey: 'mlm_privilege',
        icon: 'ic_tab_privilege_svg',
        iconClassName: style.icon,
        url: APP_URL.privilege,
        mobile: true,
    },
    [MenuKey.reports]: {
        id: MenuKey.reports,
        translateKey: 'reports',
        icon: 'ic_report_svg',
        iconClassName: style.icon,
        url: APP_URL.reports,
    },
    [MenuKey.wallet]: {
        id: MenuKey.wallet,
        translateKey: 'icon_menu_wallet',
        icon: 'ic_tab2_wallet_svg',
        iconClassName: style.icon,
        url: APP_URL.wallet,
        countEvent: {
            key: COUNTERS.wallet,
            value: 0,
        },
    },
    [MenuKey.materials]: {
        id: MenuKey.materials,
        translateKey: 'materials',
        icon: 'ic_matherials_svg',
        iconClassName: style.icon,
        url: APP_URL.report.replace(':id', REPORT_PV_MATERIALS_ID),
    },
    [MenuKey.calendar]: {
        id: MenuKey.calendar,
        translateKey: 'calendar',
        icon: 'ic_kicks_calendar2_svg',
        iconClassName: style.icon,
        url: APP_URL.calendar,
        countValue: {
            key: COUNTERS.calendar,
            value: 0,
        },
    },
    [MenuKey.rank]: {
        id: MenuKey.rank,
        translateKey: 'business_menu_kicks_icon_title',
        icon: 'sessia_balance2_svg',
        // iconClassName: ,
        // url: '',
        countValue: {
            key: String(MenuKey.rank),
            value: 0,
        },
        disabled: true,
    },
}

const getCurrentItemId = (items: IMenuNavItemData[], pathname: string): number => {
    const currentItem = items.find(({ url }) => {
        return url ? !!matchPath(pathname, { path: url, exact: false }) : undefined
    })
    return currentItem ? currentItem.id : 0
}

const MenuNav: React.FC<MenuNavPropType> = ({ isMobile = false }) => {
    const { t } = useTranslation()
    const { pathname } = useLocation()

    const isMlmUser = useSelector(userSelectors.mlm)
    const counts = useSelector(userSelectors.counts)

    const [currentItemId, setCurrentItemId] = useState<number>(getCurrentItemId(isMlmUser
        ? Object.values(MENU_ITEMS_MLM)
        : Object.values(MENU_ITEMS),
    pathname))

    const { data: dataBudget } = useFetchBudget()

    const { data: dataShopsStatic } = useFetchShopsStatic({
        schema: AppScheme.pv,
    }, {
        enabled: isMlmUser,
    })

    const itemsValues = useMemo(() => {
        const [mainShop] = dataShopsStatic || []
        const items = isMlmUser ? MENU_ITEMS_MLM : MENU_ITEMS

        return Object.values(items).reduce<IMenuNavItemData[]>((acc, item) => {
            if (isMlmUser) {
                // change market url to main shop url
                if (item.id === MenuKey.market && mainShop) {
                    return [
                        ...acc, {
                            ...item,
                            url: APP_URL.store.replace(':id', String(mainShop.id)),
                            translateKey: t('our_store'),
                        },
                    ]
                }
            }

            return [...acc, item]
        }, [])
    }, [isMlmUser, dataShopsStatic])

    const menuMainItems = useMemo(() => {
        return itemsValues.reduce<IMenuNavItemData[]>((acc, item) => {
            if (isMobile && !item.mobile) {
                return acc
            }
            if (item.countValue?.key && item.countValue.key in counts) {
                return [...acc, { ...item, countValue: { ...item.countValue, value: counts[item.countValue.key] } }]
            }
            if (item.countEvent?.key && item.countEvent.key in counts) {
                return [...acc, { ...item, countEvent: { ...item.countEvent, value: counts[item.countEvent.key] } }]
            }
            if (item.countValue?.key === String(MenuKey.rank) && dataBudget !== undefined) {
                return [...acc, { ...item, countValue: { ...item.countValue, value: numberFormat(dataBudget) } }]
            }

            return [...acc, item]
        }, [])
    }, [isMobile, itemsValues, counts, dataBudget])

    const menuDropDownItems = useMemo(() => {
        return isMobile
            ? itemsValues.filter((item) => !item.mobile || item.id === MenuKey.feed)
            : []
    }, [isMobile, itemsValues])

    const handlerChangeSubMenu = (itemId: number) => {
        setCurrentItemId((prevState) => (prevState === itemId ? 0 : itemId))
    }

    return (
        <ul className={cn(style.menuNav, { [style.menuNav_mobile]: isMobile })}>
            {menuMainItems.map((item) => (
                <MenuNavItem key={item.id}>
                    <MenuNavLink
                        isMobile={isMobile}
                        isOpen={item.id === currentItemId}
                        data={item}
                        onChangeSubMenu={handlerChangeSubMenu}
                    />
                </MenuNavItem>
            ))}

            {isMobile && !!menuDropDownItems.length && (
                <MenuNavItem>
                    <MenuNavDropDown
                        items={menuDropDownItems}
                        currentItemId={currentItemId}
                        isShowQR={isMlmUser}
                        onChangeSubMenu={handlerChangeSubMenu}
                    />
                </MenuNavItem>
            )}
        </ul>
    )
}

export default MenuNav
