import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink, useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import cn from 'classnames'

import { APP_URL } from 'config/app'
import { appLogoutUser, fullLogoutUser } from 'containers/User/user-actions'
import * as userSelectors from 'containers/User/user-selectors'
import { useFetchConversationCountsNew } from 'containers/Messenger/hooks'
import {
    Button,
    MenuList,
    Modal,
    SvgResource,
    Badge,
    Popover,
} from 'components'
import { useThunkDispatch } from 'hooks'
import { vPrimary1 } from 'styles/modules/variables.module.css'
import styleCommon from 'styles/modules/common.module.css'
import style from './MenuNavMain.module.css'

const MenuNavMain: React.FC = () => {
    const { t } = useTranslation()
    const history = useHistory()
    const dispatch = useDispatch()
    const thunkDispatch = useThunkDispatch()

    const {
        support_count: supportCount,
    } = useSelector(userSelectors.counts)

    const [isSubmittingExit, setIsSubmittingExit] = useState(false)
    const [isShowModalExitConfirm, setIsShowModalExitConfirm] = useState(false)

    const {
        data: dataConversationCountsNew,
    } = useFetchConversationCountsNew({}, {
        refetchInterval: (res) => (res ? 30 * 1000 : 60 * 1000), // 30s | 1m
    })

    const handlerClickSettings = () => {
        history.push(APP_URL.settings)
    }

    const handlerClickExit = () => {
        setIsShowModalExitConfirm(true)
    }

    const handlerChangeUser = () => {
        setIsSubmittingExit(true)
        thunkDispatch(fullLogoutUser())
            .then(() => {
                //
            })
            .finally(() => {
                setIsSubmittingExit(false)
            })
    }

    const handlerConfirmExit = () => {
        dispatch(appLogoutUser())
    }

    const handlerCloseModalExitConfirm = () => {
        setIsShowModalExitConfirm(false)
    }

    return (
        <>
            <ul className={style.nav}>
                <li className={style.navItem}>
                    <NavLink to={APP_URL.messenger} title={t('chat')}>
                        <SvgResource
                            isImgTag={false}
                            classes={style.icon}
                            resourceKey="ic_chat_v2_svg"
                            width={32}
                            height={32}
                        />
                        {!!dataConversationCountsNew?.chatsWithNewMsgsCount && (
                            <Badge
                                animChanged
                                classes={style.badge}
                                size="small"
                                color={vPrimary1}
                                backgroundColor="#fff"
                            >
                                {dataConversationCountsNew.chatsWithNewMsgsCount}
                            </Badge>
                        )}
                    </NavLink>
                </li>
                <li className={style.navItem}>
                    <NavLink className={style.link} to={APP_URL.support} title={t('ui_support_create_ticket_title')}>
                        <SvgResource
                            isImgTag={false}
                            classes={style.icon}
                            resourceKey="ic_support_v2_svg"
                            width={32}
                            height={32}
                        />
                        {!!supportCount && (
                            <Badge classes={style.badge} animChanged size="small">
                                {supportCount}
                            </Badge>
                        )}
                    </NavLink>
                </li>
                <li className={style.navItem}>
                    <Popover
                        position="bottom"
                        side="left"
                        trigger={(
                            <SvgResource
                                classes={cn(style.icon, style.iconDropDown)}
                                resourceKey="dropdpwn_daw_svg"
                                width={20}
                                height={32}
                            />
                        )}
                    >
                        <MenuList type="large">
                            <MenuList.Item onClick={handlerClickSettings}>
                                <SvgResource
                                    isImgTag={false}
                                    classes={style.iconMenuItem}
                                    resourceKey="ic_settings_v2_svg"
                                    width={32}
                                    height={32}
                                />
                                {t('settings')}
                            </MenuList.Item>
                            <MenuList.Item onClick={handlerClickExit}>
                                <SvgResource
                                    isImgTag={false}
                                    classes={style.iconMenuItem}
                                    resourceKey="ic_appexit2_svg"
                                    width={32}
                                    height={32}
                                />
                                {t('exit')}
                            </MenuList.Item>
                        </MenuList>
                    </Popover>
                </li>
            </ul>

            <Modal
                isOpen={isShowModalExitConfirm}
                size="smallCenter"
                onClose={handlerCloseModalExitConfirm}
            >
                <Modal.Body classes={cn({ [styleCommon.updating]: isSubmittingExit })}>
                    <div className={style.modalText}>
                        {t('exit_text')}
                    </div>
                    <div className={style.modalActions}>
                        <Button
                            fullWidth
                            textUpper
                            classes={style.modalAction}
                            size="size48"
                            text={t('change_user')}
                            onClick={handlerChangeUser}
                        />
                        <Button
                            fullWidth
                            textUpper
                            classes={style.modalAction}
                            styleType="bordered"
                            size="size48"
                            text={t('my_sessia_security_button')}
                            onClick={handlerConfirmExit}
                        />
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default MenuNavMain
