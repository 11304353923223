import React, { useMemo } from 'react'
import { Field, useFormState } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import cn from 'classnames'

import {
    IProfile,
    IPostProps,
    IPostContent,
    IPostContentAttacheParams,
    ICommunity,
} from 'interfaces'
import { TAddPostProps, TUpdatePostProps } from 'services/CommunityService'
import { POST_TYPE_POST } from 'config/app'
import {
    Avatar,
    Input,
    Textarea,
    Button,
    SvgResource,
    Image,
    Post,
} from 'components'
import { FieldCommunity } from 'forms/fields'
import { getId } from 'utils/helpers'
import styleForm from 'styles/modules/form.module.css'
import style from './PostForm.module.css'
import { AttachControls } from './components'

export enum FieldName {
    community = 'community',
    title = 'title',
    text = 'text',
    dateStart = 'calendarDateFrom',
    dateEnd = 'calendarDateTo',
    files = 'files',
}

export type FormDataType = TAddPostProps | TUpdatePostProps

type PostFormPropType = {
    classesContent?: string
    classesControls?: string
    profile: IProfile
    isActiveCommunity: boolean
    isSubmitting: boolean
    files: IPostProps['files']
    content: IPostContent[]
    communities?: ICommunity[]
    onAddFiles: (files: FileList, params: IPostContentAttacheParams) => void
    onAddGoods: (params: IPostContentAttacheParams) => void
    onAddImage: (params: IPostContentAttacheParams) => void
    onChangeContentDesc: (id: number, value: string) => void
    onDeletePostFile: (id: number) => void
    onDeleteContentFile: (id: number) => void
    onSubmit: (e: React.FormEvent<HTMLFormElement>) => void
}

const ACCEPT_IMAGE = 'image/jpeg,image/png,image/gif'
const ACCEPT_VIDEO = 'video/mp4,video/mpeg,video/quicktime'

export const formDataInitial: FormDataType = {
    type: POST_TYPE_POST,
    community: 0,
    title: '',
    text: '',
    isPublished: true,
    isShowAuthor: true,
    files: [],
}

const PostForm: React.FC<PostFormPropType> = ({
    classesContent,
    classesControls,
    profile,
    isActiveCommunity,
    isSubmitting,
    files,
    content,
    communities,
    onAddFiles,
    onAddGoods,
    onAddImage,
    onChangeContentDesc,
    onDeletePostFile,
    onDeleteContentFile,
    onSubmit,
}) => {
    const { t } = useTranslation()

    const { values, hasValidationErrors } = useFormState<FormDataType>()

    const mainFiles = useMemo(() => {
        return files.filter((item) => ('delete' in item ? !item.delete : true))
    }, [files])

    const handlerAddFiles = (fileList: FileList | null, contentId?: number) => {
        if (fileList) {
            onAddFiles(fileList, { isAttach: !!contentId, contentId })
        }
    }

    const handlerAddGoods = (contentId?: number) => {
        onAddGoods({ isAttach: !!contentId, contentId })
    }

    const handlerAddImage = (contentId?: number) => {
        onAddImage({ isAttach: !!contentId, contentId })
    }

    return (
        <form className={cn({ [styleForm.submitting]: isSubmitting })} onSubmit={onSubmit}>
            <div className={cn(style.body, classesContent)}>
                <div className={cn(styleForm.row, styleForm.row_12, styleForm.row_vAlignCenter)}>
                    <Avatar
                        src={profile?.photo}
                        width={54}
                        height={54}
                        name={profile?.name}
                        surname={profile?.surname}
                    />
                    <div className={style.name}>
                        {profile?.name}
                        {' '}
                        {profile?.surname}
                    </div>
                </div>
                <div className={cn(styleForm.row, styleForm.row_12, styleForm.row_vAlignCenter)}>
                    <div className={style.fieldText}>
                        {t('ui_posts_community_hint')}
                    </div>
                    <FieldCommunity
                        classesField={style.fieldCommunity}
                        name={FieldName.community}
                        communities={communities}
                        activeId={values[FieldName.community]}
                        disabled={!isActiveCommunity}
                    />
                </div>
                <div className={style.images}>
                    <div className={style.imageControls}>
                        <Button
                            classes={style.control}
                            styleType="text"
                            onClick={() => handlerAddImage()}
                        >
                            <SvgResource
                                resourceKey="ic_photo_bank_picker_white_svg"
                                width={30}
                                height={30}
                            />
                        </Button>
                        <label className={cn(style.control, styleForm.field, styleForm.field_attachIcon)}>
                            <Input
                                multiple
                                classes={styleForm.inputFile}
                                type="file"
                                styleType="clear"
                                accept={ACCEPT_IMAGE}
                                onChange={({ currentTarget }) => handlerAddFiles(currentTarget.files)}
                            />
                            <SvgResource
                                resourceKey="ic_image_picker_white_svg"
                                width={30}
                                height={30}
                            />
                        </label>
                        <label className={cn(style.control, styleForm.field, styleForm.field_attachIcon)}>
                            <Input
                                classes={styleForm.inputFile}
                                type="file"
                                styleType="clear"
                                accept={ACCEPT_VIDEO}
                                onChange={({ currentTarget }) => handlerAddFiles(currentTarget.files!)}
                            />
                            <SvgResource
                                resourceKey="ic_video_picker_white_svg"
                                width={30}
                                height={30}
                            />
                        </label>
                        <Button
                            classes={style.control}
                            styleType="text"
                            onClick={() => handlerAddGoods()}
                        >
                            <SvgResource
                                resourceKey="ic_goods_picker_white_svg"
                                width={30}
                                height={30}
                            />
                        </Button>
                    </div>
                    {mainFiles.length ? (
                        <Post.MainImage
                            files={mainFiles}
                            onDeleteFile={onDeletePostFile}
                        />
                    ) : (
                        <Image
                            classesImage={style.imagePlaceholder}
                            src=""
                            width={800}
                            height={600}
                        />
                    )}
                </div>
                <div className={cn(styleForm.row, styleForm.row_20)}>
                    <Field
                        name={FieldName.title}
                        render={({ input, meta }) => (
                            <>
                                <Input
                                    classes={cn({ [styleForm.invalid]: meta.dirty && !!meta.error })}
                                    styleType="dynamic"
                                    dynamicPlaceholder={`${t('ui_posts_post_title_hint')}*`}
                                    name={input.name}
                                    value={input.value}
                                    onChange={input.onChange}
                                    onBlur={input.onBlur}
                                />
                                <div className={styleForm.error}>
                                    {meta.dirty && meta.error}
                                </div>
                            </>
                        )}
                    />
                </div>
                <div className={cn(styleForm.row, styleForm.row_20)}>
                    <Field
                        name={FieldName.text}
                        render={({ input, meta }) => (
                            <>
                                <Textarea
                                    classes={cn(style.textarea, { [styleForm.invalid]: meta.dirty && !!meta.error })}
                                    styleType="dynamic"
                                    dynamicPlaceholder={`${t('ui_posts_post_text_hint')}*`}
                                    name={input.name}
                                    limit={10000}
                                    value={input.value}
                                    onChange={input.onChange}
                                    onBlur={input.onBlur}
                                />
                                <div className={styleForm.error}>
                                    {meta.dirty && meta.error}
                                </div>
                            </>
                        )}
                    />
                </div>
                {content.map((item, index) => (
                    /* skip post main text item */
                    index > 0 && (
                        <div className={cn(styleForm.row, styleForm.row_20)} key={item.id}>
                            <Post.Content
                                content={item}
                                onClickDeleteContentFile={onDeleteContentFile}
                            >
                                <AttachControls
                                    classes={cn(style.controls, style.controls_contentRight, style.controls_mt)}
                                    controlId={item.id}
                                    onAddFiles={handlerAddFiles}
                                    onAddGoods={handlerAddGoods}
                                    onAddImage={handlerAddImage}
                                />
                                <Post.ContentTextArea
                                    data={item}
                                    onChange={onChangeContentDesc}
                                />
                            </Post.Content>
                        </div>
                    )
                ))}
            </div>
            <div className={cn(style.footer, classesControls)}>
                <Button
                    fullWidth
                    size="size40"
                    type="submit"
                    text={'id' in values && values.id ? t('save') : t('ui_posts_send_form_button_text')}
                    disabled={hasValidationErrors}
                />

                <AttachControls
                    classes={style.attachControls}
                    controlId={getId(true)}
                    onAddFiles={handlerAddFiles}
                    onAddGoods={handlerAddGoods}
                    onAddImage={handlerAddImage}
                />
            </div>
        </form>
    )
}

export default PostForm
