import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { IMarket } from 'interfaces'
import { APP_URL } from 'config/app'
import { API_HOST } from 'config/api'
import { Card } from 'components'
import { parseTpl } from 'utils/helpers'
import style from './MarketItem.module.css'

type MarketCategoryPropType = {
    isStatic: boolean
    category: IMarket
}

const MarketItem: React.FC<MarketCategoryPropType> = ({ isStatic, category }) => {
    const { t } = useTranslation()

    const url = useMemo(getUrl, [category])
    const title = useMemo(getTitle, [category])
    const image = useMemo(getImage, [category])

    function getUrl() {
        return parseTpl(APP_URL.category, { ':id': category.id }, { prefix: '', suffix: '' })
    }

    function getTitle() {
        if (isStatic && category.nameKey) {
            return t(category.nameKey)
        }
        if (category.pluralName) {
            return category.pluralName
        }
        return category.name
    }

    function getImage() {
        return category?.image ? `${API_HOST}${category.image}` : ''
    }

    return (
        <Card.Item classes={style.item} url={url}>
            <Card.Image src={image} backgroundColor={category.color} width={400} />
            <Card.Body classes={style.body} title={title} />
        </Card.Item>
    )
}

export default MarketItem
