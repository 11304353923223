import { YANDEX_METRICA_ID } from 'config/app'

type TReachGoalTarget = 'ORDER_MAKE'

type TUserParams = Record<string, string|number|boolean>

type TReachGoalParams = {
    order_price?: number
    currency?: string
}

type THitOptions = {
    title?: string
    referer?: string
    callback?: () => void
    params?: {
        order_price?: number
        currency?: string
    }
}

declare global {
    interface Window {
        ym?: any
        // ym?: (
        //     id: number,
        //     type: 'hit' | 'reachGoal',
        //     url?: string,
        //     params?: TReachGoalParams,
        //     options?: THitOptions,
        // ) => void
    }
}

/**
 * Yandex metrica
 * @see https://yandex.ru/support/metrica/code/counter-spa-setup.html
 */
export default function useMetrics() {
    const hit = (url: string, options?: THitOptions) => {
        if ('ym' in window && typeof window.ym === 'function') {
            window.ym(YANDEX_METRICA_ID, 'hit', url, options)
        }
    }

    const userParams = (params: TUserParams) => {
        if ('ym' in window && typeof window.ym === 'function') {
            window.ym(YANDEX_METRICA_ID, 'userParams', params)
        }
    }

    const reachGoal = (target: TReachGoalTarget, params?: TReachGoalParams) => {
        if ('ym' in window && typeof window.ym === 'function') {
            window.ym(YANDEX_METRICA_ID, 'reachGoal', target, params)
        }
    }

    return {
        hit,
        userParams,
        reachGoal,
    }
}
