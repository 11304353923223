import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import cn from 'classnames'

import { ICity, ICountry } from 'interfaces'
import {
    CountryDataSelect,
    CityDataSelect,
    Input,
    Button,
} from 'components'
import { Form, formErrorsType } from 'utils/Form'
import { maskNormalizer } from 'utils/helpers'

import styleInput from 'components/Input/Input.module.css'
import styleForm from 'styles/modules/form.module.css'
import style from './DeliveryAddressForm.module.css'

type addressSimpleFields = {
    city_name?: string
    full_name?: string
    post_code?: string
    street?: string
    home_number?: string
    room?: string
    phone?: string
    email?: string
}

export type formAddressDataType = {
    country?: number
    city?: number
} & addressSimpleFields

export type initAddressDataType = {
    countries?: ICountry[]
    country?: ICountry
    city?: ICity
} & addressSimpleFields

export type DeliveryAddressFormPropType = {
    data: initAddressDataType
    errors?: formErrorsType
    controlText?: string
    classesContent?: string
    classesControls?: string
    isDisabled?: boolean
    onChange?: (data: formAddressDataType) => void
    onSubmit: (data: formAddressDataType) => void
}

const DeliveryAddressForm: React.FC<DeliveryAddressFormPropType> = ({
    data,
    errors = {},
    controlText,
    classesContent,
    classesControls,
    isDisabled,
    onChange = () => {},
    onSubmit,
}) => {
    const { t } = useTranslation()

    const [countries] = useState<ICountry[] | []>(data?.countries || [])
    const [country, setCountry] = useState<ICountry | undefined>(data?.country)
    const [city, setCity] = useState<ICity | undefined>(data?.city)

    const [formData, setFormData] = useState<formAddressDataType>({
        country: data?.country ? data.country.id : undefined,
        city: data?.city ? data.city.id : undefined,
        city_name: data?.city?.ru_name ? data.city.ru_name : undefined,
        full_name: data?.full_name ? data.full_name : '',
        post_code: data?.post_code ? data.post_code : '',
        street: data?.street ? data.street : '',
        home_number: data?.home_number ? data.home_number : '',
        room: data?.room ? data.room : '',
        phone: data?.phone ? data.phone : '',
        email: data?.email ? data.email : '',
    })
    const [formErrors, setFormErrors] = useState(errors)

    const phoneMask = useMemo(() => (country?.mask ? maskNormalizer(country.mask) : undefined), [country])

    const handlerSubmitForm = (e: React.SyntheticEvent) => {
        e.preventDefault()
        onSubmit(formData)
    }

    const handlerChangeFormData = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { type, name, value } = e.currentTarget
        setFormData(Form.setData({ type, name, value }))
    }

    const handlerAcceptFormData = (el: HTMLInputElement, unmaskedValue: string) => {
        const { type, name } = el
        setFormData(Form.setData({ type, name, value: unmaskedValue }))
    }

    const handlerChangeCountry = (changedCountry: ICountry) => {
        if (country?.id !== changedCountry.id) {
            setCountry(changedCountry)
            setCity(undefined)
            setFormData((prevState) => ({
                ...prevState,
                country: changedCountry.id,
                city: undefined,
                city_name: undefined,
            }))
        }
    }

    const handlerChangeCity = (changedCity: ICity) => {
        if (city?.id !== changedCity.id) {
            setCity(changedCity)
            setFormData((prevState) => ({
                ...prevState,
                city: changedCity.id,
                city_name: changedCity.ru_name,
            }))
        }
    }

    useEffect(() => {
        // Remove country code in phone
        if (country && formData.phone) {
            const phoneWithoutCode = formData.phone.replace(country.code, '')
            setFormData((prevState) => ({ ...prevState, phone: phoneWithoutCode }))
        }
    }, [country])

    useEffect(() => {
        onChange(formData)
    }, [formData])

    useEffect(() => {
        setFormErrors(errors)
    }, [errors])

    return (
        <form onSubmit={handlerSubmitForm}>
            <div className={classesContent}>
                <div className={cn(styleForm.row, styleForm.row_24)}>
                    <CountryDataSelect
                        classesField={cn(styleInput.gray, style.fieldSelect)}
                        countries={countries}
                        selected={country}
                        selectedIsIcon={false}
                        isShowSelectArrow
                        onChange={handlerChangeCountry}
                    />
                </div>

                <div className={cn(styleForm.row, styleForm.row_24)}>
                    <CityDataSelect
                        classesField={cn(
                            styleInput.gray,
                            style.fieldSelect,
                            formErrors?.city ? styleForm.invalid : '',
                        )}
                        selected={city}
                        countryId={country?.id}
                        countryLang={country?.lang}
                        onChange={handlerChangeCity}
                    />
                    <div className={styleForm.error}>
                        {formErrors?.city}
                    </div>
                </div>

                <div className={cn(styleForm.row, styleForm.row_24)}>
                    <label className={styleForm.label}>
                        <div className={cn(styleForm.fieldHead)}>
                            {t('market_fullname')}
                        </div>
                        <Input
                            classes={formErrors?.full_name ? styleForm.invalid : ''}
                            styleType="gray"
                            name="full_name"
                            placeholder={t('market_fullname')}
                            value={formData.full_name}
                            onChange={handlerChangeFormData}
                        />
                        <div className={styleForm.error}>
                            {formErrors?.full_name}
                        </div>
                    </label>
                </div>

                <div className={cn(styleForm.row, styleForm.row_24)}>
                    <label className={styleForm.label}>
                        <div className={cn(styleForm.fieldHead)}>
                            {t('market_postal_code')}
                        </div>
                        <Input
                            classes={formErrors?.post_code ? styleForm.invalid : ''}
                            styleType="gray"
                            name="post_code"
                            placeholder={t('market_postal_code')}
                            value={formData.post_code}
                            onChange={handlerChangeFormData}
                        />
                        <div className={styleForm.error}>
                            {formErrors?.post_code}
                        </div>
                    </label>
                </div>

                <div className={cn(styleForm.row, styleForm.row_24)}>
                    <label className={styleForm.label}>
                        <div className={cn(styleForm.fieldHead)}>
                            {t('market_bh_street')}
                        </div>
                        <Input
                            classes={formErrors?.street ? styleForm.invalid : ''}
                            styleType="gray"
                            name="street"
                            placeholder={t('market_bh_street')}
                            value={formData.street}
                            onChange={handlerChangeFormData}
                        />
                        <div className={styleForm.error}>
                            {formErrors?.street}
                        </div>
                    </label>
                </div>

                <div className={cn(styleForm.row, styleForm.row_24)}>
                    <label className={styleForm.label}>
                        <div className={cn(styleForm.fieldHead)}>
                            {t('market_bh_house')}
                        </div>
                        <Input
                            classes={formErrors?.home_number ? styleForm.invalid : ''}
                            styleType="gray"
                            name="home_number"
                            placeholder={t('market_bh_house')}
                            value={formData.home_number}
                            onChange={handlerChangeFormData}
                        />
                        <div className={styleForm.error}>
                            {formErrors?.home_number}
                        </div>
                    </label>
                </div>

                <div className={cn(styleForm.row, styleForm.row_24)}>
                    <label className={styleForm.label}>
                        <div className={cn(styleForm.fieldHead)}>
                            {t('market_bh_appartment')}
                        </div>
                        <Input
                            classes={formErrors?.room ? styleForm.invalid : ''}
                            styleType="gray"
                            name="room"
                            value={formData.room}
                            placeholder={t('market_bh_appartment')}
                            onChange={handlerChangeFormData}
                        />
                        <div className={styleForm.error}>
                            {formErrors?.room}
                        </div>
                    </label>
                </div>

                <div className={cn(styleForm.row, styleForm.row_24)}>
                    <label className={styleForm.label}>
                        <div className={cn(styleForm.fieldHead)}>
                            {t('phone')}
                        </div>
                        <div className={cn(styleForm.group, styleInput.gray, formErrors?.phone ? styleForm.invalid : '')}>
                            <div className={cn(styleForm.groupItem, styleForm.groupItem_minWidth, style.phoneCode)}>
                                {country?.code}
                            </div>
                            <Input
                                classes={cn(styleForm.groupItem, styleForm.groupItem_maxWidth, style.phoneInput)}
                                styleType="gray"
                                name="phone"
                                inputMode="tel"
                                placeholder={t('phone')}
                                mask={phoneMask}
                                value={formData.phone}
                                onAccept={handlerAcceptFormData}
                            />
                        </div>
                        <div className={styleForm.error}>
                            {formErrors?.phone}
                        </div>
                    </label>
                </div>

                <div className={cn(styleForm.row, styleForm.row_24)}>
                    <label className={styleForm.label}>
                        <div className={cn(styleForm.fieldHead)}>
                            {t('email')}
                        </div>
                        <Input
                            classes={formErrors?.email ? styleForm.invalid : ''}
                            styleType="gray"
                            name="email"
                            inputMode="email"
                            placeholder={t('email')}
                            value={formData.email}
                            onChange={handlerChangeFormData}
                        />
                        <div className={styleForm.error}>
                            {formErrors?.email}
                        </div>
                    </label>
                </div>
            </div>

            <div className={cn(styleForm.row, styleForm.row_24, classesControls)}>
                <Button
                    fullWidth
                    classes={style.modalControl}
                    type="submit"
                    textUpper
                    size="size40"
                    text={controlText}
                    disabled={isDisabled}
                />
            </div>
        </form>
    )
}

export default DeliveryAddressForm
