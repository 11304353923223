import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import cn from 'classnames'

import { IStoreProfile } from 'interfaces'
import { Image, SvgResource, InfoBlock } from 'components'
import style from './StoreContacts.module.css'

type StoreContactsPropType = {
    data?: IStoreProfile
}

const StoreContacts: React.FC<StoreContactsPropType> = ({ data = {} }) => {
    const { t } = useTranslation()

    const [isShow, setIsShow] = useState(false)

    useEffect(() => {
        const { pickup, delivery_price, site } = data

        if (pickup?.comment || site || (delivery_price && Number(delivery_price) > 0)) {
            setIsShow(true)
        }
    }, [data])

    return (
        <>
            {isShow && (
                <InfoBlock classes={style.wrap}>
                    <InfoBlock.Body classes={style.body}>
                        {!!data.pickup?.comment && (
                            <div className={style.contactsItem}>
                                <SvgResource
                                    classes={cn(style.contactsIcon, style.iconMap)}
                                    resourceKey="ic_map_marker_svg"
                                    width={15}
                                    height={24}
                                />
                                <span className={style.contactsText}>
                                    {data.pickup?.comment}
                                </span>
                            </div>
                        )}
                        {!!data.site && (
                            <div className={style.contactsItem}>
                                <SvgResource
                                    classes={cn(style.contactsIcon, style.iconWeb)}
                                    resourceKey="ic_web_svg"
                                    width={20}
                                    height={20}
                                />
                                <a
                                    className={style.contactsLink}
                                    href={data.site}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    {data.site}
                                </a>
                            </div>
                        )}
                        {!!data?.delivery_price && Number(data.delivery_price) > 0 && (
                            <div className={style.contactsItem}>
                                <span className={style.contactsIcon} />
                                <span className={style.contactsPrice}>
                                    {t('shop_card_delivery_from')}
                                    {' '}
                                    {data.delivery_price}
                                    {' '}
                                    {data.currency}
                                </span>
                            </div>
                        )}
                        {data.pickup?.image && (
                            <div className={style.contactsItem}>
                                <Image
                                    src={data.pickup?.image}
                                    width={400}
                                    height={400}
                                    resize={{ width: 400, height: 400 }}
                                />
                            </div>
                        )}
                    </InfoBlock.Body>
                </InfoBlock>
            )}
        </>
    )
}

export default StoreContacts
