import { useSelector } from 'react-redux'
import { useMemo } from 'react'

import { APP_URL } from 'config/app'
import * as userSelectors from 'containers/User/user-selectors'

export default function useApp() {
    const isMlmUser = useSelector(userSelectors.mlm)

    const mainPrivateRoute = useMemo(() => {
        return isMlmUser ? APP_URL.privilege : APP_URL.lenta
    }, [isMlmUser])

    return {
        mainPrivateRoute,
    }
}
