import React, {
    ImgHTMLAttributes,
    useRef,
    useEffect,
    useMemo,
    useState,
} from 'react'
import cn from 'classnames'

import { IMAGE_PLACEHOLDER_RESOURCE } from 'config/app'
import { IMAGE_RESOURCE_PATH } from 'config/api'
import { useIntersectionObserver, useOnLoadImage } from 'hooks'
import { getImageResizeSrc } from 'utils/helpers'

import styleCommon from 'styles/modules/common.module.css'
import style from './Image.module.css'

export type ImagePropType = {
    isImg?: boolean
    isPlaceholder?: boolean
    classesWrap?: string
    classesImage?: string
    styles?: object
    src?: string
    width?: number
    height?: number
    backgroundColor?: string
    resize?: {
        width?: number
        height?: number
        quality?: number
        format?: 'auto' | 'webp' | 'jpeg'
        fit?: 'scale-down' | 'contain' | 'cover' | 'pad'
    }
    rootMargin?: string
    onLoad?: (image: HTMLImageElement) => void
    onError?: () => void
} & Omit<ImgHTMLAttributes<HTMLImageElement>, 'className'>

const Image: React.FC<ImagePropType> = ({
    children,
    isImg = true,
    isPlaceholder = true,
    classesWrap,
    classesImage,
    styles = {},
    src,
    backgroundColor,
    width,
    height,
    resize,
    rootMargin = '0px 0px 50% 0px',
    onLoad = () => {},
    onError = () => {},
    ...props
}) => {
    const ref = useRef(null)
    const [isIntersecting] = useIntersectionObserver(ref, { freezeOnceVisible: true, rootMargin })

    const [imgSrc, setImgSrc] = useState('')

    const [loadedImg, errorImg] = useOnLoadImage(imgSrc)

    const imgHeight = useMemo(() => {
        if (width && height) {
            return Math.round((Number(height) / Number(width)) * 100)
        }

        return 75
    }, [width, height])

    function setImagePlaceholder() {
        const plsSrc = `${IMAGE_RESOURCE_PATH}/${IMAGE_PLACEHOLDER_RESOURCE}`

        if (isPlaceholder) {
            setImgSrc(resize ? getImageResizeSrc({ src: plsSrc, ...resize, fit: 'cover' }) : plsSrc)
        }
    }

    useEffect(() => {
        if (isIntersecting) {
            if (!src) {
                setImagePlaceholder()
            } else if (resize) {
                setImgSrc(getImageResizeSrc({ src, ...resize }))
            } else {
                setImgSrc(src)
            }
        }
    }, [isIntersecting])

    useEffect(() => {
        if (loadedImg) {
            onLoad(loadedImg)
        }
    }, [loadedImg])

    useEffect(() => {
        if (errorImg) {
            if (src && resize) { // resizer return error
                setImgSrc(src) // set original image
            } else {
                setImagePlaceholder()
                onError()
            }
        }
    }, [errorImg])

    return (
        <div
            className={cn(style.wrap, classesWrap)}
            style={{ backgroundColor, ...styles }}
            ref={ref}
        >
            {children}

            {isImg ? (
                <img
                    {...props}
                    className={cn(
                        style.img,
                        loadedImg ? '' : styleCommon.image_loading,
                        classesImage,
                    )}
                    src={loadedImg ? loadedImg.src : ''}
                    width={loadedImg ? loadedImg.width : width}
                    height={loadedImg ? loadedImg.height : height}
                    alt=""
                />
            ) : (
                <div
                    className={cn(
                        style.image,
                        styleCommon.image,
                        loadedImg ? '' : styleCommon.image_loading,
                        classesImage,
                    )}
                    style={{
                        paddingTop: `${imgHeight}%`,
                        backgroundImage: loadedImg ? `url(${loadedImg.src})` : '',
                    }}
                />
            )}
        </div>
    )
}

export default Image
