import React, {
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react'
import { useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import {
    IUser,
    ICountry,
    ICity,
    IUserRegData,
    IUserUpdateProps,
    IAgreementCompany,
    IAgreement,
    ICompanyAgreementData,
} from 'interfaces'
import { FormDataType } from 'forms/RegistrationUserForm/RegistrationUserForm'
import { TValidationFieldRule } from 'utils/validators'
import {
    Modal,
    Button,
    Cropper,
    CompanyAgreements,
} from 'components'
import { useForm, useThunkDispatch } from 'hooks'
import {
    AppService,
    AuthService,
    UserService,
    AgreementService,
} from 'services'
import {
    isObject,
    getRequestError,
    showAlertNotify,
    defaultDateFormat,
} from 'utils/helpers'
import { updateUser } from 'containers/User/user-actions'
import * as appSelectors from 'containers/App/app-selectors'
import * as userSelectors from 'containers/User/user-selectors'
import { RegistrationUserForm } from 'forms'
import errorLog from 'utils/errorLog'

export type RegistrationUserActionPropType = {
    guarantorCode: string
}

const initFormData: IUserRegData = {
    name: '',
    surname: '',
    guarantor_code: '',
    email: '',
    city: undefined,
    birthDate: '',
    photo: '',
    gender: undefined,
}

const RegistrationUserAction: React.FC<RegistrationUserActionPropType> = ({ guarantorCode }) => {
    const { t } = useTranslation()
    const isMounted = useRef(false)
    const thunkDispatch = useThunkDispatch()
    const history = useHistory()
    const location = useLocation()

    const FORM_VALIDATION_RULES: TValidationFieldRule[] = [{
        field: 'name',
        rules: [{ rule: 'required', error: t('error_field_is_empty') }],
    }, {
        field: 'surname',
        rules: [{ rule: 'required', error: t('error_field_is_empty') }],
    }]

    const regDataUser = UserService.getRegData()

    const user = useSelector(userSelectors.user)
    const countries = useSelector(appSelectors.countries)

    const [selectedCountry, setSelectedCountry] = useState<ICountry>(user?.country)
    // if is set city in regDataUser we cant get API city by id for selectedCity
    const [selectedCity, setSelectedCity] = useState<ICity>()
    const [imageAvatar, setImageAvatar] = useState<string>() // imgBase64
    const [imageAvatarCropped, setImageAvatarCropped] = useState<string>() // base64
    const [companiesAgreementList, setCompaniesAgreementList] = useState<null | IAgreementCompany[]>(null)
    const [companyAgreement, setCompanyAgreement] = useState<null | {
        company: IAgreementCompany
        agreement: IAgreement
    }>(null)
    const [confirmedCompanyAgreement, setConfirmedCompanyAgreement] = useState<null | ICompanyAgreementData>()

    const [isSubmitting, setIsSubmitting] = useState(false)
    const [isShowModalAvatar, setIsShowModalAvatar] = useState(false)
    const [isShowModalAgreements, setIsShowModalAgreements] = useState(false)
    const [isDisabledConfirmAgreement, setIsDisabledConfirmAgreement] = useState(true)

    const {
        formData,
        formErrors,
        setFormValue,
        isValidForm,
    } = useForm<FormDataType>(regDataUser ? { ...initFormData, ...regDataUser } : initFormData, FORM_VALIDATION_RULES)

    const maxBirthDayRestrictions = useMemo(() => {
        const date = AppService.getDateMinimumAgeRegistration()
        return defaultDateFormat(date)
    }, [])

    const isDisabled = useMemo(() => !isValidForm(), [formData])

    const handlerSubmit = () => {
        if (isValidForm()) {
            submitAction()
        }
    }

    const handlerChangePhoto = (file: File) => {
        if (file) {
            uploadImageAction(file)
        }
    }

    const handlerChangeCountry = (data: ICountry) => {
        setSelectedCountry(data)
    }

    const handlerChangeCity = (data: ICity) => {
        setFormValue('city', data.id)
        setSelectedCity(data)
    }

    const handlerChangeFormData = (name: string, value: string) => {
        setFormValue(name, name === 'gender' ? Number(value) : value)
    }

    const handlerCropAvatar = (data: string) => {
        if (data) {
            setImageAvatarCropped(data)
        }
    }

    const handlerSetAvatar = () => {
        setFormValue('photo', imageAvatarCropped)
        setIsShowModalAvatar(false)
    }

    const handlerChangeCompanyAgreements = (data: ICompanyAgreementData) => {
        if (data.success) {
            setConfirmedCompanyAgreement(data)
            setIsDisabledConfirmAgreement(false)
        } else {
            setConfirmedCompanyAgreement(null)
            setIsDisabledConfirmAgreement(true)
        }
    }

    const handlerConfirmAgreement = () => {
        setIsShowModalAgreements(false)

        if (!confirmedCompanyAgreement) {
            return
        }

        const { agreementId } = confirmedCompanyAgreement

        AgreementService.setAgreementSigned(agreementId).then(({ data }) => {
            if (data?.success) {
                setCompaniesAgreementList(((prevState) => {
                    return !prevState ? null : prevState.map((company) => {
                        if (company.company_id === confirmedCompanyAgreement.companyId) {
                            const agreements = !company.agreements ? [] : company.agreements.map((agreement) => {
                                return agreement.id === agreementId ? { ...agreement, signed: true } : agreement
                            })
                            return { ...company, ...{ agreements } }
                        }
                        return company
                    })
                }))
            }
        }).catch((err) => {
            errorLog('setAgreementSigned', err)
        })
    }

    function resetGuarantorCode() {
        setFormValue('guarantor_code', '')
        UserService.saveRegData({ ...formData, guarantor_code: '' })
    }

    function handlerError(err: any) {
        const errorText = getRequestError(err)

        if (errorText) {
            showErrorAlertNotify(errorText)
        }
    }

    function showErrorAlertNotify(message: string = t('update_error')) {
        showAlertNotify({ type: 'error', message })
    }

    function submitAction() {
        const { guarantor_code, ...userData } = formData

        if (guarantor_code) {
            setGuarantorAction(guarantor_code)
        } else {
            updateUserAction(userData)
        }
    }

    function setGuarantorAction(code: string) {
        setIsSubmitting(true)
        UserService.setGuarantor({ guarantor_code: code })
            .then(({ data }) => {
                if (data?.status === 'success') {
                    getCompanyAgreementAction()
                }
            })
            .catch((err) => {
                handlerError(err)
                resetGuarantorCode()
            })
            .finally(() => {
                setIsSubmitting(false)
            })
    }

    function getCompanyAgreementAction() {
        const { guarantor_code, ...userData } = formData

        setIsSubmitting(true)
        AgreementService.getAgreement()
            .then(({ data }) => {
                if (data?.length) {
                    setCompaniesAgreementList(data)
                } else {
                    updateUserAction(userData)
                }
            })
            .catch((err) => {
                handlerError(err)
                resetGuarantorCode()
            })
            .finally(() => {
                setIsSubmitting(false)
            })
    }

    /**
     * Save user profile
     */
    function updateUserAction(userProps: IUserUpdateProps) {
        setIsSubmitting(true)
        thunkDispatch(updateUser(userProps))
            .then((updatedUser) => {
                UserService.clearRegData()
                authorizeAction(updatedUser)
            })
            .catch((err) => {
                let errorText = getRequestError(err)

                if (!errorText && isObject(err.response.data)) {
                    const { name, surname, email } = err.response.data
                    let formErrorText = ''

                    if (Array.isArray(name) && typeof name[0] === 'string') {
                        const [msg] = name
                        formErrorText = msg
                    } else if (typeof name === 'string') {
                        formErrorText = name
                    } else if (Array.isArray(surname) && typeof surname[0] === 'string') {
                        const [msg] = surname
                        formErrorText = msg
                    } else if (typeof surname === 'string') {
                        formErrorText = surname
                    } else if (Array.isArray(email) && typeof email[0] === 'string') {
                        const [msg] = email
                        formErrorText = msg
                    } else if (typeof email === 'string') {
                        formErrorText = email
                    }

                    errorText = formErrorText
                }

                showErrorAlertNotify(errorText || t('update_error'))
            })
            .finally(() => {
                setIsSubmitting(false)
            })
    }

    function authorizeAction(updatedUser: IUser) {
        setIsSubmitting(true)
        AuthService.authorize(updatedUser, location)
            .then((route) => {
                if (route) {
                    history.push(route)
                }
            })
            .catch(handlerError)
            .finally(() => {
                if (isMounted.current) {
                    setIsSubmitting(false)
                }
            })
    }

    function uploadImageAction(file: File) {
        AppService.uploadImageToBase64(file)
            .then((res) => {
                setImageAvatar(res)
                setIsShowModalAvatar(true)
            })
            .catch((err) => {
                showErrorAlertNotify(err.message)
            })
    }

    useEffect(() => {
        isMounted.current = true

        return () => {
            isMounted.current = false
        }
    }, [])

    useEffect(() => {
        if (guarantorCode) {
            setFormValue('guarantor_code', guarantorCode)
        }
    }, [guarantorCode])

    useEffect(() => {
        UserService.saveRegData(formData)
    }, [formData])

    useEffect(() => {
        if (companiesAgreementList?.length) {
            const company = companiesAgreementList.find((item) => item.agreements.length)
            const agreement = company?.agreements.find((item) => !item.signed)

            if (company && agreement) {
                setCompanyAgreement({ company, agreement })
                setIsShowModalAgreements(true)
            } else {
                const { guarantor_code, ...userData } = formData
                updateUserAction(userData)
            }
        }
    }, [companiesAgreementList])

    return (
        <>
            <RegistrationUserForm
                data={formData}
                errors={formErrors}
                country={selectedCountry}
                city={selectedCity}
                countries={countries}
                maxBirthDayRestrictions={maxBirthDayRestrictions}
                isSubmitting={isSubmitting}
                isDisabled={isDisabled}
                onChangePhoto={handlerChangePhoto}
                onChangeCountry={handlerChangeCountry}
                onChangeCity={handlerChangeCity}
                onChange={handlerChangeFormData}
                onSubmit={handlerSubmit}
            />

            <Modal
                isOpen={isShowModalAvatar}
                size="medium"
                onClose={() => setIsShowModalAvatar(false)}
            >
                <Modal.Header title={t('edit_photo')} />
                <Modal.Body>
                    <Cropper source={imageAvatar} onCrop={handlerCropAvatar} />
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        text={t('install')}
                        size="size40"
                        onClick={handlerSetAvatar}
                    />
                </Modal.Footer>
            </Modal>

            <Modal
                isOpen={isShowModalAgreements && !!companyAgreement}
                classes="modal modal_small"
                onClose={() => setIsShowModalAgreements(false)}
            >
                <Modal.Body>
                    {!!companyAgreement && (
                        <CompanyAgreements
                            company={companyAgreement.company}
                            agreement={companyAgreement.agreement}
                            onChange={handlerChangeCompanyAgreements}
                        />
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        text={t('ok')}
                        size="size40"
                        disabled={isDisabledConfirmAgreement}
                        onClick={handlerConfirmAgreement}
                    />
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default RegistrationUserAction
