import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { APP_URL } from 'config/app'
import { ContentContainer, CardContainer } from 'layout'
import * as appSelectors from 'containers/App/app-selectors'
import { ReportItem } from 'containers/ReportList/components'
import {
    PageHeader,
    Loader,
    ErrorMsg,
} from 'components'
import { scrollTop } from 'utils/helpers'
import { useFetchReports } from './hooks'
// import style from './Reports.module.css'

const ReportsV1: React.FC = () => {
    const { t } = useTranslation()
    const history = useHistory()

    const { reportsNew: isNewReports } = useSelector(appSelectors.settings)

    const {
        isLoading: isLoadingReports,
        data: dataReports,
        error: errorReports,
    } = useFetchReports({
        displayFull: false,
        count: false,
    })

    const handlerClickBack = () => {
        history.push(APP_URL.reports)
    }

    useEffect(() => {
        const scrollTimerId = setTimeout(scrollTop)

        return () => {
            clearTimeout(scrollTimerId)
        }
    }, [])

    return (
        <ContentContainer size="half">
            <PageHeader
                title={t('reports')}
                onClickBack={isNewReports ? handlerClickBack : undefined}
            />

            {isLoadingReports && (
                <Loader />
            )}

            {!isLoadingReports && dataReports && (
                <CardContainer>
                    {dataReports.map((item) => (
                        <ReportItem data={item} key={item.id} />
                    ))}
                </CardContainer>
            )}

            {errorReports && (
                <ErrorMsg error={errorReports} />
            )}
        </ContentContainer>
    )
}

export default ReportsV1
