import React from 'react'
import { useTranslation } from 'react-i18next'
import cn from 'classnames'

import {
    ICountry,
    ICity,
    IDeliveryAddress,
    IDeliveryCost,
    IStoreCurrency,
    IBasket,
    IOrder,
} from 'interfaces'
import { validationFormErrorsType } from 'utils/validators'
import { Block } from 'layout'
import {
    CountryDataSelect,
    CityDataSelect,
    Loader,
    Radio,
    Button,
    Textarea,
} from 'components'
import styleForm from 'styles/modules/form.module.css'
import styleInput from 'components/Input/Input.module.css'
import style from 'containers/StoreOrderMake/StoreOrderMake.module.css'

import {
    StoreOrderDelivery,
    StoreOrderPickup,
    StoreOrderTariffs,
    StoreOrderMakePayDates,
    StoreOrderMakeBuyer,
    StoreOrderMakePayments,
} from 'containers/StoreOrderMake/components'

export type OrderProductType = {
    goods: number
    quantity: number
    payment_by?: string
}

export type ReceiveType = 'delivery' | 'pickup'

export type SubmitDataType = {
    service?: number
}

export type formDataType = {
    products: OrderProductType[]
    delivery_address?: number
    delivery_method?: number
    pay_date?: string
    buyer_account_id?: number
    payment_by_wallet?: boolean
    payment_by_payout?: boolean
    comment?: string
}

export type OrderMakeFormPropType = {
    data: formDataType
    errors: validationFormErrorsType
    isLoadingDelivery: boolean
    isDelivery: boolean
    isDisabled: boolean
    countries: ICountry[]
    receiveType: ReceiveType
    addresses: IDeliveryAddress[]
    delivery: IDeliveryCost[]
    pickup: IDeliveryCost[]
    payDates: IBasket['pay_dates']
    sumPaymentByBonus: number
    sumPaymentByPayout: number
    submitText: string
    submitService?: SubmitDataType['service']
    country?: ICountry
    city?: ICity
    currency?: IStoreCurrency
    order?: IOrder
    onChangeCountry: (country: ICountry) => void
    onChangeCity: (city: ICity) => void
    onChangeReceiveType: (type: ReceiveType) => void
    onSelectDeliveryAddress: (id: number) => void
    onAddDeliveryAddress: () => void
    onEditDeliveryAddress: (address: IDeliveryAddress) => void
    onDeleteDeliveryAddress: (address: IDeliveryAddress) => void
    onSelectPickup: (id?: number) => void
    onSelectDeliveryTariff: (id: number) => void
    onChangePayDate: (date: string) => void
    onSelectBuyer: (id: number | undefined) => void
    onChange: (el: HTMLInputElement | HTMLTextAreaElement) => void
    onSubmit: (data: SubmitDataType) => void
}

const OrderMakeForm: React.FC<OrderMakeFormPropType> = ({
    data,
    errors,
    isLoadingDelivery,
    isDelivery,
    isDisabled,
    countries,
    country,
    city,
    receiveType,
    addresses,
    delivery,
    pickup,
    currency,
    payDates,
    order,
    sumPaymentByBonus,
    sumPaymentByPayout,
    submitText,
    submitService,
    onChangeCountry,
    onChangeCity,
    onChangeReceiveType,
    onSelectDeliveryAddress,
    onAddDeliveryAddress,
    onEditDeliveryAddress,
    onDeleteDeliveryAddress,
    onSelectPickup,
    onSelectDeliveryTariff,
    onChangePayDate,
    onSelectBuyer,
    onChange,
    onSubmit,
}) => {
    const { t } = useTranslation()

    const handlerSubmit = (e: React.SyntheticEvent<HTMLFormElement>) => {
        e.preventDefault()
        onSubmit({ service: submitService })
    }

    const handlerChangeFormData = ({ currentTarget }: React.SyntheticEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        onChange(currentTarget)
    }

    const handlerChangeReceiveType = ({ currentTarget }: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = currentTarget
        onChangeReceiveType(value as ReceiveType)
    }

    return (
        <form onSubmit={handlerSubmit}>
            <Block classes={style.block}>
                {isDelivery && (
                    <>
                        <div className={cn(styleForm.row, styleForm.row_40)}>
                            <div className={style.subTitle}>
                                {t('market_region_title')}
                            </div>

                            <div className={cn(style.field)}>
                                <CountryDataSelect
                                    classesField={cn(
                                        styleInput.gray,
                                        style.fieldSelect,
                                        errors?.country ? styleForm.invalid : '',
                                    )}
                                    countries={countries}
                                    selected={country}
                                    selectedIsIcon={false}
                                    isShowSelectArrow
                                    onChange={onChangeCountry}
                                />
                            </div>
                            <div className={style.field}>
                                <CityDataSelect
                                    classesField={cn(
                                        styleInput.gray,
                                        style.fieldSelect,
                                        errors?.city ? styleForm.invalid : '',
                                    )}
                                    selected={city}
                                    countryId={country?.id}
                                    countryLang={country?.lang}
                                    onChange={onChangeCity}
                                />
                            </div>
                        </div>

                        {!!city && (
                            <div className={cn(styleForm.row, styleForm.row_40)}>
                                <div className={style.subTitle}>
                                    {t('market_delivery_method_title')}
                                </div>

                                <div className={style.receiveControls}>
                                    <Radio
                                        classes={style.receiveControl}
                                        name="receiveType"
                                        text={t('market_delivery_to_address')}
                                        value="delivery"
                                        checked={receiveType === 'delivery'}
                                        onChange={handlerChangeReceiveType}
                                    />
                                    <Radio
                                        classes={style.receiveControl}
                                        name="receiveType"
                                        text={t('selfdelivery')}
                                        value="pickup"
                                        checked={receiveType === 'pickup'}
                                        disabled={!pickup?.length}
                                        onChange={handlerChangeReceiveType}
                                    />
                                </div>

                                {receiveType === 'delivery' && (
                                    <>
                                        {isLoadingDelivery ? (
                                            <Loader />
                                        ) : (
                                            <StoreOrderDelivery
                                                items={addresses}
                                                activeId={data?.delivery_address}
                                                error={errors?.delivery_address}
                                                onSelect={onSelectDeliveryAddress}
                                                onAdd={onAddDeliveryAddress}
                                                onEdit={onEditDeliveryAddress}
                                                onDelete={onDeleteDeliveryAddress}
                                            />
                                        )}
                                    </>
                                )}

                                {receiveType === 'pickup' && (
                                    <StoreOrderPickup
                                        items={pickup}
                                        currency={currency}
                                        activeId={data?.delivery_method}
                                        error={errors?.delivery_method}
                                        onSelect={onSelectPickup}
                                    />
                                )}
                            </div>
                        )}

                        {receiveType === 'delivery' && !!delivery.length && (
                            <div className={cn(styleForm.row, styleForm.row_40)}>
                                <div className={style.subTitle}>
                                    {t('market_delivery_tariff')}
                                </div>

                                <StoreOrderTariffs
                                    items={delivery}
                                    activeId={data?.delivery_method}
                                    currency={currency}
                                    error={errors?.delivery_method}
                                    onSelect={onSelectDeliveryTariff}
                                />
                            </div>
                        )}
                    </>
                )}

                <div className={cn(styleForm.row, styleForm.row_40)}>
                    <div className={style.subTitle}>
                        {t('market_additional_settings')}
                    </div>

                    <StoreOrderMakePayDates
                        payDates={payDates}
                        error={errors?.pay_date}
                        onChange={onChangePayDate}
                    />

                    <StoreOrderMakeBuyer
                        error={errors?.buyer_account_id}
                        onSelect={onSelectBuyer}
                    />

                    <StoreOrderMakePayments
                        totalBonus={sumPaymentByBonus}
                        totalPayout={sumPaymentByPayout}
                        sumBonus={order?.sum_by_wallets}
                        sumPayout={order?.sum_by_payout}
                        isCheckedBonus={data?.payment_by_wallet}
                        isCheckedPayout={data?.payment_by_payout}
                        currency={currency}
                        onChange={handlerChangeFormData}
                    />

                    <div className={cn(styleForm.row, styleForm.row_24)}>
                        <div className={cn(styleForm.fieldHead, style.fieldLabel)}>
                            {t('market_comment')}
                        </div>

                        <div>
                            <Textarea
                                styleType="gray"
                                name="comment"
                                onBlur={handlerChangeFormData}
                            />
                        </div>
                    </div>

                    <div className={cn(styleForm.row, style.row)}>
                        <Button
                            fullWidth
                            type="submit"
                            size="size44"
                            text={submitText}
                            disabled={isDisabled}
                        />
                    </div>
                </div>
            </Block>
        </form>
    )
}

export default OrderMakeForm
