import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import { QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
// import * as Sentry from '@sentry/react'
// import { BrowserTracing } from '@sentry/tracing'
import ReactModal from 'react-modal'

// import { APP_VERSION } from 'config/app'
// import { IS_PRODUCTION } from 'config/api'
import { App } from './containers'
import store from './store'
import queryClient from './queryClient'
import * as serviceWorker from './serviceWorker'
import './i18n'
import './styles/index.css'

// Sentry.init({
//     dsn: 'https://9bb4d92d9b534a98aef8e0c267c979d4@sentry.sessia.com/12',
//     integrations: [new BrowserTracing()],
//     tracesSampleRate: 1.0,
//     environment: 'production',
//     release: APP_VERSION,
//     enabled: IS_PRODUCTION,
// })

ReactModal.setAppElement('#root')

ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <QueryClientProvider client={queryClient}>
                <ReactQueryDevtools initialIsOpen={false} />
                <BrowserRouter>
                    <App />
                </BrowserRouter>
            </QueryClientProvider>
        </Provider>
    </React.StrictMode>,
    document.getElementById('root'),
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
