import React, {
    InputHTMLAttributes,
    useRef,
    useEffect,
    useState,
} from 'react'
import { IMask, IMaskInput } from 'react-imask'
import cn from 'classnames'

import { getId } from 'utils/helpers'
import style from './Input.module.css'

export type InputPropType = {
    classes?: string
    styleType?: 'default' | 'dynamic' | 'clear' | 'transparent' | 'gray'
    dynamicPlaceholder?: string
    focus?: boolean
    mask?: IMask.AnyMask
    dispatch?: IMask.MaskedDynamicOptions['dispatch']
    onFocus?: () => void
    onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void
    onKeyUp?: (e: React.KeyboardEvent<HTMLInputElement>) => void
    onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void // only default input
    onAccept?: (el: HTMLInputElement, unmaskedValue: string) => void // only masked input
} & Omit<InputHTMLAttributes<HTMLInputElement>, 'className'>

type PropsType = InputHTMLAttributes<HTMLInputElement> & {
    className?: string
}

type MaskPropsType = Exclude<PropsType, 'onChange'> & Pick<InputPropType, 'mask' | 'dispatch'> & {
    onAccept: (value: unknown, maskRef: IMask.InputMask<IMask.AnyMaskedOptions>, e?: InputEvent) => void
}

type MaskRefType = IMask.InputMask<IMask.MaskedPatternOptions> & { el: { input: HTMLInputElement } }

/**
 * Input component
 *
 * Masked input
 * @see https://imask.js.org/
 *
 * @param {string} [type]
 * @param {string} [inputMode]
 * @param {string} [placeholder]
 * @param {string} [dynamicPlaceholder]
 * @param {string} [styleType]
 * @param {string} [classes] - custom classes
 * @param {string} [mask] - placeholder chars
 * @param {boolean} [showMask] - display the mask as a placeholder
 * @param {boolean} [focus] - focus field on load component
 * @param {number} [maxLength]
 * @param {callback} [onAccept] - masked input onchange callback
 * @param defaults
 */
const Input: React.FC<InputPropType> = ({
    classes = '',
    type = 'text',
    styleType = type === 'text' ? 'default' : '',
    inputMode = 'text',
    placeholder = '',
    dynamicPlaceholder = '',
    mask,
    focus = false,
    maxLength,
    onAccept = () => {},
    ...defaults
}) => {
    const props: PropsType | MaskPropsType = {
        className: cn(style[styleType], classes),
        type,
        inputMode,
        placeholder: dynamicPlaceholder ? ' ' : placeholder,
        ...defaults,
    }

    const ref = useRef<HTMLInputElement>(null)
    const [id] = useState(getId(false))

    const handlerOnAccept = (value: unknown, maskRef: IMask.InputMask<IMask.AnyMaskedOptions>) => {
        const { el, unmaskedValue } = maskRef as MaskRefType
        onAccept(el.input, unmaskedValue)
    }

    useEffect(() => {
        if (focus && ref.current && 'focus' in ref.current) {
            ref.current.focus()
        }
    }, [focus, ref.current])

    return (
        <>
            {mask ? (
                <IMaskInput
                    {...props as MaskPropsType}
                    id={id}
                    mask={mask}
                    lazy={false} // make placeholder always visible
                    inputRef={ref}
                    onAccept={handlerOnAccept}
                />
            ) : (
                <input
                    {...props}
                    id={id}
                    maxLength={maxLength}
                    ref={ref}
                />
            )}

            {styleType === 'dynamic' && dynamicPlaceholder && (
                <label className={style.placeholder} htmlFor={id}>
                    {dynamicPlaceholder}
                </label>
            )}
        </>
    )
}

export default Input
