import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { IMlmPrivilegeData } from 'interfaces'
import { UserService } from 'services'
import * as userSelectors from 'containers/User/user-selectors'
import { getRequestError } from 'utils/helpers'

export type TError = string
export type TQueryKey = string[]
export type TQueryFnOpts = UseQueryOptions<IMlmPrivilegeData, TError, IMlmPrivilegeData, TQueryKey>

/**
 * Хук API сводная статистика привилегий
 */
export default function useFetchPrivilege({
    cacheTime = 600 * 1000, // 10m
    staleTime = 600 * 1000, // 10m
    ...opts
}: TQueryFnOpts = {}) {
    const { t } = useTranslation()
    const user = useSelector(userSelectors.user)

    return useQuery([`mlmPrivilege-${user.id}`], () => {
        return UserService.fetchMlmPrivilege()
            .then(({ data }) => {
                return data
            })
            .catch((err) => {
                return Promise.reject(getRequestError(err) || t('update_error'))
            })
    }, {
        cacheTime,
        staleTime,
        ...opts,
    })
}
