import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { APP_URL } from 'config/app'
import { Block } from 'layout'
import * as userSelectors from 'containers/User/user-selectors'
import { RegistrationUserAction } from 'form-actions'
import { getURLSearchParams } from 'utils/helpers'
import style from './Registration.module.css'

const Registration = () => {
    const history = useHistory()

    const user = useSelector(userSelectors.user)

    const [guarantorCode, setGuarantorCode] = useState('')

    useEffect(() => {
        const { id, invite } = getURLSearchParams(history.location.search)

        if (!user) {
            history.push(APP_URL.auth)
        }
        if (id || invite) {
            setGuarantorCode(id || invite)
        }
    }, [])

    return (
        <Block classes={style.registration}>
            <RegistrationUserAction
                guarantorCode={guarantorCode}
            />
        </Block>
    )
}

export default Registration
