import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import cn from 'classnames'

import { IBasketItem, IStoreCurrency, IGoodsImage } from 'interfaces'
import { APP_URL } from 'config/app'
import { Button, Counter, SvgResource } from 'components'
import { useOnLoadImage } from 'hooks'
import {
    getImageResizeSrc,
    parseTpl,
    numberFormat,
    priceFormat,
} from 'utils/helpers'

import styleCommon from 'styles/modules/common.module.css'
import style from './StoreBasketItem.module.css'

type StoreBasketItemPropType = {
    data: IBasketItem
    currency?: IStoreCurrency
    payoutCurrency?: string
    onChange: (id: number, value: number, customCurrency?: string) => void
    onRemove: (id: number, customCurrency?: string) => void
}

const getImage = (images: IGoodsImage[]) => {
    const imageMain = images.find((img) => img.format === 1)
    return imageMain ? getImageResizeSrc({
        src: imageMain.path,
        width: 180,
        height: 136,
        quality: 90,
        fit: 'cover',
    }) : ''
}

const getPrice = (data: IBasketItem, currency?: IStoreCurrency) => {
    if (data?.custom_price) {
        return data.custom_price
    }
    if (data.goods?.cost) {
        return priceFormat(data.goods.cost, currency)
    }

    return ''
}

const getUrl = (id: number) => {
    return id ? parseTpl(APP_URL.goods, {
        ':id': id,
    }, {
        prefix: '',
        suffix: '',
    }) : ''
}

const StoreBasketItem: React.FC<StoreBasketItemPropType> = ({
    data,
    currency,
    payoutCurrency,
    onChange,
    onRemove,
}) => {
    const { t } = useTranslation()

    const image = useMemo(() => getImage(data.goods.images), [data])
    const price = useMemo(() => getPrice(data, currency), [data, currency])
    const url = useMemo(() => getUrl(data.goods.id), [data])

    const [loadedImg, error] = useOnLoadImage(image)

    const handlerChangeCount = (value: number) => {
        onChange(data.goods.id, value, data?.custom_currency)
    }

    const handlerClickRemove = () => {
        onRemove(data.goods.id, data?.custom_currency)
    }

    return (
        <div className={style.item}>
            <Link
                className={cn(style.image, loadedImg || error ? '' : styleCommon.image_loading)}
                style={{ backgroundImage: loadedImg ? `url(${loadedImg.src})` : '' }}
                to={url}
            />
            <div className={style.body}>
                <Link className={style.title} to={url}>
                    {payoutCurrency && payoutCurrency === data?.custom_currency && (
                        <SvgResource
                            isImgTag={false}
                            classes={style.iconPresent}
                            resourceKey="ic_shop_present_svg"
                        />
                    )}
                    {data.goods?.name}
                </Link>
                {data?.goods.subtitle && (
                    <div className={style.subtitle}>
                        {data.goods.subtitle}
                    </div>
                )}
                <div className={style.prices}>
                    {!data?.custom_price && (
                        <span className={style.price}>
                            {price}
                        </span>
                    )}
                    {!!data.goods?.price_cv && (
                        <span className={style.price}>
                            {numberFormat(data.goods.price_cv)}
                            {' '}
                            {t('market_cv')}
                        </span>
                    )}
                </div>
                <div className={style.controls}>
                    <Counter
                        value={data?.quantity}
                        maxCount={data.goods?.quantity_limit_for_sale}
                        onChange={handlerChangeCount}
                    />

                    <Button
                        classes={style.control}
                        styleType="text"
                        onClick={handlerClickRemove}
                    >
                        <SvgResource
                            isImgTag={false}
                            classes={style.iconTrash}
                            resourceKey="trash_can_svg"
                            width={26}
                            height={30}
                        />
                    </Button>
                </div>
            </div>
        </div>
    )
}

export default StoreBasketItem
