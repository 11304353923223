import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { IReport } from 'interfaces'
import { fetchReportsFilterValuePropType } from 'services/ReportService'
import { ReportService } from 'services'
import * as userSelectors from 'containers/User/user-selectors'
import { getRequestError } from 'utils/helpers'

export type TFetchFnParams = fetchReportsFilterValuePropType
export type TError = string
export type TQueryKey = [string, TFetchFnParams] | string[]
export type TQueryFnOpts = UseQueryOptions<IReport[], TError, IReport[], TQueryKey>

/**
 * Хук API получить значения фильтра в отчете
 */
export default function useFetchReportFilterValue(params: TFetchFnParams, {
    cacheTime = 600 * 1000, // 10m
    staleTime = 600 * 1000, // 10m
    ...opts
}: TQueryFnOpts = {}) {
    const { t } = useTranslation()
    const user = useSelector(userSelectors.user)

    return useQuery([`reportFilterValue-${user.id}`, params], () => {
        return ReportService.fetchReportsFilterValue(params)
            .then(({ data }) => {
                return data
            })
            .catch((err) => {
                return Promise.reject(getRequestError(err) || t('update_error'))
            })
    }, {
        cacheTime,
        staleTime,
        ...opts,
    })
}
