import React, { useState, useEffect, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { IBreadcrumb } from 'interfaces'
import { APP_URL } from 'config/app'
import { ContentContainer, CardContainer } from 'layout'
import {
    PageTitle,
    Loader,
    Breadcrumbs,
    InfiniteScroll,
    NoDataInfo,
    ErrorMsg,
} from 'components'
import { useFetchMarkerCategories, useFetchInfiniteShops, useFetchInfiniteShopsStatic } from 'containers/Market/hooks'
import { scrollTop } from 'utils/helpers'
import { ShopItem } from './components'
import style from './Shops.module.css'

const LIMIT = 12
const OFFSET = 0

/**
 * KW-122 Страница магазины из категории избранное
 * или магазины конкретной категории
 */
const Shops: React.FC = () => {
    const { id: categoryId } = useParams<{ id: string }>()
    const { t } = useTranslation()

    const [isHideLoader, setIsHideLoader] = useState(false)

    const {
        data: dataMarkerCategories,
    } = useFetchMarkerCategories({
        enabled: !!categoryId,
    })

    const {
        isInitialLoading: isLoadingShops,
        data: dataShops,
        error: errorShops,
        fetchNextPage: fetchNextPageShops,
    } = useFetchInfiniteShops({
        category_id: categoryId,
        limit: LIMIT,
        offset: OFFSET,
    }, {
        enabled: !!categoryId,
    })

    const {
        isInitialLoading: isLoadingShopsStatic,
        data: dataShopsStatic,
        error: errorShopsStatic,
        fetchNextPage: fetchNextPageShopsStatic,
    } = useFetchInfiniteShopsStatic({
        limit: LIMIT,
        offset: OFFSET,
    }, {
        enabled: !categoryId,
    })

    const breadcrumbs = useMemo<IBreadcrumb[]>(() => {
        const parent = { id: 1, name: t('stores'), url: APP_URL.market }

        if (!categoryId) {
            return [parent, { id: 2, name: t('my_stores') }]
        }
        if (dataMarkerCategories) {
            const category = dataMarkerCategories.find((item) => item.id === Number(categoryId))
            if (category) {
                return [parent, { id: category.id, name: category.name }]
            }
        }

        return [parent]
    }, [categoryId, dataMarkerCategories])

    const isLoading = useMemo(() => {
        return isLoadingShops || isLoadingShopsStatic
    }, [isLoadingShops, isLoadingShopsStatic])

    const data = useMemo(() => {
        if (categoryId && dataShops) {
            return dataShops
        }
        if (!categoryId && dataShopsStatic) {
            return dataShopsStatic
        }

        return undefined
    }, [categoryId, dataShops, dataShopsStatic])

    const error = useMemo(() => {
        if (errorShops) {
            return errorShops[0]
        }
        if (errorShopsStatic) {
            return errorShopsStatic[0]
        }

        return undefined
    }, [errorShops, errorShopsStatic])

    const handlerOnLoad = () => {
        if (dataShops) {
            fetchNextPageShops()
        }
        if (dataShopsStatic) {
            fetchNextPageShopsStatic()
        }
    }

    useEffect(() => {
        const scrollTimerId = setTimeout(scrollTop)

        return () => {
            clearTimeout(scrollTimerId)
        }
    }, [])

    useEffect(() => {
        if (dataShops) {
            const { pages } = dataShops
            const pagesLen = pages.length

            if (pagesLen && pages[pagesLen - 1].length < LIMIT) {
                setIsHideLoader(true)
            }
        }
    }, [dataShops])

    useEffect(() => {
        if (dataShopsStatic) {
            const { pages } = dataShopsStatic
            const pagesLen = pages.length

            if (pagesLen && pages[pagesLen - 1].length < LIMIT) {
                setIsHideLoader(true)
            }
        }
    }, [dataShopsStatic])

    useEffect(() => {
        if (error) {
            setIsHideLoader(true)
        }
    }, [error])

    return (
        <ContentContainer classes={style.content} size="three-quarters">
            <PageTitle>
                <Breadcrumbs items={breadcrumbs} />
            </PageTitle>

            {isLoading && (
                <Loader />
            )}

            {!isLoadingShopsStatic && dataShopsStatic && !dataShopsStatic.pages.length && (
                <NoDataInfo text={t('nothing_found')} />
            )}

            <InfiniteScroll
                options={{ rootMargin: '0px 0px 25% 0px' }}
                isActive={!!dataShops || !!dataShopsStatic}
                isHideLoader={isHideLoader}
                onLoad={handlerOnLoad}
            >
                <CardContainer>
                    {data?.pages.map((page, i) => (
                        // eslint-disable-next-line
                        <React.Fragment key={i}>
                            {page.map((shop) => (
                                <ShopItem shop={shop} key={shop.id} />
                            ))}
                        </React.Fragment>
                    ))}
                </CardContainer>
            </InfiniteScroll>

            {!isLoading && !data && error && (
                <ErrorMsg error={error} />
            )}
        </ContentContainer>
    )
}

export default Shops
