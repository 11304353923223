import React from 'react'
import { useTranslation } from 'react-i18next'

import { IMlmPrivilegeSumLog } from 'interfaces'
import { Button, TimeAgo } from 'components'
import { numberFormat, plural } from 'utils/helpers'
import style from './PrivilegeScoreContentLog.module.css'

type PrivilegeScoreContentLogPropType = {
    typeId: number
    items: IMlmPrivilegeSumLog[]
    onClickHistory: (id: number) => void
}

const DATE_FORMAT_OPTIONS: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'short', day: 'numeric' }
const AMOUNT_FORMAT_OPTIONS = { minimumFractionDigits: 2, maximumFractionDigits: 2 }

const PrivilegeScoreContentLog: React.FC<PrivilegeScoreContentLogPropType> = ({ typeId, items, onClickHistory }) => {
    const { t } = useTranslation()

    const handlerClickHistory = () => {
        onClickHistory(typeId)
    }

    return (
        <>
            {items.map((item) => (
                <div className={style.item} key={item.id}>
                    <div className={style.column}>
                        <div className={style.name}>
                            {item.type?.name}
                        </div>
                        <div className={style.date}>
                            <TimeAgo time={item.date} options={DATE_FORMAT_OPTIONS} />
                        </div>
                    </div>
                    <div className={style.column}>
                        <div className={style.value}>
                            {numberFormat(item.amount, AMOUNT_FORMAT_OPTIONS)}
                        </div>
                        <div className={style.unit}>
                            {plural(item.amount, [t('points_count_1x'), t('points_count_2x'), t('points_count_5x')])}
                        </div>
                    </div>
                </div>
            ))}

            {items.length >= 5 && (
                <Button
                    classes={style.more}
                    styleType="text"
                    onClick={handlerClickHistory}
                >
                    {t('tab_more')}
                </Button>
            )}
        </>
    )
}

export default PrivilegeScoreContentLog
