import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { CardSize } from 'enums'
import { BREAKPOINTS } from 'config/app'
import { SliderOptions } from 'components/Slider/Slider'
import { ContentContainer, CardContainer } from 'layout'
import {
    PageHeader,
    CardV2,
    Loader,
    Button,
    Modal,
    Iframe,
    SvgResource,
    Slider,
} from 'components'
import { useFetchPrivilege, useFetchPrivilegeSum } from 'containers/User/hooks'
import { useWindowResize } from 'hooks'
import { scrollTop } from 'utils/helpers'
import {
    PrivilegeProgress,
    PrivilegeScoreContent,
    PrivilegeStatusItem,
    PrivilegeHistory,
} from './components'
import style from './Privilege.module.css'

const SLIDER_STATUSES_OPTIONS: SliderOptions = {
    initial: 0,
    loop: false,
    slides: {
        origin: 'center',
        perView: 'auto',
        spacing: 15,
    },
    breakpoints: {
        [`(min-width: ${BREAKPOINTS.phone}px)`]: {
            slides: {
                perView: 2,
                spacing: 15,
            },
        },
        [`(min-width: ${BREAKPOINTS.desktop}px)`]: {
            slides: {
                perView: 3,
                spacing: 15,
            },
        },
        [`(min-width: ${BREAKPOINTS.bigDesktop}px)`]: {
            slides: {
                perView: 4,
                spacing: 15,
            },
        },
    },
}

const Privilege: React.FC = () => {
    const { t } = useTranslation()
    const [windowWidth] = useWindowResize()

    const [privilegeHistoryId, setPrivilegeHistoryId] = useState<number>()
    const [isOpenModalHistory, setIsOpenModalHistory] = useState(false)
    const [isOpenModalProgram, setIsOpenModalProgram] = useState(false)

    const {
        isInitialLoading: isLoadingPrivilege,
        data: dataPrivilege,
    } = useFetchPrivilege()

    const {
        isInitialLoading: isLoadingPrivilegeSum,
        data: dataPrivilegeSum,
    } = useFetchPrivilegeSum({ enabled: !dataPrivilege })

    const sliderStatusesArrows = useMemo(() => windowWidth >= BREAKPOINTS.phone, [windowWidth])

    const cardSumSize = useMemo(() => {
        if (windowWidth >= BREAKPOINTS.desktop) {
            return CardSize.thirdWidth
        }
        if (windowWidth >= BREAKPOINTS.phone) {
            return CardSize.halfWidth
        }

        return CardSize.fullWidth
    }, [windowWidth])

    const nextRank = useMemo(() => {
        if (dataPrivilege) {
            const { next_rank_id: nextRankId, statuses } = dataPrivilege
            return statuses.find((item) => item.id === nextRankId)
        }
        return undefined
    }, [dataPrivilege])

    const handlerClickPrivilegeHistory = (id: number) => {
        setPrivilegeHistoryId(id)
        setIsOpenModalHistory(true)
    }

    const handlerModalHistory = () => {
        setIsOpenModalHistory((prevState) => !prevState)
    }

    const handlerModalProgram = () => {
        setIsOpenModalProgram((prevState) => !prevState)
    }

    const pageHeaderTpl = (
        <PageHeader
            classesTitle={style.pageHeaderTitle}
            classesContent={style.pageHeaderContent}
            title={t('mlm_privilege')}
        >
            <Button
                styleType="transparent"
                onClick={handlerModalProgram}
            >
                <SvgResource
                    resourceKey="ic_info_circle_svg"
                    width={18}
                    height={18}
                />
            </Button>
        </PageHeader>
    )

    useEffect(() => {
        const scrollTimerId = setTimeout(scrollTop)

        return () => {
            clearTimeout(scrollTimerId)
        }
    }, [])

    return (
        <>
            <ContentContainer size="three-quarters">
                {isLoadingPrivilege && (
                    <Loader />
                )}

                {windowWidth < BREAKPOINTS.tablet && pageHeaderTpl}

                {!isLoadingPrivilege && dataPrivilege && (
                    <Slider
                        isHideLeftArrowOnFirstSlide
                        isHideRightArrowOnLastSlide
                        classes={style.sliderStatuses}
                        navigation={false}
                        arrows={sliderStatusesArrows}
                        params={SLIDER_STATUSES_OPTIONS}
                    >
                        {dataPrivilege.statuses.map((item) => (
                            item.id >= dataPrivilege.current_rank_id && (
                                <Slider.Item classes={style.sliderStatusesItem} key={item.id}>
                                    <PrivilegeStatusItem
                                        data={item}
                                        card={dataPrivilege.card_number}
                                        isLock={item.id !== dataPrivilege.current_rank_id}
                                    />
                                </Slider.Item>
                            )
                        ))}
                    </Slider>
                )}

                {!isLoadingPrivilegeSum && dataPrivilege && dataPrivilegeSum && (
                    <>
                        {windowWidth >= BREAKPOINTS.tablet && pageHeaderTpl}
                        <PrivilegeProgress
                            points={dataPrivilege.points}
                            rest={dataPrivilege.rest}
                            nextRankName={nextRank?.name}
                        />
                        <CardContainer classes={style.cardContainer}>
                            {dataPrivilegeSum.map((item) => (
                                <CardV2 classes={style.blockSlideDown0} size={cardSumSize} key={item.id}>
                                    <PrivilegeScoreContent
                                        data={item}
                                        onClickPrivilegeHistory={handlerClickPrivilegeHistory}
                                    />
                                </CardV2>
                            ))}
                        </CardContainer>
                    </>
                )}
            </ContentContainer>

            <Modal
                isOpen={isOpenModalHistory}
                size="small"
                onClose={handlerModalHistory}
            >
                <Modal.Header isCloseButton title={t('rating_sessia_type_sum_title')} />
                <Modal.Body>
                    <PrivilegeHistory
                        id={privilegeHistoryId}
                    />
                </Modal.Body>
            </Modal>

            <Modal
                classes={style.modalIframe}
                isOpen={isOpenModalProgram}
                size="medium"
                onClose={handlerModalProgram}
            >
                <Modal.Header isCloseButton title={t('qr_page_privilege_button_small_text')} />
                <Modal.Body classes={style.modalBodyIframe}>
                    <Iframe
                        title={t('privileges_programm')}
                        src={t('privileges_programm')}
                    />
                </Modal.Body>
            </Modal>
        </>
    )
}

export default Privilege
