import React from 'react'
import { NavLink } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import cn from 'classnames'

import { APP_URL } from 'config/app'
import { ContentContainer, Block } from 'layout'
import { allLogoutUser } from 'containers/User/user-actions'
import { PageTitle, Button, SvgResource } from 'components'
import style from './Settings.module.css'

const Settings: React.FC = () => {
    const { t } = useTranslation()
    const dispatch = useDispatch()

    const handlerClickFullExit = () => {
        dispatch(allLogoutUser())
    }

    return (
        <ContentContainer size="half">
            <PageTitle title={t('settings_tab')} />

            <Block classes={style.block}>
                <NavLink className={style.nav} to={APP_URL.userSettings}>
                    <SvgResource
                        isImgTag={false}
                        classes={cn(style.icon, style.iconSettings)}
                        resourceKey="ic_profile2_svg"
                        width={24}
                        height={24}
                    />
                    <span className={style.text}>
                        {t('my_profile')}
                    </span>
                </NavLink>
                <NavLink className={style.nav} to={APP_URL.settingsLab}>
                    <SvgResource
                        isImgTag={false}
                        classes={style.icon}
                        resourceKey="ic_delete_account2_svg"
                        width={24}
                        height={24}
                    />
                    <span className={style.text}>
                        {t('lab_settings_title')}
                    </span>
                </NavLink>
                <Button
                    classes={style.nav}
                    styleType="transparent"
                    onClick={handlerClickFullExit}
                >
                    <SvgResource
                        isImgTag={false}
                        classes={style.icon}
                        resourceKey="ic_appexit2_svg"
                        width={24}
                        height={24}
                    />
                    <span className={style.text}>
                        {t('logout_on_all_devices')}
                    </span>
                </Button>
            </Block>
        </ContentContainer>
    )
}

export default Settings
