import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { QRCodeSVG } from 'qrcode.react'

import { APP_URL } from 'config/app'
import {
    Avatar,
    SvgResource,
    Button,
    Modal,
} from 'components'
import { copyTextClipboard, showAlertNotify } from 'utils/helpers'
import { useAppSelector } from 'store'
import style from './UserInfo.module.css'

const UserInfo: React.FC = () => {
    const { t } = useTranslation()

    const user = useAppSelector((state) => state.user)
    const profile = useAppSelector((state) => state.profile)
    const isMlmUser = useAppSelector((state) => state.mlm)

    const [isShowModal, setIsShowModal] = useState(false)

    const handlerClickMemberId = () => {
        copyTextClipboard(user.member_id)
            .then(() => {
                showAlertNotify({ type: 'success', message: t('Copied to clipboard') })
            })
            .catch(() => {
                showAlertNotify({ type: 'error', message: t('update_error') })
            })
    }

    const handleOpenModal = () => {
        setIsShowModal(true)
    }

    const handleCloseModal = () => {
        setIsShowModal(false)
    }

    return (
        <>
            <div className={style.wrap}>
                <NavLink
                    className={style.linkEdit}
                    activeClassName={style.linkEdit_active}
                    title={t('edit')}
                    to={APP_URL.userSettings}
                >
                    <SvgResource
                        isImgTag={false}
                        classes={style.iconEdit}
                        resourceKey="edit_profile2_svg"
                        width={30}
                        height={30}
                    />
                </NavLink>

                <Avatar
                    src={user?.photo}
                    width={117}
                    height={117}
                    name={user?.name}
                    surname={user?.surname}
                />

                <div className={style.user}>
                    <div className={style.userName}>
                        {`${user?.name ?? ''} ${user?.surname ?? ''}`}
                    </div>
                </div>

                {user?.profileStatus && (
                    <div className={style.accountStatus}>
                        {user.profileStatus}
                    </div>
                )}

                <div className={style.data}>
                    {isMlmUser && user?.member_id && (
                        <>
                            <div className={style.dataItem}>
                                <Button
                                    classes={style.dataText}
                                    styleType="transparent"
                                    title={t('contract_number')}
                                    onClick={handlerClickMemberId}
                                >
                                    {user.member_id}
                                </Button>
                            </div>
                            <div className={style.dataItem}>
                                <Button
                                    styleType="transparent"
                                    title={t('qrcode')}
                                    onClick={handleOpenModal}
                                >
                                    <SvgResource
                                        isImgTag={false}
                                        classes={style.iconQR}
                                        resourceKey="profile_qr_svg"
                                        width={30}
                                        height={30}
                                    />
                                </Button>
                            </div>
                        </>
                    )}
                </div>
            </div>

            <Modal
                isOpen={isShowModal}
                size="medium"
                onClose={handleCloseModal}
            >
                <Modal.Header isCloseButton title={t('help_context_qr_title')} />
                <Modal.Body>
                    <div className={style.qr}>
                        <QRCodeSVG
                            size={300}
                            value={profile?.sharing_link}
                        />
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default UserInfo
