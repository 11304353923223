import React from 'react'

import { IStickerCollection } from '../../../../interfaces'
import { StickersMenuItem } from '../index'
import style from './StickersMenu.module.css'

type StickersMenuPropType = {
    collections: IStickerCollection[]
    activeIndex: number
    onClickItem: (id: number) => void
}

const StickersMenu: React.FC<StickersMenuPropType> = ({
    collections,
    activeIndex,
    onClickItem,
}) => {
    return (
        <ul className={style.menu}>
            {collections.map((collection, index) => (
                <StickersMenuItem
                    data={collection}
                    count={collection.stickers.length}
                    isActive={activeIndex === index}
                    key={collection.id}
                    onClick={onClickItem}
                />
            ))}
        </ul>
    )
}

export default StickersMenu
