import React, { useEffect, useState } from 'react'

import { Context } from './Context'
import { SlideDownHeader, SlideDownBody } from './components'
import { SlideDownHeaderPropType } from './components/SlideDownHeader/SlideDownHeader'
import { SlideDownBodyPropType } from './components/SlideDownBody/SlideDownBody'

type SlideDownPropType = {
    classes?: string
    isOpened?: boolean
}

type MenuLisComposition = {
    Header: React.FC<SlideDownHeaderPropType>
    Body: React.FC<SlideDownBodyPropType>
}

const SlideDown: React.FC<SlideDownPropType> & MenuLisComposition = ({
    children,
    classes,
    isOpened = false,
}) => {
    const [context, setContext] = useState({ isOpen: isOpened })

    useEffect(() => {
        setContext((preState) => ({ ...preState, isOpen: isOpened }))
    }, [isOpened])

    return (
        <div className={classes}>
            <Context.Provider value={context}>
                {children}
            </Context.Provider>
        </div>
    )
}

SlideDown.Header = SlideDownHeader
SlideDown.Body = SlideDownBody

export default SlideDown
