import React, { useEffect, useState } from 'react'

import { IPost } from 'interfaces'
import { LoadDataType } from 'components/InfiniteScroll/InfiniteScroll'
import { fetchPostsCommunityPropType } from 'services/CommunityService'
import { Community } from 'containers'
import { useFetchInfinitePostsCommunity, useInvalidatePostsCommunity, useSetInfiniteQueryDataPostsCommunity } from 'containers/Community/hooks'
import { Posts } from 'components'

type FeedPostsCommunityPropType = {
    queryParams?: fetchPostsCommunityPropType
    isPostTile?: boolean
}

const FeedPostsCommunity: React.FC<FeedPostsCommunityPropType> = ({
    queryParams = {},
    isPostTile,
}) => {
    const { limit = 10, offset = 0, ...params } = queryParams

    const [isHideLoader, setIsHideLoader] = useState(false)

    const {
        isInitialLoading: isLoading,
        isLoadingError,
        data,
        error,
        fetchNextPage,
    } = useFetchInfinitePostsCommunity({
        limit,
        offset,
        ...params,
    })

    const { invalidate } = useInvalidatePostsCommunity({
        limit,
        offset,
        ...params,
    })

    const { setQueryData } = useSetInfiniteQueryDataPostsCommunity({
        limit,
        offset,
        ...params,
    })

    const handlerOnLoad = ({ page }: LoadDataType) => {
        if (page > 0) {
            fetchNextPage()
        }
    }

    const handlerCompletePost = (post: IPost) => {
        setQueryData(post)
    }

    const handlerDeleteCompletePost = () => {
        invalidate()
    }

    useEffect(() => {
        if (isLoadingError) {
            setIsHideLoader(true)
        }
    }, [isLoadingError])

    useEffect(() => {
        if (data) {
            const { pages } = data
            const pagesLen = pages.length

            if (pagesLen && pages[pagesLen - 1].length < limit) {
                setIsHideLoader(true)
            }
        }
    }, [data])

    return (
        <Community
            onLikeCompletePost={handlerCompletePost}
            onEditCompletePost={handlerCompletePost}
            onDeleteCompletePost={handlerDeleteCompletePost}
        >
            <Posts
                isShowAvatar
                isLoading={isLoading}
                isHideLoader={isHideLoader}
                isPostTile={isPostTile}
                data={data}
                error={error}
                onLoad={handlerOnLoad}
            />
        </Community>
    )
}

export default FeedPostsCommunity
