import React, { useState } from 'react'
import cn from 'classnames'

import { Block } from 'layout'
import { SlideDown, Chevron } from 'components'
import style from './PrivilegeSlideDown.module.css'

type PrivilegeSlideDownPropType = {
    classes?: string
    title: string
}

const PrivilegeSlideDown: React.FC<PrivilegeSlideDownPropType> = ({
    children,
    classes,
    title,
}) => {
    const [isOpen, setIsOpen] = useState(false)

    const handlerClickHeader = () => {
        setIsOpen((prevState) => !prevState)
    }

    return (
        <Block styles={{ height: isOpen ? '100%' : 'auto', transition: 'height .2s' }}>
            <SlideDown
                classes={cn(style.slideDown, classes)}
                isOpened={isOpen}
            >
                <SlideDown.Header
                    classes={style.header}
                    onClick={handlerClickHeader}
                >
                    <div className={style.title}>
                        {title}
                    </div>
                    <div className={style.chevronWrap}>
                        <Chevron
                            classes={style.chevron}
                            size={16}
                            type={isOpen ? 'up' : 'down'}
                        />
                    </div>
                </SlideDown.Header>
                <SlideDown.Body classes={style.body}>
                    {children}
                </SlideDown.Body>
            </SlideDown>
        </Block>
    )
}

export default PrivilegeSlideDown
