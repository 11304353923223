import React from 'react'

import { SvgResource } from 'components'

const Logo: React.FC = () => {
    return (
        <SvgResource
            isImgTag={false}
            resourceKey="projectv_logo_svg"
            width={120}
            height={42}
        />
    )
}

export default Logo
