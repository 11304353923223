import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import cn from 'classnames'

import { IReport, IChartData, IChartDataPoint } from 'interfaces'
import { ChartConfiguration } from 'components/Chart/Chart'
import { ChartPeriod, ChartPeriodTranslate } from 'enums'
import { Chart, Spinner, Tabs } from 'components'
import { UserService } from 'services'
import { numberFormat, dateTimeFormat } from 'utils/helpers'
import { ItemString } from '../index'
import style from './ItemChart.module.css'

type ItemChartPropType = {
    data: IReport
    classes?: string
    url?: string
    target?: '_blank'
    onClick?: () => void
}

const ItemChart: React.FC<ItemChartPropType> = ({
    data,
    classes,
    url,
    target,
    onClick = () => {},
}) => {
    const CHART_TYPE_LINE = 1
    const CHART_TYPE_BAR = 2

    const { t, i18n } = useTranslation()

    const [chartConfig, setChartConfig] = useState<ChartConfiguration>()
    const [tabItems, setTabItems] = useState<any>([])
    const [isLoading, setIsLoading] = useState(false)

    const handlerChangeTab = (id: number) => {
        fetchCounterHistoryAction(id)
    }

    function getChartType(type?: number) {
        switch (type) {
            case CHART_TYPE_LINE:
                return 'line'
            case CHART_TYPE_BAR:
            default:
                return 'bar'
        }
    }

    function getChartItemLabel(item: IChartDataPoint, period: number) {
        const { date } = item

        if (date) {
            const yearShort = dateTimeFormat(date, i18n.language, { year: '2-digit' })
            const monthShort = dateTimeFormat(date, i18n.language, { month: 'short' })
            const day = dateTimeFormat(date, i18n.language, { day: '2-digit' })

            switch (ChartPeriod[period]) {
                case 'all':
                case 'year':
                    return `${monthShort} ${yearShort}`
                case 'month':
                case 'week':
                    return `${day} ${monthShort}`
                default:
                    return ''
            }
        }

        return ''
    }

    function fetchCounterHistoryAction(period?: number) {
        const {
            id,
            // counter,
            defaultPeriod,
            deltaByDate,
        } = data
        const currentPeriod = (period || period === 0) ? period : (defaultPeriod ?? 0)

        if (id) {
            setIsLoading(true)
            UserService.fetchCounterHistory({
                // counter,
                report: id,
                period: currentPeriod,
                delta: deltaByDate,
            })
                .then(({ data: historyData }) => {
                    handleFetchCounterHistoryResponse(historyData, currentPeriod)
                })
                .finally(() => {
                    setIsLoading(false)
                })
        }
    }

    function handleFetchCounterHistoryResponse(historyData: IChartData, period: number) {
        if (historyData?.points?.length) {
            if (!tabItems.length && data.periodTabs?.length) {
                setTabItems(data.periodTabs.map((item) => ({
                    id: item.period,
                    name: t(ChartPeriodTranslate[item.period]),
                })))
            }

            setChartConfig({
                type: getChartType(data.chartType),
                data: {
                    labels: historyData.points.map((item) => getChartItemLabel(item, period)),
                    datasets: [{
                        // for line
                        cubicInterpolationMode: CHART_TYPE_LINE ? 'monotone' : 'default',
                        borderColor: data.chartLineColor,
                        backgroundColor: data.chartLineColor, // 'transparent',
                        fill: true,

                        data: historyData.points.map((item) => item.value),
                    }],
                },
                options: {
                    plugins: {
                        title: {
                            display: true,
                            text: `${t('sum_for_period')}: ${numberFormat(historyData.sum)} ${historyData.unit}`,
                            font: {
                                weight: 'normal',
                                size: 18,
                            },
                        },
                        legend: {
                            display: false,
                        },
                    },
                },
            })
        }
    }

    useEffect(() => {
        fetchCounterHistoryAction()
    }, [])

    return (
        <div className={cn(style.chart, classes)}>
            <ItemString
                data={data}
                url={url}
                target={target}
                onClick={onClick}
            />

            <div className={style.wrap}>
                {isLoading && (
                    <div className={style.loader}>
                        <Spinner position="center" size="medium" />
                    </div>
                )}

                <div className={style.tabsWrap}>
                    <Tabs
                        classes={style.tabs}
                        classesItem={style.tabsItem}
                        classesActive={style.tabsItemActive}
                        items={tabItems}
                        active={data.defaultPeriod}
                        onChange={handlerChangeTab}
                    />
                </div>

                <Chart config={chartConfig} />
            </div>
        </div>
    )
}

export default ItemChart
