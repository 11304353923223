import React, { useState, useEffect, useMemo } from 'react'
import { useHistory, useParams, generatePath } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import cn from 'classnames'

import { IBasketItem, IStoreCurrency } from 'interfaces'
import { TBasketUpdateProps } from 'services/MarketService'
import { APP_URL } from 'config/app'
import { Block } from 'layout'
import { useFetchBasket, useGetOrderBasket, useMutationBasket } from 'containers/Market/hooks'
import { useFetchStoreProfile, useFetchStoreShowcase } from 'containers/Store/hooks'
import { Catalog } from 'containers'
import { Loader, Button, ErrorMsg } from 'components'
import { MarketService } from 'services'
import {
    scrollTop,
    getStoreCurrency,
    numberFormat,
    priceFormat,
    showAlertNotify,
} from 'utils/helpers'
import { StoreBasketItem } from './components'
import style from './StoreBasket.module.css'

const getBasketTotalGoodsQuantity = (basketContent: IBasketItem[] = []): number => {
    return basketContent.reduce((acc, item) => {
        return item.quantity ? acc + item.quantity : acc
    }, 0)
}

const StoreBasket: React.FC = () => {
    const { id, orderId } = useParams<{ id: string, orderId?: string }>()
    const { t } = useTranslation()
    const history = useHistory()

    const [isUpdating, setIsUpdating] = useState(false)

    const storeId = useMemo(() => Number(id), [id])

    const {
        isInitialLoading: isLoadingStoreProfile,
        data: dataStoreProfile,
        error: errorStoreProfile,
    } = useFetchStoreProfile({
        id: storeId,
    }, {
        enabled: !Number.isNaN(storeId),
    })

    const {
        isInitialLoading: isLoadingBasket,
        data: dataBasket,
        error: errorBasket,
        refetch: refetchBasket,
    } = useFetchBasket({
        storeId,
    }, {
        enabled: !Number.isNaN(storeId) && !!dataStoreProfile && !orderId,
    })

    const {
        isInitialLoading: isLoadingOrderBasket,
        data: dataOrderBasket,
        error: errorOrderBasket,
    } = useGetOrderBasket({
        storeId,
        orderId: Number(orderId) ?? 0,
    }, {
        enabled: !Number.isNaN(storeId) && !!dataStoreProfile && !Number.isNaN(Number(orderId)),
        staleTime: 0,
        cacheTime: 0,
    })

    const {
        isInitialLoading: isLoadingStoreRecommendedGoods,
        data: dataStoreRecommendedGoods,
    } = useFetchStoreShowcase({
        storeId,
        recommended: true,
    }, {
        enabled: !!dataBasket,
    })

    const { update: updateBasket } = useMutationBasket()

    const storeCurrency: undefined | IStoreCurrency = useMemo(() => {
        return dataStoreProfile ? getStoreCurrency(dataStoreProfile) : undefined
    }, [dataStoreProfile])

    const isDisabledSubmit = useMemo(() => {
        return !getBasketTotalGoodsQuantity(dataBasket?.content) || !!dataBasket?.error_list?.length
    }, [dataBasket])

    const totalSumFormat = useMemo(() => {
        const { content_sum = 0 } = dataBasket || {}
        return priceFormat(content_sum, storeCurrency)
    }, [dataBasket, storeCurrency])

    const discountedSumFormat = useMemo(() => {
        const { content_sum = 0, discount_sum = 0 } = dataBasket || {}
        return priceFormat(content_sum - discount_sum, storeCurrency)
    }, [dataBasket, storeCurrency])

    const totalCvSumFormat = useMemo(() => {
        const { content_cv_sum = 0 } = dataBasket || {}
        return numberFormat(content_cv_sum)
    }, [dataBasket])

    const payoutCurrency = useMemo(() => {
        return MarketService.getPayoutCurrency(dataBasket?.market_wallet_list)
    }, [dataBasket])

    const handlerChangeBasketItemCount = (changedGoodsId: number, quantity: number, customCurrency?: string) => {
        updateBasketAction({
            storeId: Number(storeId),
            goods: changedGoodsId,
            quantity,
            payment_by: customCurrency,
        })
    }

    const handlerRemoveBasketItem = (removedGoodsId: number, customCurrency?: string) => {
        updateBasketAction({
            storeId: Number(storeId),
            goods: removedGoodsId,
            quantity: 0,
            payment_by: customCurrency,
        })
    }

    const handlerClickCheckout = () => {
        history.push(generatePath(APP_URL.orderMake, { id: Number(storeId) }))
    }

    function updateBasketAction(params: TBasketUpdateProps) {
        setIsUpdating(true)
        updateBasket.mutate(params, {
            onError: (err) => {
                showAlertNotify({ type: 'error', title: '', message: err[0] })
            },
            onSettled: () => {
                setIsUpdating(false)
            },
        })
    }

    useEffect(() => {
        const scrollTimerId = setTimeout(scrollTop)

        return () => {
            clearTimeout(scrollTimerId)
        }
    }, [])

    useEffect(() => {
        if (dataOrderBasket) {
            refetchBasket()
        }
    }, [dataOrderBasket])

    return (
        <div className={style.content}>
            {dataOrderBasket?.service_message_list?.map((item) => (
                <Block
                    classes={cn(style.blockMessage, style[`blockMessage_${item.type}`])}
                    key={item.description.length}
                >
                    {item.description}
                </Block>
            ))}

            {dataBasket?.service_message_list?.map((item) => (
                <Block
                    classes={cn(style.blockMessage, style[`blockMessage_${item.type}`])}
                    key={item.description.length}
                >
                    {item.description}
                </Block>
            ))}

            {(isLoadingStoreProfile || isLoadingBasket || isLoadingOrderBasket) && (
                <Loader />
            )}

            {Number.isNaN(storeId) && (
                <ErrorMsg error={t('nothing_found')} />
            )}

            {(!isLoadingStoreProfile && !isLoadingBasket && !isLoadingOrderBasket)
                && (errorStoreProfile || errorBasket || errorOrderBasket)
            && (
                <ErrorMsg error={(errorBasket && errorBasket[0])
                    || (errorStoreProfile && errorStoreProfile[0])
                    || (errorOrderBasket && errorOrderBasket[0])
                    || t('update_error')}
                />
            )}

            {!isLoadingBasket && dataBasket && !dataBasket.content.length && (
                <ErrorMsg error={t('market_empty_basket')} />
            )}

            {!isLoadingBasket && !!dataBasket?.content?.length && (
                <Block classes={cn(style.basket, { [style.updating]: isUpdating })}>
                    <div className={style.body}>
                        {dataBasket.content.map((item) => (
                            <StoreBasketItem
                                data={item}
                                currency={storeCurrency}
                                payoutCurrency={payoutCurrency}
                                key={item.custom_currency ? `${item.goods.id}${item.custom_currency}` : item.goods.id}
                                onChange={handlerChangeBasketItemCount}
                                onRemove={handlerRemoveBasketItem}
                            />
                        ))}
                    </div>
                    <div className={style.footer}>
                        <div className={style.footerPrices}>
                            <div className={style.footerPriceText}>
                                {t('market_product_total')}
                            </div>
                            <div className={style.footerPrice}>
                                {dataBasket?.discount_sum ? (
                                    <div>
                                        <span className={style.footerPriceCurrent}>
                                            {discountedSumFormat}
                                        </span>
                                        {' '}
                                        <span className={style.footerPriceOld}>
                                            {totalSumFormat}
                                        </span>
                                    </div>
                                ) : (
                                    <div className={style.footerPriceCurrent}>
                                        {totalSumFormat}
                                    </div>
                                )}
                                {!!dataBasket?.content_cv_sum && (
                                    <div className={style.footerPriceCv}>
                                        {`${totalCvSumFormat} ${t('market_cv')}`}
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className={style.footerControls}>
                            <Button
                                fullWidth
                                classes={cn(style.footerControlSubmit, style.footerControlSubmit_fontLarge)}
                                size="size44"
                                text={t('market_checkout_button')}
                                disabled={isDisabledSubmit}
                                onClick={handlerClickCheckout}
                            />
                        </div>
                    </div>
                </Block>
            )}

            {dataStoreProfile && !!dataStoreRecommendedGoods?.length && (
                <>
                    <h2 className={style.subtitle}>
                        {t('featured_goods')}
                    </h2>
                    <Catalog
                        isSetItemLink
                        isShowBuyButton
                        isLoading={isLoadingStoreRecommendedGoods}
                        storeProfile={dataStoreProfile}
                        storeGoods={dataStoreRecommendedGoods}
                    />
                </>
            )}
        </div>
    )
}

export default StoreBasket
