import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import cn from 'classnames'

import { ITicketItem } from 'interfaces'
import { AppDispatch } from 'store'
import { APP_URL, COUNTERS } from 'config/app'
import { ContentContainer } from 'layout'
import {
    PageHeader,
    Button,
    List,
    Modal,
    Loader,
    ErrorMsg,
} from 'components'
import { SupportAction } from 'form-actions'
import { fetchCount } from 'containers/User/user-actions'
import * as userSelectors from 'containers/User/user-selectors'
import { AuthService } from 'services'
import { scrollTop } from 'utils/helpers'
import useUserGUID from 'hooks/useUserGUID'
import { SupportItem } from './components'
import { useFetchTickets, useInvalidateTickets } from './hooks'
import style from './Support.module.css'

const Support: React.FC = () => {
    const { t } = useTranslation()
    const dispatch: AppDispatch = useDispatch()
    const history = useHistory()
    const guid = useUserGUID()

    const user = useSelector(userSelectors.user)
    const isAuthorized = useSelector(userSelectors.authorize)

    const [tickets, setTickets] = useState<ITicketItem[]>([])
    const [error, setError] = useState<string>()
    const [isOpenModalSupport, setIsOpenModalSupport] = useState(false)

    const {
        isInitialLoading: isLoadingTickets,
        isFetching: isFetchingTickets,
        data: dataTickets,
        error: errorTickets,
    } = useFetchTickets({
        guid,
    }, {
        staleTime: 0,
        refetchOnMount: true,
    })

    const { invalidate: invalidateTickets } = useInvalidateTickets({ guid })

    const handlerModalSupport = () => {
        setIsOpenModalSupport((prevState) => !prevState)
    }

    const handlerCompleteSupport = () => {
        setIsOpenModalSupport(false)
        setError('')
        invalidateTickets()
    }

    const handlerClickBack = () => {
        history.push(AuthService.isAuthenticated() ? APP_URL.registration : APP_URL.auth)
    }

    function fetchCountAction() {
        dispatch(fetchCount({ counter: COUNTERS.support })).catch(() => {})
    }

    useEffect(() => {
        const scrollTimerId = setTimeout(scrollTop)

        if (user) {
            fetchCountAction()
        }

        return () => {
            clearTimeout(scrollTimerId)
        }
    }, [])

    useEffect(() => {
        if (dataTickets) {
            if (dataTickets.length) {
                const sortedByCreateDate = dataTickets.sort((a, b) => {
                    return new Date(a.create_date) < new Date(b.create_date) ? 1 : -1
                })

                setTickets(sortedByCreateDate)
            } else {
                setError(t('no_data'))
            }
        }
    }, [dataTickets])

    return (
        <>
            <ContentContainer classes={cn(style.content, { [style.content_center]: !isAuthorized })} size="three-quarters">
                <PageHeader
                    title={t('ui_support_create_ticket_title')}
                    onClickBack={isAuthorized ? undefined : handlerClickBack}
                >
                    <Button
                        classes={style.headerAction}
                        size="size44"
                        text={t('ui_support_create_new_ticket')}
                        onClick={handlerModalSupport}
                    />
                </PageHeader>

                {isLoadingTickets && (
                    <Loader />
                )}

                {(error || !!errorTickets?.length) && (
                    <ErrorMsg error={error! || errorTickets![0]} />
                )}

                <div className={cn(style.items, { [style.updating]: isFetchingTickets })}>
                    {!!tickets.length && (
                        <List>
                            {tickets.map((item) => (
                                <SupportItem data={item} key={item.id} />
                            ))}
                        </List>
                    )}
                </div>
            </ContentContainer>

            <Modal
                isOpen={isOpenModalSupport}
                size="medium"
                onClose={handlerModalSupport}
            >
                <Modal.Header
                    isCloseButton
                    classes={style.modalFormHeader}
                    titlePos="left"
                    title={t('ui_support_create_ticket_title')}
                />

                <SupportAction
                    classes={{ body: style.modalFormBody, footer: style.modalFormFooter }}
                    guid={guid}
                    onComplete={handlerCompleteSupport}
                />
            </Modal>
        </>
    )
}

export default Support
