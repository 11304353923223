import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import cn from 'classnames'

import { ChartConfiguration } from 'components/Chart/Chart'
import { Block } from 'layout'
import { Chart } from 'components'
import doughnutTextCenter from 'components/Chart/plugins/doughnutTextCenter'
import doughnutBackground from 'components/Chart/plugins/doughnutBackground'
import { numberFormat } from 'utils/helpers'
import { vPrimary1 } from 'styles/modules/variables.module.css'
import style from './PrivilegeProgress.module.css'

type PrivilegeProgressPropType = {
    points: number
    rest: number
    nextRankName?: string
}

const MAX_PERCENTAGES = 100
const NUMBER_FORMAT_OPTIONS = { maximumFractionDigits: 0 }
const CHART_ROUND_STYLE = {
    innerStart: 20,
    innerEnd: 20,
    outerStart: 20,
    outerEnd: 20,
}

const defaults = {
    color: vPrimary1,
}

const PrivilegeProgress: React.FC<PrivilegeProgressPropType> = ({ points, rest, nextRankName }) => {
    const { t } = useTranslation()

    const pointsFormatted = useMemo(() => numberFormat(points, NUMBER_FORMAT_OPTIONS), [points])
    const restFormatted = useMemo(() => numberFormat(rest, NUMBER_FORMAT_OPTIONS), [rest])
    const nextRankText = useMemo(() => {
        return nextRankName ? t('mlm_privilege_rest').replace('%s', nextRankName) : ''
    }, [nextRankName])

    const values = useMemo(() => {
        if (points !== undefined && rest !== undefined) {
            const percent = Math.floor((points / (points + rest)) * 100)
            return [percent, MAX_PERCENTAGES - percent]
        }
        return []
    }, [points, rest])

    const chartConfig: ChartConfiguration = useMemo(() => {
        return {
            type: 'doughnut',
            data: {
                datasets: [{
                    backgroundColor: [defaults.color, 'transparent'],
                    hoverBackgroundColor: [defaults.color, 'transparent'],
                    borderRadius: [values[0] !== MAX_PERCENTAGES ? CHART_ROUND_STYLE : 0, 0],
                    data: values,
                }],
            },
            options: {
                responsive: false,
                cutout: '70%',
                borderWidth: 0,
                borderJoinStyle: 'round',
                plugins: {
                    legend: {
                        display: false,
                    },
                    tooltip: {
                        enabled: false,
                    },
                    doughnutTextCenter: {
                        enabled: true,
                        text: `${values[0] ?? 0}%`,
                        font: 'bolder 26px sans-serif',
                        fontColor: defaults.color,
                    },
                    doughnutBackground: {
                        enabled: true,
                    },
                },
            },
            plugins: [doughnutTextCenter, doughnutBackground],
        }
    }, [values])

    return (
        <Block classes={style.block}>
            <div className={style.column}>
                <div className={style.columnTitle}>
                    {pointsFormatted}
                </div>
                <div className={style.columnText}>
                    {t('mlm_privilege_points2')}
                </div>
            </div>
            <div className={cn(style.column, style.column_chart)}>
                <Chart
                    config={chartConfig}
                    options={{ className: style.chart, width: 140, height: 140 }}
                />
            </div>
            <div className={style.column}>
                <div className={cn(style.columnTitle, style.columnTitle_next, style.columnTitle_gray)}>
                    {restFormatted}
                </div>
                <div className={cn(style.columnText, style.columnText_next, style.columnText_gray)}>
                    {nextRankText}
                </div>
            </div>
        </Block>
    )
}

export default PrivilegeProgress
