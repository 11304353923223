import { useEffect, useState } from 'react'
import { isMobile, isAndroid, isIOS } from 'react-device-detect'

import { IStorageMobileDetectData } from 'interfaces'
import {
    CLIENT_STORAGE_MOBILE_DETECT_TIMEOUT_KEY,
    ANDROID_APP_STORE,
    IOS_APP_STORE,
} from 'config/app'
import { StorageService } from 'services'

const MOBILE_DEVICE_TIMEOUT_DETECT = 1000 * 3600 * 24 * 30 // 30d

/**
 * Hook for detect mobile and get mobile app store link
 */
export default function useMobileDetectAppStore(): {
appStoreUrl?: string
appStoreImgKey?: string
setTimeoutDetect: () => void
} {
    const [appStoreUrl, setAppStoreUrl] = useState<string | undefined>()
    const [appStoreImgKey, setAppStoreImgKey] = useState<string | undefined>()

    const getTimeoutDetectData = () => {
        return StorageService.getItem<IStorageMobileDetectData>(CLIENT_STORAGE_MOBILE_DETECT_TIMEOUT_KEY)
    }

    const setTimeoutDetect = () => {
        StorageService.setItem(CLIENT_STORAGE_MOBILE_DETECT_TIMEOUT_KEY, { date: Date.now() })
    }

    useEffect(() => {
        if (isMobile) {
            const data = getTimeoutDetectData()
            const isCanDetect = data ? data.date + MOBILE_DEVICE_TIMEOUT_DETECT < Date.now() : true

            if (isCanDetect) {
                if (isAndroid) {
                    setAppStoreUrl(ANDROID_APP_STORE)
                    setAppStoreImgKey('google_play_button')
                } else if (isIOS) {
                    setAppStoreUrl(IOS_APP_STORE)
                    setAppStoreImgKey('appstore_button')
                }
            }
        }
    }, [isMobile])

    return {
        appStoreUrl,
        appStoreImgKey,
        setTimeoutDetect,
    }
}
