import React from 'react'
import { NavLink } from 'react-router-dom'

import { Logo } from 'components'
import style from './LogoLink.module.css'

type LogoLinkPropType = {
    path: string
}

const LogoLink: React.FC<LogoLinkPropType> = ({ path }) => {
    return (
        <NavLink className={style.logoLink} to={path}>
            <Logo />
        </NavLink>
    )
}

export default LogoLink
