import React from 'react'
import cn from 'classnames'

import { ImgResource } from 'components'
import style from './ReportsMlmActivity.module.css'

type ReportsMlmActivityPropType = {
    title: string
    imageKey: string
    imageWidth?: number
    imageHeight?: number
    dataText: string
    dataValue: string
    subtitle?: string | React.ReactNode
    options?: {
        title?: {
            success?: boolean
            danger?: boolean
        }
        subtitle?: {
            success?: boolean
            danger?: boolean
        }
    }
}

const ReportsMlmActivity: React.FC<ReportsMlmActivityPropType> = ({
    title,
    subtitle,
    imageKey,
    imageWidth,
    imageHeight,
    dataText,
    dataValue,
    options = {},
}) => {
    return (
        <div className={style.wrap}>
            <div className={style.info}>
                <ImgResource
                    classes={style.infoImage}
                    resourceKey={imageKey}
                    isShowLoader={false}
                    width={imageWidth}
                    height={imageHeight}
                />
                <div className={cn(style.infoTitle, {
                    [style.dataTitle_success]: options.title?.success,
                    [style.dataTitle_danger]: options.title?.danger,
                })}
                >
                    {title}
                </div>
                {subtitle && (
                    <div className={cn(style.infoSubtitle, {
                        [style.dataSubtitle_success]: options.subtitle?.success,
                        [style.dataSubtitle_danger]: options.subtitle?.danger,
                    })}
                    >
                        {subtitle}
                    </div>
                )}
            </div>
            <div className={style.data}>
                <div className={style.dataText}>
                    {dataText}
                </div>
                <div className={style.dataValue}>
                    {dataValue}
                </div>
            </div>
        </div>
    )
}

export default ReportsMlmActivity
