import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import cn from 'classnames'

import { ICountry, ICity, IUserUpdateProps } from 'interfaces'
import { validationFormErrorsType } from 'utils/validators'
import {
    Input,
    Radio,
    Button,
    CountryDataSelect,
    CityDataSelect,
} from 'components'
import { isDataUrl } from 'utils/helpers'

import styleForm from 'styles/modules/form.module.css'
import style from './RegistrationUserForm.module.css'

export type FormDataType = IUserUpdateProps

export type RegistrationUserFormPropType = {
    data: FormDataType
    errors: validationFormErrorsType
    country?: ICountry
    city?: ICity
    countries: ICountry[]
    maxBirthDayRestrictions: string
    isSubmitting: boolean
    isDisabled: boolean
    onChangePhoto: (data: File) => void
    onChangeCountry: (data: ICountry) => void
    onChangeCity: (data: ICity) => void
    onChange: (name: string, value: string) => void
    onSubmit: () => void
}

const RegistrationUserForm: React.FC<RegistrationUserFormPropType> = ({
    data,
    errors,
    country,
    city,
    countries,
    maxBirthDayRestrictions,
    isSubmitting,
    isDisabled,
    onChangePhoto,
    onChangeCountry,
    onChangeCity,
    onChange,
    onSubmit,
}) => {
    const { photo } = data

    const { t } = useTranslation()

    const avatarPreview: string = useMemo(() => {
        if (photo) {
            return isDataUrl(photo) ? photo : `data:image/png;base64,${photo}`
        }

        return ''
    }, [photo])

    const handlerSubmit = (e: React.SyntheticEvent<HTMLFormElement>) => {
        e.preventDefault()
        onSubmit()
    }

    const handlerChangePhoto = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
        const { files } = target
        const [file] = files || []

        onChangePhoto(file)
    }

    const handlerChangeFormData = ({ currentTarget }: React.SyntheticEvent<HTMLInputElement>) => {
        const { name, value } = currentTarget
        onChange(name, value)
    }

    return (
        <form className={cn({ [styleForm.submitting]: isSubmitting })} onSubmit={handlerSubmit}>
            {avatarPreview && (
                <div className={cn(styleForm.row, styleForm.row_12)}>
                    <img
                        className={style.avatarPreview}
                        src={avatarPreview}
                        alt=""
                    />
                </div>
            )}

            <div className={cn(styleForm.row, styleForm.row_12)}>
                <Input
                    type="file"
                    styleType="default"
                    onChange={handlerChangePhoto}
                />
            </div>

            <div className={cn(styleForm.row, styleForm.row_12)}>
                <Input
                    classes={errors.name ? styleForm.invalid : ''}
                    name="name"
                    placeholder={`${t('firstname')} *`}
                    value={data.name}
                    onChange={handlerChangeFormData}
                />
            </div>

            <div className={cn(styleForm.row, styleForm.row_12)}>
                <Input
                    classes={errors.surname ? styleForm.invalid : ''}
                    name="surname"
                    placeholder={`${t('lastname')} *`}
                    value={data.surname}
                    onChange={handlerChangeFormData}
                />
            </div>

            <div className={cn(styleForm.row, styleForm.row_12)}>
                <Input
                    classes={errors.guarantor_code ? styleForm.invalid : ''}
                    name="guarantor_code"
                    placeholder={t('Promocode')}
                    value={data.guarantor_code}
                    onChange={handlerChangeFormData}
                />
            </div>

            <div className={cn(styleForm.row, styleForm.row_12)}>
                <Input
                    classes={errors.email ? styleForm.invalid : ''}
                    name="email"
                    placeholder={t('email')}
                    value={data.email}
                    onChange={handlerChangeFormData}
                />
            </div>

            <div className={cn(styleForm.row, styleForm.row_12)}>
                <Input
                    classes={cn('input-default', errors.date ? styleForm.invalid : '')}
                    type="date"
                    name="birthDate"
                    styleType="default"
                    max={maxBirthDayRestrictions}
                    value={data.birthDate}
                    placeholder={t('borndate')}
                    onChange={handlerChangeFormData}
                />
            </div>

            <div className={cn(styleForm.row, styleForm.row_12)}>
                <div className={cn(styleForm.group, style.group)}>
                    <CountryDataSelect
                        isShowSelectArrow
                        classes={cn(
                            styleForm.groupItem,
                            styleForm.groupItem_static,
                            styleForm.groupItem_minWidth,
                        )}
                        countries={countries}
                        selected={country}
                        onChange={onChangeCountry}
                    />

                    <CityDataSelect
                        classes={cn(
                            styleForm.groupItem,
                            styleForm.groupItem_static,
                            styleForm.groupItem_maxWidth,
                            style.citySelect,
                        )}
                        countryId={country?.id}
                        countryLang={country?.lang}
                        selected={city}
                        onChange={onChangeCity}
                    />
                </div>
            </div>

            <div className={cn(styleForm.row, styleForm.row_12)}>
                <Radio
                    classes={style.radio}
                    name="gender"
                    text={t('female')}
                    value="0"
                    checked={data.gender === 0}
                    onChange={handlerChangeFormData}
                />

                <Radio
                    classes={style.radio}
                    name="gender"
                    text={t('male')}
                    value="1"
                    checked={data.gender === 1}
                    onChange={handlerChangeFormData}
                />
            </div>

            <div className={style.controls}>
                <Button
                    fullWidth
                    text={t('Сomplete the registration')}
                    size="size44"
                    type="submit"
                    disabled={isDisabled}
                />
            </div>
        </form>
    )
}

export default RegistrationUserForm
