import React, { useEffect, useMemo, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { IBreadcrumb, IMlmRankUser, IReportMlmRank } from 'interfaces'
import { DatePairType } from 'components/DateRangeFilter/DateRangeFilter'
import { APP_URL } from 'config/app'
import { CardContainer } from 'layout'
import { useFetchMlmReportsRanksNewUsers, useFetchMlmReportsRanksMaxUsers } from 'containers/ReportsFreedom/hooks'
import { ReportsRankUsersListItem } from 'containers/Reports/components'
import {
    PageTitle,
    Breadcrumbs,
    Loader,
    InfiniteScroll,
    NoDataInfo,
} from 'components'
import { scrollTop } from 'utils/helpers'

type TReportsFreedomRanksUsersStateProps = {
    type?: 'new' | 'max'
    name?: string
    rank?: IReportMlmRank
    dateFilter?: DatePairType
}

const ReportsFreedomRanksUsers: React.FC = () => {
    const { state }: { state?: TReportsFreedomRanksUsersStateProps } = useLocation()
    const history = useHistory()
    const { t } = useTranslation()

    const [userList, setUserList] = useState<IMlmRankUser[]>([])
    const [limit] = useState(15)
    const [offset, setOffset] = useState(0)
    const [isHideInfiniteLoader, setIsHideInfiniteLoader] = useState(false)

    const [breadcrumbs] = useState<IBreadcrumb[]>([
        { id: 1, name: t('reports'), url: APP_URL.reports },
        { id: 2, name: state?.name ?? '', url: APP_URL.reportsFreedomRanks },
        { id: 3, name: state?.rank?.name ?? '' },
    ])

    const {
        isInitialLoading: isLoadingRanksNewUsers,
        data: dataRanksNewUsers,
    } = useFetchMlmReportsRanksNewUsers({
        limit,
        offset,
        rankId: state?.rank?.id,
        dateFrom: state?.dateFilter?.dateFrom,
        dateTo: state?.dateFilter?.dateTo,
    }, {
        enabled: state?.type === 'new',
    })

    const {
        isInitialLoading: isLoadingRanksMaxUsers,
        data: dataRanksMaxUsers,
    } = useFetchMlmReportsRanksMaxUsers({
        limit,
        offset,
        rankId: state?.rank?.id,
        dateFrom: state?.dateFilter?.dateFrom,
        dateTo: state?.dateFilter?.dateTo,
    }, {
        enabled: state?.type === 'max',
    })

    const isLoading = useMemo(() => {
        return isLoadingRanksNewUsers || isLoadingRanksMaxUsers
    }, [isLoadingRanksNewUsers, isLoadingRanksMaxUsers])

    const handlerOnLoad = ({ page }: { page: number }) => {
        if (!isLoading) {
            setOffset((prevSate) => prevSate + limit)
        }
    }

    function handleResponseData(value: IMlmRankUser[]) {
        setUserList((prevState) => [...prevState, ...value])

        if (value.length < limit) {
            setIsHideInfiniteLoader(true)
        }
    }

    useEffect(() => {
        const scrollTimerId = setTimeout(scrollTop)

        if (!state?.type || !state?.rank) {
            setIsHideInfiniteLoader(true)
            history.push(APP_URL.reportsFreedomRanks)
        }

        return () => {
            clearTimeout(scrollTimerId)
        }
    }, [])

    useEffect(() => {
        if (dataRanksNewUsers) {
            const { data } = dataRanksNewUsers

            handleResponseData(data)
        }
    }, [dataRanksNewUsers])

    useEffect(() => {
        if (dataRanksMaxUsers) {
            const { data } = dataRanksMaxUsers

            handleResponseData(data)
        }
    }, [dataRanksMaxUsers])

    return (
        <>
            <PageTitle>
                <Breadcrumbs items={breadcrumbs} />
            </PageTitle>

            {isLoading && (
                <Loader />
            )}

            <CardContainer>
                <InfiniteScroll
                    isActive={!isLoading}
                    options={{ rootMargin: '0px 0px 50% 0px' }}
                    isHideLoader={isHideInfiniteLoader}
                    onLoad={handlerOnLoad}
                >
                    {userList.map((item) => (
                        <ReportsRankUsersListItem
                            data={item}
                            key={item.id}
                        />
                    ))}
                    {!isLoading && !userList.length && (
                        <NoDataInfo text={t('nothing_found')} />
                    )}
                </InfiniteScroll>
            </CardContainer>
        </>
    )
}

export default ReportsFreedomRanksUsers
