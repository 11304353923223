import React, { useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { IBreadcrumb } from 'interfaces'
import { APP_URL, STORE_TYPE_CPA_NO_GOODS, STORE_FRIENDS_SHOW_COUNT } from 'config/app'
import { Block } from 'layout'
import {
    Breadcrumbs,
    PageTitle,
    Loader,
    UsersAvatarGroup,
} from 'components'
import { useFetchStoreProfile } from 'containers/Store/hooks'
import { useFetchFriendsStore } from 'containers/User/hooks'
import * as userSelectors from 'containers/User/user-selectors'
import { getStoreFriendsCountText, parseTpl, scrollTop } from 'utils/helpers'
import { StoreProfileBonus, StoreProfileContent, StoreProfileCpaContent } from './components'
import style from './StoreProfile.module.css'

const StoreProfile: React.FC = () => {
    const { id } = useParams<{ id: string }>()
    const { t } = useTranslation()

    const isMlmUser = useSelector(userSelectors.mlm)

    const [friendsCountText, setFriendsCountText] = useState('')

    const storeId = useMemo(() => Number(id), [id])

    const {
        isInitialLoading: isLoadingStoreProfile,
        data: dataStoreProfile,
    } = useFetchStoreProfile({ id: storeId }, { enabled: !Number.isNaN(storeId) })

    const { data: friendsData } = useFetchFriendsStore({
        storeId: Number(id),
        limit: STORE_FRIENDS_SHOW_COUNT,
        with_count: true,
    })

    const storeUrl = useMemo(() => {
        return dataStoreProfile
            ? parseTpl(APP_URL.category, { ':id': dataStoreProfile.category.id }, { prefix: '', suffix: '' })
            : ''
    }, [dataStoreProfile])

    const breadcrumbs: IBreadcrumb[] = useMemo(() => ([
        { id: 1, name: t('stores'), url: APP_URL.market },
        { id: 2, name: dataStoreProfile?.category.pluralName || '', url: storeUrl },
        { id: 3, name: dataStoreProfile?.name || '' },
    ]), [dataStoreProfile])

    useEffect(() => {
        const scrollTimerId = setTimeout(scrollTop)

        return () => {
            clearTimeout(scrollTimerId)
        }
    }, [])

    useEffect(() => {
        if (friendsData) {
            const countText = getStoreFriendsCountText(friendsData.count, [t('friend_1x'), t('friend_2x'), t('friend_5x')])
            const descText = t('shop_card_n_made_order')

            setFriendsCountText(`${countText} ${descText}`)
        }
    }, [friendsData])

    return (
        <>
            {!isMlmUser && (
                <PageTitle classes={style.breadcrumbs}>
                    <Breadcrumbs items={breadcrumbs} />
                </PageTitle>
            )}

            {isLoadingStoreProfile ? (
                <Loader />
            ) : (
                <>
                    <StoreProfileBonus profile={dataStoreProfile} />

                    <div className={style.title}>
                        {dataStoreProfile?.name}
                    </div>

                    <div className={style.subTitle}>
                        {dataStoreProfile?.sub_title}
                    </div>

                    {dataStoreProfile?.description && (
                        <Block classes={style.blockDesc}>
                            {dataStoreProfile.description}
                        </Block>
                    )}

                    {friendsData?.users && (
                        <UsersAvatarGroup
                            containerClasses={style.friends}
                            users={friendsData.users}
                            size={32}
                            desc={friendsCountText}
                        />
                    )}

                    <div className={style.profile}>
                        {dataStoreProfile?.gateway_type === STORE_TYPE_CPA_NO_GOODS ? (
                            <StoreProfileCpaContent profile={dataStoreProfile} />
                        ) : (
                            <StoreProfileContent profile={dataStoreProfile} />
                        )}
                    </div>
                </>
            )}
        </>
    )
}

export default StoreProfile
