import React from 'react'

export type SlideDownHeaderPropType = {
    classes?: string
    onClick?: () => void
}

const SlideDownHeader: React.FC<SlideDownHeaderPropType> = ({
    children,
    classes,
    onClick = () => {},
}) => {
    return (
        <div
            className={classes}
            role="button"
            aria-hidden="false"
            tabIndex={0}
            onKeyDown={() => {}}
            onClick={onClick}
        >
            {children}
        </div>
    )
}

export default SlideDownHeader
