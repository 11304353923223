import { ICountryIdentify } from 'interfaces'
import requestClient from 'utils/requestClient'
import { API_URL } from 'config/api'

type fetchCountryPropType = {
    lat?: number
    lon?: number
}

type fetchCountryResponseType = ICountryIdentify

/**
 * API country
 */
class CountryService {
    /**
     * Определение страны юзера
     * @param [lat]
     * @param [lon]
     * @return {object}
     */
    static fetchCountry({ lat, lon }: fetchCountryPropType = {}) {
        return requestClient<fetchCountryResponseType>(API_URL.country, { params: { lat, lng: lon } })
    }
}

export default CountryService
